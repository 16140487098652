import routes from '../routes/Services.routes';
import axios from 'axios';

export default async function addResumeApi(body,setLoader){
    // console.log("body==========",body)
    return await axios.post(routes.Resume, body).then(res=>{
        setLoader(false);
        return res.data;
    }).catch(error =>{
        setLoader(false);
        if(error.response){
            return error.response.data.message;
        }else if(error.request){           
        }else{
        }
    })
}
