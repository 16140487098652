import routes from '../routes/Services.routes';
import axios from "axios";
 
export default async function getPostCommentApi(id){
    return await axios.get(`${routes.DiscussionPost}/${id}`,{
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then(res => {
        return res.data;
    }).catch(error => {
        console.log(error);
    })
}