import routes from "../routes/Services.routes";
import axios from "axios";

export default async function getEditorsHtmlInfoApi(section, subsection){
    return await axios.get(`${routes.EditorsHtmlInfo}/${section}/${subsection}`,{
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then(res => {
        return res.data;
    }).catch(error => {
        console.log(error);
    })
}
