import * as React from "react";
import { Typography, Container } from "@mui/material";
import "./enrollCard.css";
import { useNavigate } from "react-router-dom";
import EnrollCardPic from "../../assets/images/enroll-card-pic.png";

const EnrollCard = () => {
  //const isMobile = useIsMobile();
  const navigate = useNavigate();
  const gotoSummerIntern = () => {
    navigate("techvanto-summer-internship")
  }
  return (
    <section>
      <Container className="main-container-box-prev">
        <div className="enroll-box">
            <div>
                <p className="want-to-learn">Want To Learn Technology ?</p>
                <p className="want-to-learn-explain">We have all the technology to make you smarter. Get smarter with tech today.</p>
            </div>
            <div style={{
                textAlign: "center"
            }}>
                <h4 className="enroll-now-white-text">Enroll Now</h4>
                <button className="enroll-now-know-more" onClick={gotoSummerIntern}>
                    Know More
                </button>
            </div>
            <div>
                <img src={EnrollCardPic} width="180" alt="EnrollPic" />
            </div>
        </div>
      </Container>
    </section>
  );
};

export default EnrollCard;
