import React from 'react';
import { useState } from 'react';
import { Typography } from "@mui/material";
import addDemoApi from "../../apis/api/AddDemo";
import DatePicker from "react-datepicker";
import CalenderIcon from '../../assets/images/calendar.png';
import TimeIcon from '../../assets/images/clock.png';
import "./placementTraining.css";
import ButtonLoader from "../../assets/images/button_loader.gif";
import HeaderImage from "../../assets/images/placement_training_header_img.png";
import placementAsissKey from "../../assets/images/placement_asiss_key.png";
import { toast } from "react-toastify";
import { Partners } from "../../components";
import hiringData from '../../data/partner/partner';
import topClientData from '../../data/universityPartners/universityPartner';
import GreenCheck from "../../assets/images/green_check.png";
import WhyShould1 from "../../assets/images/why-should-1.png";
import WhyShould2 from "../../assets/images/why-should-2.png";
import WhyShould3 from "../../assets/images/why-should-3.png";
import WhyShould4 from "../../assets/images/why-should-4.png";
import WhyShould5 from "../../assets/images/why-should-5.png";
import WhyShould6 from "../../assets/images/why-should-6.png";
import PlBottomImg from "../../assets/images/pl-bottom-img.png";
let date = '';
let time = '';

const CampusAmbas = () => {
    const [file, setFile] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [resumeError, setResumeError] = useState(true);
    const [loader, setLoader] = useState(true);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [dateValue, onChange] = useState(new Date());
    const [timeValue, timeCahnge] = useState(new Date());
    const [dateError, setDateError] = useState(true);
    const [timeError, setTimeError] = useState(true);
    const [info, setInfo] = useState({
        name: "",
        email: "",
        phone: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).phone : "",
        date: "",
        source: "placement_training"
    });
    let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    
    const submitForm= async(e) =>{
        e.preventDefault();
        setLoader(true);
        if(!nameError && !phoneError && 
           !emailError && !dateError && !timeError)
        {
            let res;
            setButtonLoader(true)
            res = await addDemoApi(info);
            console.log("res==========",res)
            if(res){
                // console.log("res==========",res)
                setButtonLoader(false)
                if(res.statusCode === 200){
                    console.log("success");
                    let fileInfo = {
                        name: "",
                        email: "",
                        phone: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).phone : "",
                        date:"",
                        source: ""
                    }
                    setNameError(true);
                    setEmailError(true);
                    JSON.parse(localStorage.getItem('user')) ? setPhoneError(false) : setPhoneError(true);
                    setResumeError(true);
                    setDateError(true);
                    setTimeError(true);
                    setFile(null);
                    setInfo(fileInfo);
                    toast.success(res.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }else{
                    toast.error('Something went wrong.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }
            }else{
                setButtonLoader(false);
                toast.error('Something went wrong.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }else{
            if(nameError) return errorToast('Name required.');
            if(phoneError) return errorToast('Invalid phone number entered.');
            if(emailError) return errorToast('Invalid email entered.');
            if(dateError) return errorToast('Date Required.');
            if(timeError) return errorToast('Time Required.');
        }
    }
    const formValidate = (event) => {
        if (event.name === "name") {
          setInfo({ ...info, name: event.value });
          if(event.value === "") return setNameError(true);
          setNameError(false);
        }
        if (event.name === "email") {
          setInfo({ ...info, email: event.value });
          if(event.value === "" || !event.value.match(emailValidate)) return setEmailError(true);
          setEmailError(false);
        }
        if (event.name === "phone") {
          setInfo({ ...info, phone: event.value });
          if(event.value !== "" && event.value.length === 10) return setPhoneError(false);
          setPhoneError(true);
        }
    }
    const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const handleDateSelect = (value, from) => {
        if(from === 'date'){
            date = value;
            onChange(value);
            setDateError(false);
        }
        if(from === 'time'){
            time = value;
            timeCahnge(value);
            setTimeError(false);
        }
        if(date !== '' && time !== ''){
            let y = new Date(dateValue).getFullYear();
            let m = new Date(dateValue).getMonth()+1;
            let d = new Date(dateValue).getDate();
            let hh = new Date(time).getHours();
            let min = new Date(time).getMinutes();
            let newDate = new Date(y + ',' + m + ',' + d + ' ' + hh + ':' + min).toISOString();
            setInfo({...info, date: `${newDate}`});
        }
    }
    return (
        <>
            <section className="summer-intern">
                <div className="wintr-intern-box">
                    <Typography 
                        component="div" 
                        variant="div" 
                        className="wintr-intern-content">
                            <p style={{
                                color: "var(--color-light-blue)",
                                fontWeight: "bold",
                            }} className="place-train-header-text">
                                Placement Training Program by Techvanto Academy
                            </p>
                            <button 
                                className="intern-register placement-apply-now" 
                                data-bs-toggle="modal" 
                                data-bs-target="#myModal"
                                style={{
                                    backgroundColor: "var(--color-light-green)"
                                }}>
                                Apply Now
                            </button>
                    </Typography>
                    <div className="pl-header">
                        <img src={HeaderImage} />
                    </div>
               </div>
            </section>
            <br />
            <div style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
            }} className="campus-ambas-top-text">
                <Typography component="h2" className="techvanto-whyus-heading" style={{
                    color: 'var(--color-light-blue)'
                }}>
                    Our Program
                </Typography>
                <p style={{
                    margin: 0
                }}>
                    Many elite IT companies like <b>TCS, Infosys, Accenture, and Cognizant recruit graduates of 1 to 1.2 lakh on college 
                    campuses.</b> Along with technical skills, their campus recruitment processes also include aptitude tests, which test 
                    you on your quantitative, logical, and language skills. <b>Techvanto Academy’s placement Training Program (PTP) will 
                    equip you with the skills to make your dream come true by joining these elite institutes.</b>
                </p>   
            </div>
            <br />
            <Partners data={hiringData} heading="Get Placed At" />
            <br />
            <Partners data={topClientData} heading="Partnership with Top Universities" />
            <br />
            <div style={{
                textAlign: 'center',
            }}>
                <button 
                    className="intern-register placement-apply-now" 
                    data-bs-toggle="modal" 
                    data-bs-target="#myModal"
                    style={{
                        backgroundColor: "var(--color-light-green)"
                    }}>
                    Apply Now
                </button>
            </div>
            <br />
            <section style={{
                backgroundColor: 'rgb(224, 252, 242, 1)',
                padding: '12px 0',
                margin: '12px 0'
            }} className="placement-section">
                <Typography component="h2" className="techvanto-whyus-heading" style={{
                    color: 'var(--color-light-blue)',
                    textAlign: 'center'
                }}>
                    Placement Assistance Key Points
                </Typography>
                <div className="placement-key-box">
                    <div className="placement-key-box-1" style={{
                        textAlign: 'center'
                    }}>
                        <img src={placementAsissKey} width="500" />
                    </div>
                    <div className="placement-key-box-1 placement-margin">
                        <ul style={{
                            listStyleType: 'none',
                            padding: '0'
                        }}>
                            <li>
                                <img src={GreenCheck} width="20"/>
                                <p>It is the leader in offering student placements, as it has a dedicated placement wing that caters to 
                                    the needs of students during internships.</p>
                            </li>
                            <li>
                                <img src={GreenCheck} width="20" />
                                <p>It helps students develop their CVs according to current industry standards.It also helps students 
                                    develop their CVs according to current industry standards.</p>
                            </li>
                            <li>
                                <img src={GreenCheck} width="20" />
                                <p>It hosts personality development sessions including spoken English, group discussions, mock interviews,
                                    presentation skills to prepare students to easily cope with difficult interview situations.</p>
                            </li>
                            <li>
                                <img src={GreenCheck} width="20" />
                                <p>It has prepared its students to place themselves in top IT companies like Amazon, Flipkart, HCL, TCS, 
                                    Infosys, Wipro, Accenture, and many more.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <br />
            <br />
            <section style={{
                backgroundImage: 'linear-gradient(to right, #E0FCF2 , #498E89)'
            }} className="carrer-councel-aria">
                <div style={{
                    fontWeight: 'bold',
                }} className="pl-difficult">
                    Difficult to choose<br/> what is best for you?
                </div>
                <div style={{
                    textAlign: 'center'
                }} className="pl-carer-coun-txt">
                    <h5 style={{
                        marginBottom: 0,
                        color: 'var(--color-secondary-red)',
                        fontWeight: 'bold'
                    }}>
                        CAREER<br/> COUNSELLING
                    </h5>
                    <h6 style={{
                        color: 'var(--color-secondary-red)',
                        fontWeight: 'bold'
                    }}>Sessions</h6>
                </div>
                <div>
                    <button 
                        className="intern-register" 
                        data-bs-toggle="modal" 
                        data-bs-target="#myModal">
                        Book Now
                    </button>
                </div>
            </section>
            <br />
            <br />
            <section>
                <Typography component="h2" className="techvanto-whyus-heading why-should-heading" style={{
                    color: 'var(--color-light-blue)'
                }}>
                    Why should you join Placement Program by <br/> Techvanto Academy
                </Typography>
                <br/>
                <div className='pl-why-should-aria'>
                    <div className='pl-why-should'>
                        <img src={WhyShould1} className="pl-why-should-img"/>
                    </div>
                    <div className='pl-why-should-2'>
                        <Typography component="h3" className="techvanto-whyus-heading">
                            60hrs Live Training
                        </Typography>
                        <p className="pl-why-should-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing 
                            elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </p>
                    </div>
                </div>
                <br />
                <div className='pl-why-should-aria pl-why-2'>
                    <div className='pl-why-should-2'>
                        <Typography component="h3" className="techvanto-whyus-heading">
                            Company Specific Mock Placement Tests
                        </Typography>
                        <p className="pl-why-should-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing 
                            elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </p>
                    </div>
                    <div className='pl-why-should'>
                        <img src={WhyShould2} className="pl-why-should-img"/>
                    </div>
                </div>
                <br />
                <div className='pl-why-should-aria'>
                    <div className='pl-why-should'>
                        <img src={WhyShould3} className="pl-why-should-img"/>
                    </div>
                    <div className='pl-why-should-2'>
                        <Typography component="h3" className="techvanto-whyus-heading">
                            Technical Training and Soft Skills
                        </Typography>
                        <p className="pl-why-should-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing 
                            elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </p>
                    </div>
                </div>
                <br />
                <div className='pl-why-should-aria pl-why-2'>
                    <div className='pl-why-should-2'>
                        <Typography component="h3" className="techvanto-whyus-heading">
                            Resume Making Sessions
                        </Typography>
                        <p className="pl-why-should-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing 
                            elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </p>
                    </div>
                    <div className='pl-why-should'>
                        <img src={WhyShould4} className="pl-why-should-img"/>
                    </div>
                </div>
                <br />
                <div className='pl-why-should-aria'>
                    <div className='pl-why-should'>
                        <img src={WhyShould5} className="pl-why-should-img"/>
                    </div>
                    <div className='pl-why-should-2'>
                        <Typography component="h3" className="techvanto-whyus-heading">
                            Interview Skills Coaching Session
                        </Typography>
                        <p className="pl-why-should-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing 
                            elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </p>
                    </div>
                </div>
                <br />
                <div className='pl-why-should-aria pl-why-2'>
                    <div className='pl-why-should-2'>
                        <Typography component="h3" className="techvanto-whyus-heading">
                            Email Writing Classes
                        </Typography>
                        <p className="pl-why-should-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing 
                            elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </p>
                    </div>
                    <div className='pl-why-should'>
                        <img src={WhyShould6} className="pl-why-should-img"/>
                    </div>
                </div>
            </section>
            <br />
            <br />
            <div style={{
                textAlign: 'center',
            }}>
                <button 
                    className="intern-register placement-apply-now" 
                    data-bs-toggle="modal" 
                    data-bs-target="#myModal"
                    style={{
                        backgroundColor: "var(--color-light-green)"
                    }}>
                    Apply Now
                </button>
            </div>
            <br />
            <section className="summer-intern" 
                style={{
                    marginTop: 0,
                    backgroundImage: 'linear-gradient(to bottom, #B3FAC3, #ffffff)'
                }}>
                <div className="wintr-intern-box">
                    <Typography 
                        component="div" 
                        variant="div" 
                        className="wintr-intern-content">
                            <p style={{
                                fontWeight: "bold",
                            }} className="place-train-header-text">
                                Indulge In Limtless Learning
                            </p>
                            <Typography component="h3" className="techvanto-whyus-heading book-first-demo" 
                            style={{
                                color: '#2FAC66',
                                marginLeft: 0,
                                marginRight: 0
                            }}>
                                BOOK YOUR FIRST DEMO WITH US
                            </Typography>
                            <button 
                                className="intern-register placement-apply-now" 
                                data-bs-toggle="modal" 
                                data-bs-target="#myModal"
                                style={{
                                    backgroundColor: "#65469B"
                                }}>
                                Book Now
                            </button>
                    </Typography>
                    <div className="pl-header">
                        <img src={PlBottomImg} />
                    </div>
               </div>
            </section>
            <div className="modal" id="myModal" style={{
                zIndex: 10000
            }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">

                        <div className="modal-header" style={{
                            border: 'none'
                        }}>
                            <h4 className="modal-title modal-header-text-style">
                                Book Demo
                            </h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div>
                                    <form onSubmit={submitForm} className="or-form">
                                        <div>
                                            <div className="form-group">
                                                <label className="secondary-text-color">Name</label>
                                                <input 
                                                type="text" 
                                                name="name" 
                                                className="form-control or-input-box" 
                                                value={info.name}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={nameError}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div>
                                            <div className="form-group">
                                                <label className="secondary-text-color">Contact Number</label>
                                                <input 
                                                type="number"
                                                name="phone" 
                                                className="form-control or-input-box" 
                                                value={info.phone}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={phoneError}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div>
                                            <div className="form-group">
                                                <label className="secondary-text-color">Email id</label>
                                                <input 
                                                type="email" 
                                                name="email" 
                                                className="form-control or-input-box" 
                                                value={info.email}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={emailError} 
                                                //helperText={name ? "Enter Your Name." : ""}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div style={{width: '47%'}}>
                                                <div className="form-group">
                                                    <label className="secondary-text-color">Date</label>
                                                    <div className="datepicker-box kids-date-input">
                                                        <DatePicker
                                                            selected={dateValue}
                                                            //onSelect={() => {handleDateSelect()}}
                                                            onChange={(date) => handleDateSelect(date, 'date')}
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={new Date()}
                                                        />
                                                        <div className="calender-icon">
                                                            <img src={CalenderIcon} width="20" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{width: '47%'}}>
                                                <div className="form-group">
                                                    <label className="secondary-text-color">Time</label>
                                                    <div className="datepicker-box kids-date-input">
                                                        <DatePicker
                                                            selected={timeValue}
                                                            //onChange={handleDateChange}
                                                            //onTimeSelect={() => {handleDateSelect()}}
                                                            dateFormat="HH ':' mm"
                                                            //dateFormat="HH ':' mm aa"
                                                            //onChange={timeCahnge}
                                                            onChange={(date) => handleDateSelect(date, 'time')}
                                                            minDate={new Date()}
                                                            timeIntervals={30}
                                                            timeFormat="HH:mm"
                                                            timeCaption="time"
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                        />
                                                        <div className="calender-icon">
                                                            <img src={TimeIcon} width="20" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div>
                                            <center> <button type="submit"
                                            className="btn-grad-resume resume-upload-btn"
                                            style={buttonLoader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-primary)'}}
                                                disabled={buttonLoader}
                                            >
                                                {buttonLoader ? <img src={ButtonLoader} width="80" alt="" /> : 'Submit'}
                                            </button></center>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        </>
    )
}
export default CampusAmbas;