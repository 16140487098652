import Experts from "../../assets/images/expert-pic.png";

const ExpertsData = [
  {
    image: Experts,
    name: "Anoosha Chandra",
    company: 'SALESFORCE',
    desig: 'Software Developer'
  },
  {
    image: Experts,
    name: "Anoosha Chandra",
    company: 'SALESFORCE',
    desig: 'Software Developer'
  },
  {
    image: Experts,
    name: "Anoosha Chandra",
    company: 'SALESFORCE',
    desig: 'Software Developer'
  },
  {
    image: Experts,
    name: "Anoosha Chandra",
    company: 'SALESFORCE',
    desig: 'Software Developer'
  },
]
export default ExpertsData;