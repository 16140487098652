import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import logoTransperent from "../../../assets/images/logo-print-hd-transparent-removebg-preview.png";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const LoginPopup = ({openPopup, setClose, prevUrl}) => {
    
    return (
        <Dialog className="pop-dialog"
          open={openPopup}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >  
        <div style={{display:'flex',justifyContent:'right',width:'100%'}}>
        <Button sx={{width:"50px",height:"50px"}} onClick={setClose}>
          <CancelOutlinedIcon sx={{opacity:"70%"}}/>
        </Button>
        </div>
        <center><img src={logoTransperent} height="100%" width="250px" alt="Logo" /></center>
        
          <DialogContent sx={{width: "550px",height:"340px",}} >
            <DialogContentText className="curriculum-popup" >
              <center><p><h2 style={{color:"var(--color-secondary)",fontWeight:"bolder"}}>Oops !!</h2></p></center>
              <p 
              style={
                {fontWeight: "bolder",
                fontSize: "25px",
                textAlign:"center",
                marginTop:"3%",
                color:"black",
              }
              }>
                  Looks like you need to be logged in to perform this action.
              </p>
              <center><p>You can login by clicking below button</p></center>
                </DialogContentText>
                <Link to={`/auth-user`} 
                    style={{ textDecoration: "none" }}
                    state={{ from: prevUrl }}>
                  <center><button type="button" className="btn-grad btn-nav">
                    LOGIN
                  </button></center>
                </Link>
          </DialogContent>
         
        </Dialog>
    )
}
export default LoginPopup;