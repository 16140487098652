import "./Loading.css";

const Loading = () => (
  <div className="backdrop">
    <div className="loading">
      <div className="wrapper">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <span style={{ color: "black" }}>Loading</span>
      </div>
    </div>
  </div>
);
export default Loading;
