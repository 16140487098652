import * as React from "react";
import { Typography, Container } from "@mui/material";
import "./adminAria.css";
import question from "../../assets/Svgs/WhatWeDo1.svg";
import question2 from "../../assets/Svgs/WhatWeDo2.svg";
import WorkshopDel from "../../assets/images/workshop_del.png";
import PromiseDel from "../../assets/images/promise_del.png";
import CommunityMem from "../../assets/images/community_mem.png";
import AdminPanelPic from "../../assets/images/admin-panel-pic.png";
//import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
//import { useIsMobile } from "../../utils/mobileScreen";

const AdminAria = () => {
  //const isMobile = useIsMobile();
  return (
    <section>
      <Container className="admin-aria-container">
        <div className="admin-aria-box">
            <div>
                <img src={AdminPanelPic} />
            </div>
            <div className="admin-text-box">
                <Typography component="h2" className="techvanto-whyus-heading admin-heading-2">
                  Techvanto's Upskilling Platform
                </Typography>
                <Typography component="p" className="admin-aria-text">
                  Techvanto Academy provides the best job-oriented courses in Delhi, 
                  designed to prepare you for the real world of work. Our platform 
                  provides a custom dashboard tailored specifically to students, offering 
                  a variety of tools and insights to help you track your progress all in 
                  one place. With everything you need at your fingertips, you can 
                  streamline your learning process and achieve your career goals faster. 
                  Join us today and take the first step towards a successful career!
                </Typography>
            </div>
        </div>
      </Container>
    </section>
  );
};

export default AdminAria;
