import React from 'react';
import OBJECTS from '../../assets/Svgs/OBJECTS.svg';
import './BookDemo.css';
import { Typography } from "@mui/material";

const DemoHeader=()=>{
    return(
    <div className="demo-head">
        <img src={OBJECTS} alt="object" className="demo-img"/>
        <div className="demo-text">
            <Typography component="h2">
                <span className="schedule">Schedule a free demo</span>
            </Typography>
            <Typography style={{color:"white",fontSize:"20px",marginTop:"1%"}}>
                We will set up your demo at the earliest convenient time.
            </Typography>
        </div>
    </div>
    )
}
export default DemoHeader;