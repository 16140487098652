import Accenture from "../../assets/images/hiring2/accenture.png";
import Amazon from "../../assets/images/hiring2/amazon.png";
import Barclays from "../../assets/images/hiring2/barclays.png";
import Delivery from "../../assets/images/hiring2/delivery.png";
import Eduvacancy from "../../assets/images/hiring2/eduvacancy.png";
import Hotstar from "../../assets/images/hiring2/hotstar.png";
import OrangeCup from "../../assets/images/hiring2/orange_cup.png";
import Pinglash from "../../assets/images/hiring2/pinglash.png";
import Rapido from "../../assets/images/hiring2/rapido.png";
import Sysclound from "../../assets/images/hiring2/sysclound.png";
import zomato from "../../assets/images/hiring2/zomato.png";

const hiringData = [
  {
    image: Accenture,
    name: "Accenture",
  },
  {
    image: Amazon,
    name: "Amazon",
  },
  {
    image: Barclays,
    name: "Barclays",
  },
  {
    image: Delivery,
    name: "Delivery",
  },
  {
    image: Eduvacancy,
    name: "Eduvacancy",
  },
  {
    image: Hotstar,
    name: "Hotstar",
  },
  {
    image: OrangeCup,
    name: "OrangeCup",
  },
  {
    image: Pinglash,
    name: "Pinglash",
  },
  {
    image: Rapido,
    name: "Rapido",
  },
  {
    image: Sysclound,
    name: "Sysclound",
  },
  {
    image: zomato,
    name: "zomato",
  },
]
export default hiringData;