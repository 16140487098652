import routes from "../routes/Services.routes";
import axios from "axios";

export default async function getEditorsTopicApi(){
    return await axios.get(routes.EditorsTopics,{
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then(res => {
        return res.data;
    }).catch(error => {
        console.log(error);
    })
}
