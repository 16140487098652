import routes from '../routes/Services.routes';
import axios from 'axios';

//export default async function UploadOnS3(){}
const UploadOnS3 = async (body) => {
    try {
        const { data } = await axios.post(routes.UploadOnS3, body, { headers: {} });
        return data;
    } catch (e) {
        throw new Error(e);
    }
};
export default UploadOnS3;