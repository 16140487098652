import React from 'react';
import Group194 from '../../assets/Svgs/Group194.svg';
import { Typography } from "@mui/material";
import './counseling.css'

const CounselingHeader=()=>{
    return(
        <div className="counsel-head">
            <div className="counsel-text">
                <Typography component="h2" className="counsel-heading-text-box">
                    <span className="councelling-heading">Are you looking for Career Counseling Online ?</span>
                </Typography>
                <Typography className="councelling-description">
                    <span>Techvanto Academy offers the best of its kind online career counseling.</span><br/>
                    <span>Get in touch with us to seek help from the career counselors</span><br/> 
                    <span>online and take your career to the new high.</span>    
                </Typography>
            </div>
            <div className="councel-header-image">
                <img src={Group194} alt="group" className="counsel-img"/>
            </div>
        </div>
    )
}
export default CounselingHeader;