import React from 'react';
import { useState } from 'react';
import { Typography } from "@mui/material";
import "./organiseWorkshop.css";
import ButtonLoader from "../../assets/images/button_loader.gif";
import { toast } from "react-toastify";
import { Partners } from "../../components";
import topClientData from '../../data/universityPartners/universityPartner';
import { IndustryExpert } from '../../components';
import OrganiseWorkshopHeadImg from "../../assets/images/organise_workshop_header_img.png";
import OrWorkshopSemImg from "../../assets/images/or_workshop_sem_img.png";
import BenefitsEventsImg from "../../assets/images/benefits-events-img.png";
import TechUsedImg from "../../assets/images/tech-used-img.png";
import addOrgWorkshopApi from "../../apis/api/AddOrgWorkshop";

const howItWorks = [
    {
        heading: 'Lorem Ipsum',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget nisl arcu pharetra aliquet fermentum.'
    },
    {
        heading: 'Lorem Ipsum',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget nisl arcu pharetra aliquet fermentum.'
    },
    {
        heading: 'Lorem Ipsum',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget nisl arcu pharetra aliquet fermentum.'
    },
]
const OrganiseWorkshop = () => {
    const [file, setFile] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [resumeError, setResumeError] = useState(true);
    const [loader, setLoader] = useState(true);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [collegeError, setCollegeError] = useState(false);
    const [techError, setTechError] = useState(false);
    const [info, setInfo] = useState({
        name: "",
        email: "",
        phone: "",
        college:"",
        technology: ""
    });
    let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const submitForm= async(e) =>{
        e.preventDefault();
        setLoader(true);
        if(!nameError && !phoneError && 
           !emailError && !collegeError && !techError)
        {
            let res;
            setButtonLoader(true)
            res = await addOrgWorkshopApi(info);
            console.log("res==========",res)
            if(res){
                // console.log("res==========",res)
                setButtonLoader(false)
                if(res.statusCode === 200){
                    console.log("success");
                    let fileInfo = {
                        name: "",
                        email: "",
                        phone: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).phone : "",
                        college:"",
                        technology: ""
                    }
                    setNameError(true);
                    setEmailError(true);
                    JSON.parse(localStorage.getItem('user')) ? setPhoneError(false) : setPhoneError(true);
                    setResumeError(true);
                    setFile(null);
                    setInfo(fileInfo);
                    toast.success(res.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }else{
                    toast.error('Something went wrong.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }
            }else{
                setButtonLoader(false);
                toast.error('Something went wrong.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }else{
            if(nameError) return errorToast('Name required.');
            if(phoneError) return errorToast('Invalid phone number entered.');
            if(emailError) return errorToast('Invalid email entered.');
            if(collegeError) return errorToast('College Required.');
            if(techError) return errorToast('Technology Required.');
        }
    }
    const formValidate = (event) => {
        if (event.name === "name") {
          setInfo({ ...info, name: event.value });
          if(event.value === "") return setNameError(true);
          setNameError(false);
        }
        if (event.name === "email") {
          setInfo({ ...info, email: event.value });
          if(event.value === "" || !event.value.match(emailValidate)) return setEmailError(true);
          setEmailError(false);
        }
        if (event.name === "phone") {
          setInfo({ ...info, phone: event.value });
          if(event.value !== "" && event.value.length === 10) return setPhoneError(false);
          setPhoneError(true);
        }
        if (event.name === "college_name") {
            setInfo({ ...info, college: event.value });
            if(event.value !== "") return setCollegeError(false);
            setCollegeError(true);
          }
          if (event.name === "tech") {
            setInfo({ ...info, technology: event.value });
            if(event.value !== "") return setTechError(false);
            setTechError(true);
          }
      }
    const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    return (
        <>
            <section className="summer-intern" style={{
                backgroundImage: 'linear-gradient(to bottom, #8FBDF5, #fff)'
            }}>
                <div className="wintr-intern-box">
                    <Typography 
                        component="div" 
                        variant="div" 
                        className="wintr-intern-content">
                            <Typography 
                                component="h2" 
                                className="techvanto-whyus-heading or-top-heading">
                                We Organize Perfect Workshop for You!
                            </Typography>
                            <p style={{
                                color: '#0492A5',
                                marginBottom: '24px'
                            }} className="or-top-small-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                            <button 
                                className="intern-register placement-apply-now" 
                                data-bs-toggle="modal" 
                                data-bs-target="#myModal"
                                style={{
                                    backgroundColor: "#124C80"
                                }}>
                                Organise Workshop
                            </button>
                    </Typography>
                    <div className="pl-header">
                        <img src={OrganiseWorkshopHeadImg} />
                    </div>
               </div>
            </section>
            <br />
            <Partners data={topClientData} heading="Trusted by" />
            <br />
            <section className='or-event-box'>
                <div className='or-img-box or-img-box-1'>
                    <img src={OrWorkshopSemImg} />
                </div>
                <div className='or-event-des-box or-img-box-1'>
                    <p style={{
                        color: 'var(--color-secondary-text)'
                    }}>
                        Event Organize by Techavnto Academy
                    </p>
                    <Typography component="h2" className="techvanto-whyus-heading or-work-heading">
                        Workshops, Seminars & Events
                    </Typography>
                    <p className="or-workshop">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu, ultricies ac dictumst volutpat fermentum. 
                        Malesuada elementum lorem massa quis. Ut sed dui faucibus pulvinar enim et porttitor cras. Ipsum amet leo 
                        dignissim diam id tempor, aliquam, vel felis. Mi pellentesque lacus, maecenas placerat ligula scelerisque.
                    </p>
                </div>
            </section>
            <br/>
            <br/>
            <br/>
            <section className="or-benefits" style={{
                backgroundColor: 'rgb(209, 235, 252, 0.6)'
            }}>
                <div className="or-bene-1">
                    <Typography component="h2" className="techvanto-whyus-heading or-benef-heading">
                        Benefits of Organizing Event with us!
                    </Typography>
                </div>
                <div className="or-img-box or-bene-1">
                    <img src={BenefitsEventsImg} />
                </div>
            </section>
            <br/>
            <section>
                <Typography component="h2" className="techvanto-whyus-heading or-how-work">
                    How It Works
                </Typography>
                <div className='or-how-it-work-ans'>
                    {howItWorks.map((item, index) => (
                    <div className="or-how-it-work-ans-card">
                        <h4 style={{
                            color: 'var(--color-black)',
                            fontWeight: 'bold'
                        }}>
                            0{index+1}
                        </h4>
                        <h5 style={{
                            color: 'var(--color-primary)'
                        }}>
                            {item.heading}
                        </h5>
                        <p style={{
                            color: 'var(--color-secondary-text)',
                        }}>
                            {item.desc}
                        </p>
                    </div>
                    ))}
                </div>
            </section>
            <br/>
            <br/>
            <section className='or-tech-used'>
                <Typography component="h2" className="techvanto-whyus-heading or-how-work">
                    Technology We Used 
                </Typography>
                <br/>
                <div>
                    <img src={TechUsedImg} />
                </div>
                <div>

                </div>
            </section>
            <br/>
            <br/>
            <section>
                <IndustryExpert />
            </section>
            <br/>
            <br/>
            <div className="modal" id="myModal" style={{
                zIndex: 10000
            }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">

                        <div className="modal-header" style={{
                            border: 'none'
                        }}>
                            <h4 className="modal-title modal-header-text-style">
                                Organise Workshop Form
                            </h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div>
                                    <form onSubmit={submitForm} className="or-form">
                                        <div>
                                            <div className="form-group">
                                                <label className="secondary-text-color">Name</label>
                                                <input 
                                                type="text" 
                                                name="name" 
                                                className="form-control or-input-box" 
                                                value={info.name}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={nameError}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div>
                                                <div className="form-group">
                                                    <label className="secondary-text-color">Contact Number</label>
                                                    <input 
                                                    type="number"
                                                    name="phone" 
                                                    className="form-control or-input-box" 
                                                    value={info.phone}
                                                    onChange={((e)=>formValidate(e.target))}
                                                    error={phoneError}
                                                    required/>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-group">
                                                    <label className="secondary-text-color">Email id</label>
                                                    <input 
                                                    type="email" 
                                                    name="email" 
                                                    className="form-control or-input-box" 
                                                    value={info.email}
                                                    onChange={((e)=>formValidate(e.target))}
                                                    error={emailError} 
                                                    //helperText={name ? "Enter Your Name." : ""}
                                                    required/>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div>
                                            <div className="form-group">
                                                <label className="secondary-text-color">College Name</label>
                                                <input 
                                                type="text" 
                                                name="college_name" 
                                                className="form-control or-input-box" 
                                                value={info.college}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={collegeError}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div>
                                                <div className="form-group">
                                                    <label className="secondary-text-color">Technology(courses)</label>
                                                    <input 
                                                    type="text"
                                                    name="tech" 
                                                    className="form-control or-input-box" 
                                                    value={info.technology}
                                                    onChange={((e)=>formValidate(e.target))}
                                                    error={techError}
                                                    required/>
                                                </div>
                                            </div>
                                            <div>
                                                
                                            </div>
                                        </div>
                                        <br/>
                                        <div>
                                            <center> <button type="submit"
                                            className="btn-grad-resume resume-upload-btn"
                                            style={buttonLoader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-primary)'}}
                                                disabled={buttonLoader}
                                            >
                                                {buttonLoader ? <img src={ButtonLoader} width="80" alt="" /> : 'Submit'}
                                            </button></center>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        </>
    )
}
export default OrganiseWorkshop;