import Box from "@mui/material/Box";
import AllEventsContent from '../../components/AllEventsContent/AllEventsContent';
import { motion } from 'framer-motion/dist/framer-motion';

const AllEvents = () => {
    
    return (
        <>
            <Box
                component="section"
                className="page-heading course-mobile-view"
                sx={{
                // background: `url(https://tv-academy-assets.s3.eu-west-2.amazonaws.com/Events.jpg)`,
                background: `#1C477C url(${
                    window.matchMedia("(max-width: 668px)").matches
                    ? ""
                    : "https://tv-academy-assets.s3.eu-west-2.amazonaws.com/Events.jpg"
                }) 0 0 no-repeat`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                }}
            >
                <motion.div
                className="animate-margin"
                initial={[{ opacity: 0 }]}
                whileInView={[{ opacity: 1, marginLeft: 0 }]}
                transition={{ duration: 1 }}
                >
                    <div className="course-container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <p className="home">
                            Home
                            <div className="line"></div>
                            </p>
                        </li>
                        <li className="breadcrumb-item active">Events</li>
                        </ol>
                    </nav>
                    <h1 className="event-heading">Events</h1>
                    </div>
                </motion.div>
            </Box>
            <AllEventsContent/>
        </>
    )
}
export default AllEvents;