import React from 'react';
import { useEffect, useState } from 'react';
import WinterIeaderImage from '../../assets/images/winter_header_image.png';
import { Typography, Container, CardActions } from "@mui/material";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.development";
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import getAllReferals from '../../apis/api/GetReferals';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import "./winterTraining.css";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Partner from "../../components/Partners/Partners";
import ReviewAria from "../../components/RviewAria/ReviewAria";
import Partners from "../../components/Partners/Partners";
import AllCourseApi from "../../apis/api/AllCourse";
import ButtonLoader from "../../assets/images/button_loader.gif";
import TopCoursesAria from "../../components/TopCoursesAria/TopCoursesAria";
import IitHyderabad from "../../assets/images/topClient2/iit_hyderabad.png";
import IitBhumneswar from "../../assets/images/topClient2/iit_bhubnes.png";
import IitKanpur from "../../assets/images/topClient2/iit_kanpur.png";
import OsmaniaUniv from "../../assets/images/topClient2/osmania_univ.png";
import Lpu from "../../assets/images/topClient2/lpu_punjab.png";
import IitJalandhar from "../../assets/images/topClient2/nit_jalandhar.png";
import PunjabUniv from "../../assets/images/topClient2/punjab_univ.png";
import IimNoida from "../../assets/images/topClient2/iimt_noida.png";
import IitJabalpur from "../../assets/images/topClient2/iit_jabalpur.png";
import TrainingBenefits from "../../assets/images/training_benefits.png";
import RedCheck from "../../assets/images/red_check.png";
import InternCertificate from "../../assets/images/intern-certificate.png";

import Accenture from "../../assets/images/hiring2/accenture.png";
import Amazon from "../../assets/images/hiring2/amazon.png";
import Barclays from "../../assets/images/hiring2/barclays.png";
import Delivery from "../../assets/images/hiring2/delivery.png";
import Eduvacancy from "../../assets/images/hiring2/eduvacancy.png";
import Hotstar from "../../assets/images/hiring2/hotstar.png";
import OrangeCup from "../../assets/images/hiring2/orange_cup.png";
import Pinglash from "../../assets/images/hiring2/pinglash.png";
import Rapido from "../../assets/images/hiring2/rapido.png";
import Sysclound from "../../assets/images/hiring2/sysclound.png";
import zomato from "../../assets/images/hiring2/zomato.png";

import { useSelector, useDispatch } from "react-redux";
import addSummerOrderApi from "../../apis/api/AddSummerOrder";
import { toast } from "react-toastify";
import { logoutAction } from "../../redux/slices/auth.slices";
import verifyOrderApi from "../../apis/api/OrderVerify";
import useRazorpay from "react-razorpay";
import FormImage from "../../assets/images/form_image.png";
import LoginPopup from "../../components/Generic/LoginPopup/LoginPopup";
import WintrRegistrCard from "../../assets/images/wintr-registr-card.png";

const topClientData = [
    {
        image: IitHyderabad,
        name: "IIT Hyderabad",
    },
    {
        image: IitBhumneswar,
        name: "IIT Bhubaneswar",
    },
    {
        image: IitKanpur,
        name: "IIT Kanpur",
    },
    {
        image: OsmaniaUniv,
        name: "Osmania University",
    },
    {
        image: Lpu,
        name: "LPU",
    },
    {
        image: IitJalandhar,
        name: "IIT Jalandhar",
    },
    {
        image: PunjabUniv,
        name: "Punjab University",
    },
    {
        image: IimNoida,
        name: "IIM Noida",
    },
    {
        image: IitJabalpur,
        name: "IIT Jabalpur",
    }
  ]

const hiringData = [
{
    image: Accenture,
    name: "Accenture",
},
{
    image: Amazon,
    name: "Amazon",
},
{
    image: Barclays,
    name: "Barclays",
},
{
    image: Delivery,
    name: "Delivery",
},
{
    image: Eduvacancy,
    name: "Eduvacancy",
},
{
    image: Hotstar,
    name: "Hotstar",
},
{
    image: OrangeCup,
    name: "OrangeCup",
},
{
    image: Pinglash,
    name: "Pinglash",
},
{
    image: Rapido,
    name: "Rapido",
},
{
    image: Sysclound,
    name: "Sysclound",
},
{
    image: zomato,
    name: "zomato",
},
]
const WinterTraining = () => {
    const [error, setError] = useState(false);
    const [collegeError, setCollegeError] = useState(false);
    const [courseByCategory, setCourseByCategory] = useState();
    const [loading, setLoading] = useState();
    const [courseList, setCourseList] = useState([]);
    const [college, setCollege] = useState("");
    const [selectedCourse, setSelectedCourse] = useState([]);
    const [radioValue, setRadioValue] = useState("full fee");
    const [referalError, setReferalError] = useState(false);
    const [referalCode, setReferalCode] = useState("");
    const [referalSucces, setReferalSucces] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [verifyLoader, setVerifyLoader] = useState(false);
    const [referalData, setReferalData] = useState();
    const [verifiedRefralCode, setVerifiedRefralCode] = useState();
    const [refreeName, setRefreeName] = useState();
    const [isItemSelected, setIsItemSelected] = useState('');
    const [checkoutLoader, setCheckoutLoader] = useState(false);
    const [openLoginPopup, setOpenLoginPopup] = useState(false);
    const [OrderId, setOrderId] = useState();
    const [paymentId, setPaymentId] = useState();
    const [paymentMessage, setPaymentMessage] = useState("");
    const [open, setOpen] = useState(false);
    let verifiedCode = [];
    let dispatch = useDispatch();
    const Razorpay = useRazorpay();
    let location = useLocation();
    let { admin, isLogin } = useSelector((state) => state.AuthReducer);

    useEffect(() => {
        getCourseData()
        
    }, [])

    const getCourseData = async() => {
        let data = [];
       data = await AllCourseApi(setCourseList, setCourseByCategory, setLoading);
       if(data?.length > 0) {
        setCourseList(data.filter((item) => {
            return item.category === "College";
        }))
       }
    }

    const getCollege = (event) => {
        event.target.value === "" ? setCollegeError(true) : setCollegeError(false);
        setCollege(event.target.value);
    }

    const selectCourse = (event) => {
        setError(false);
        setSelectedCourse(courseList.filter((item) => {
            return item._id === event.target.value;
        }))
    }
    const selectPaymentType = (event) => {
        setRadioValue(event.target.value)
    }
    const getReferalCode = (event) => {
        setReferalSucces(false)
        event.target.value === "" ? setReferalError(true) : setReferalError(false)
        event.target.value === "" ? setErrorText("Enter Referal Code") : setErrorText("")
        setReferalCode(event.target.value);
    }
    const handleVerify = async(event) => {
        event.preventDefault();
        setReferalSucces(false)
        setVerifyLoader(true)
        referalCode === "" ? setReferalError(true) : setReferalError(false)
        referalCode === "" ? setErrorText("Enter Referal Code") : setErrorText("")
        if(referalCode !==  "") {
          let allReferals = await getAllReferals(setReferalData, setVerifyLoader);
          if(allReferals) {
            verifiedCode = allReferals.filter(item => {
                return item.referal_code === referalCode
            });
          }
          
          if(verifiedCode.length < 1){
            setReferalError(true)
            setErrorText("Invalid Referal Code")
          }
          else{
            setVerifiedRefralCode(verifiedCode[0].referal_code)
            setRefreeName(verifiedCode[0].refree_name)
            setReferalSucces(true);
            setReferalError(false);
          }
        }
        else{
          setVerifyLoader(false)
        }
        return;
    }
    const registerNow = async() => {
        setCheckoutLoader(true);
        if(isLogin){
            setCollegeError(false);
            if(college === ""){ 
                setCheckoutLoader(false)
                return setCollegeError(true)
            }
            if(selectedCourse.length === 0){
                setCheckoutLoader(false);
                return setError(true)
            }
            if(referalCode === "" || referalSucces){
                let fee = "";
                ((selectedCourse[0]?.discount > 0) && new Date(selectedCourse[0]?.discount_limit_date) >= new Date()) ?
                    fee = parseInt(document.getElementById("totalPrice2").innerHTML) :
                    fee = parseInt(document.getElementById("totalPrice1").innerHTML)
                let body = {
                    college: college,
                    course_id: selectedCourse[0]?._id,
                    order_type: "paid",
                    total_amount: fee,
                    refree_name: refreeName ? refreeName : null,
                    refral_code: verifiedRefralCode ? verifiedRefralCode : null
                }
                let order = await addSummerOrderApi(body);
                if(order?.data?.message === "Unauthorized"){
                    toast.error("Unauthorized", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      dispatch(logoutAction());
                      setOpenLoginPopup(true);
                }
                console.log("price==",fee);
                const options = {
                    key: process.env.REACT_APP_RAZORPAY_KEY,
                    amount: fee*100,
                    currency: "INR",
                    name: "Techvanto Academy",
                    description: "Transaction",
                    image: "https://i.ibb.co/jVR0Kyc/logo-3.png",
                    // order_id: order.data.order.order_id,
                    modal: {
                      ondismiss: async function () {
                       //console.log("hello",response)
                      },
                    },
                    handler: async function (response) {
                      let verificationDetail = {
                        razorpay_payment_id: response.razorpay_payment_id,
                        order_id: response.razorpay_order_id,
                        cart_item: body,
                        placed_from: "summer"
                      };
                      setOrderId(response.razorpay_order_id);
                      setPaymentId(response.razorpay_payment_id);
                      setCheckoutLoader(false);
                      await verifyOrderApi(verificationDetail, setPaymentMessage, setOpen);
                    },
                    prefill: {
                      name: admin.name,
                      email: admin.email,
                      contact: admin.phone,
                    },
                    notes: {
                      address: "Razorpay Corporate Office",
                    },
                    theme: {
                      color: "#3399cc",
                    },
                  };
                  const rzp1 = new Razorpay(options);
                  rzp1.on("payment.failed", function (response) {
                    let verificationDetail = {
                      razorpay_payment_id: response.error.metadata.payment_id,
                      order_id: response.error.metadata.order_id,
                      cart_item: body
                    };
                    setOrderId(response.error.metadata.order_id);
                    setPaymentId(response.error.metadata.payment_id);
                    //options.modal.ondismiss(verificationDetail)
                    verifyOrderApi(verificationDetail, setPaymentMessage, setOpen);
                  });
                  setCheckoutLoader(false);
                  rzp1.open();
            }else{
                setCheckoutLoader(false)
            }
        }else{
            toast.error("Unauthorized", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              dispatch(logoutAction());
              setOpenLoginPopup(true)
              setCheckoutLoader(false);
        }
    }
    return (
        <>
            <section className="summer-intern" >
                <div 
                    className="wintr-intern-box">
                    <Typography 
                        component="div" 
                        variant="div" 
                        className="wintr-intern-content">
                            <p className="intern-text winter-text">Techvanto Academy Winter Training Program'23</p>
                            <p className="wintr-small-text">
                                Strive for perfection with our crash course. Each class is held over 15 days or 30 days.
                            </p>
                            <HashLink  to="/techvanto-winter-internship/#registerForm" 
                                className="intern-register summer-register" 
                                style={{textDecoration:'none'}}
                                >
                                Register Now
                            </HashLink>
                    </Typography>
                    <img src={`${WinterIeaderImage}`} alt="Summer Internship Image" className="intern-header-image winter-header-image"/>
                    
               </div>
            </section>
            <br />
            <Partner data={topClientData} heading="Partnership with Top Universities" />
            <br />
            <div className="what-is-wintr-training-box">
                <Typography component="h2" className="techvanto-whyus-heading heading-alignment">
                    What is winter training ?
                </Typography>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis in tempor fermentum lacinia consequat. Vitae 
                    diam in neque, dolor. Tincidunt dis odio in feugiat nullam malesuada. Vel sed mollis vitae enim felis justo amet 
                    neque lectusLorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis in tempor fermentum lacinia consequat. 
                    Vitae diam in neque, dolor. Tincidunt dis odio in feugiat nullam malesuada. Vel sed mollis vitae enim felis justo 
                    amet nequ.
                </p>
            </div>
            <br />
            <br />
            <div className="wintr-inter-registr-aria">
                <div className="wintr-training-img-box">
                    <img src={TrainingBenefits} />
                </div>
                <div>
                    <div className="wintr-intrn-heading">
                        <Typography component="h2" className="techvanto-whyus-heading heading-alignment wintr-intrn-text">
                            Benefits from techvanto academy training
                        </Typography>
                    </div>
                    <div className="wintr-training-prog-bene">
                        <p className="program-last-for-15">The Program lasts for 15 or 30 days, but it offers tons of great benefits:</p>
                        <div className="wintr-training-benefit-list">
                            <div>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Incentives (Variable Based)</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Free Trainings</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Full time job offer</p>
                                </div>

                            </div>
                            <div className="wintr-training-right-list">
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Internship Offers</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Certificate Of Excellence</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Learn Business and Management Skills</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wintr-registr-btn">
                        <HashLink  to="/techvanto-winter-internship/#registerForm" 
                            className="intern-register summer-register" 
                            style={{textDecoration:'none'}}
                            >
                            Register Now
                        </HashLink>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <TopCoursesAria />
            <br />
            <br />
            <div className="wintr-registr-cert-aria">
                <div className="wintr-registr-cert-heading">
                    <Typography component="h2" className="techvanto-whyus-heading heading-alignment">
                        Certificate Of Completion
                    </Typography>
                    <p className="wintr-registr-cert">Receive a Certificate of Internship at the end of the training and showcase your acquired skills to the world.</p>
                </div>
                <div className="wintr-registr-cert-pic-box">
                    <img src={InternCertificate} className="wintr-cert-pic" />
                </div>
            </div>
            <br />
            <br />
            {/* <section>
                <Container className="main-container-box-prev">
                    <div className="enroll-box" 
                        style={{
                            background: 'linear-gradient(to right, #4249F0, #9922C3)',
                            alignItems: 'center',
                            position: 'relative',
                            }}>
                        <div>
                            <p className="want-to-learn-explain">We have all the technology to make you smarter. Get Smarter With Tech today.</p>
                        </div>
                        <div>
                            <button className="enroll-now-know-more">
                                Register Now
                            </button>
                        </div>
                        <div>
                            <img src={WintrRegistrCard} width="180" className="wintr-card-image" />
                        </div>
                    </div>
                </Container>
            </section> */}
            <ReviewAria />
            <br />
            <Typography
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    margin: '20px 0'
                    }}
                className="second-section"
            >
                <div className="inner-box wintr-inner-box" id="registerForm">
                    <Typography 
                        component="div" 
                        variant="div"
                        className="register-form-box wintr-registr-form"
                    >
                        <div className="image-aria form-image-aria" style={{border: "none"}}>
                            <img src={`${FormImage}`} alt="Register Now" />
                        </div>
                        <div style={{width: '100%'}} className="form-aria">
                            <div
                                className="input-fields input-fields2"
                            >
                                {(error || collegeError) && 
                                <p style={{
                                    margin: '0',
                                    color: 'var(--color-secondary)',
                                    fontWeight: '600'}}>
                                    {error && `Select a course` }
                                    {collegeError && `Enter Your College` }
                                </p>}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'}}
                                >
                                    <input type="text" 
                                        onChange={getCollege} 
                                        placeholder="College Name *"
                                        className="college-input-box college-input2"
                                        style={{ 
                                            background: 'var(--color-dark-yellow)',
                                            border: '1px solid var(--color-black)'
                                            }} />
                                    <select onChange={selectCourse} 
                                        style={{ 
                                            background: 'var(--color-dark-yellow)',
                                            border: '1px solid var(--color-black)'}}>
                                        <option disabled selected>Select Course *</option>
                                        {courseList.length > 0 &&
                                            courseList.map((item,index) => (
                                                <option key={index} value={item._id}>{item.course_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                {selectedCourse.length > 0 &&
                                <div className="details-section wintr-details-section">
                                    {/* {selectedCourse[0]?.discount === 0 && <p>Price: Rs. 
                                        <b id="totalPrice1">{radioValue === "full fee" ? selectedCourse[0]?.price : 
                                            selectedCourse[0]?.registration_fee}</b> /-
                                    </p>} */}
                                    {((selectedCourse[0]?.discount > 0) && 
                                    new Date(selectedCourse[0]?.discount_limit_date) >= new Date()) ?
                                        <div>Price: Rs. &nbsp; {radioValue === "full fee" ? <del>{selectedCourse[0]?.price}</del> : ``}
                                            <span style={{marginLeft: '4px', fontSize: '16px', textAlign: 'right'}}>
                                                <b id="totalPrice2">{radioValue === "full fee" ? 
                                                    parseInt(selectedCourse[0]?.price) - parseInt(selectedCourse[0]?.discount) :
                                                    selectedCourse[0]?.registration_fee
                                                }</b> /-
                                            </span>
                                        </div>:
                                        <p>Price: Rs. 
                                            <b id="totalPrice1">{radioValue === "full fee" ? selectedCourse[0]?.price : 
                                                selectedCourse[0]?.registration_fee}</b> /-
                                        </p>
                                    }
                                    <Link to={`/courses/${selectedCourse[0]?._id}`}>View course details</Link>
                                </div>}
                            </div>
                            <div className="wintr-radio-aria">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    value={radioValue}
                                    onChange={selectPaymentType}
                                    name="radio-buttons-group"
                                    className="radio-aria"
                                >
                                    <FormControlLabel 
                                        value="full fee" 
                                        control={<Radio 
                                            sx={{color: 'var(--color-black)',
                                            '&.Mui-checked': {
                                                color: 'var(--color-secondary-red)',
                                            },
                                            }} 
                                            />} 
                                        label="Register with full payment"
                                        sx={{color: 'var(--color-black)'}} />
                                    <FormControlLabel 
                                        value="registration fee" 
                                        control={<Radio
                                            sx={{color: 'var(--color-black)',
                                            '&.Mui-checked': {
                                                color: 'var(--color-secondary-red)',
                                            },
                                            }} 
                                            />} 
                                        label="Register with registration fee"
                                        sx={{color: 'var(--color-black)'}} />
                                </RadioGroup>
                                <CardActions 
                                    sx={{display: "flex", flexDirection: "column"}}
                                    className="referal-code referal-code2"
                                    >
                                    <FormControl
                                        sx={{ height: '48px'}}
                                        className="referal-aria wintr-refral-aria"
                                    >
                                        <InputLabel 
                                            htmlFor="component-outlined" 
                                            error={referalError}
                                            sx={{
                                                color: 'var(--color-black)',
                                            }}
                                            
                                        >
                                            Referal Code(Optional)
                                        </InputLabel>
                                        <OutlinedInput
                                        id="component-outlined"
                                        label="Referal Code(Optional)"
                                        value={referalCode}
                                        onChange={(e) => getReferalCode(e)}
                                        error={referalError}
                                        sx={{
                                            color: 'var(--color-black)',
                                            '&.Mui-focused': {
                                                color: 'var(--color-secondary-red)',
                                            }
                                        }}
                                        />
                                        <button
                                        className="btn-verify"
                                        onClick={(e) => handleVerify(e)}
                                        disabled={verifyLoader}
                                        style={verifyLoader ? {backgroundColor: "var(--color-disable)"} :
                                        {backgroundColor: "var(--color-secondary)"}}
                                        >

                                        {verifyLoader ? <img src={ButtonLoader} width="40" alt="" /> : 
                                        'Verify'}
                                        </button>
                                        {referalError && <p className="referal-error">{errorText}</p>}
                                        {referalSucces && <p 
                                        className="referal-error"
                                        style={{
                                            color: 'rgb(13 207 32)',
                                            fontWeight: '800'
                                        }}
                                        >
                                        Applied Successfully({refreeName})
                                        <CheckCircleIcon className="check-icon" />
                                        </p>}
                                    </FormControl>
                                    <p style={{color: "var(--color-secondary)", margin:"0"}}>{isItemSelected}</p>
                                </CardActions>
                                <button 
                                    onClick={registerNow} 
                                    className="intern-register inter-btn wintr-btn" 
                                    style={
                                        checkoutLoader
                                          ? { backgroundColor: "var(--color-disable)" }
                                          : { backgroundColor: "var(--color-secondary)" }
                                    }
                                    disabled={checkoutLoader}
                                    >
                                        {checkoutLoader ? <img src={ButtonLoader} width="80" /> : "Register Now"}
                                </button>
                            </div>
                        </div>
                    </Typography>
                </div>
            </Typography>
            <br />
            <Partners data={hiringData} heading="Hiring Partner" />
            <LoginPopup openPopup={openLoginPopup} setClose={() => setOpenLoginPopup(false)} prevUrl={location.pathname} />
        </>
    )
}
export default WinterTraining;