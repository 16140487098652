import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import getDoneExercisesApi from '../../apis/api/GetDoneExcercises';
import getUndoneExercisesApi from '../../apis/api/GetUndoneExercises';
import ExcerciseHeaderImage from '../../assets/images/excercise_header_pic.png';
import Loader from "../Loader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import EditorHeader from "./EditorHeader";
import EditorTopMenu from "./EditorTopMenu";

function Editors(){
    const [undoneExercise, setUndoneExercise] = useState([]);
    const [doneExercise, setDoneExercise] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    let { isLogin } = useSelector((state) => state.AuthReducer);
    
    useEffect(() => {
    },[])

    const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    return(
        <>
            {isLoader ? <Loader /> :
            <>
                <EditorHeader 
                    heading={'Editors'}
                    description={'Practice your way to the top.'}
                    image={ExcerciseHeaderImage}/>
                <EditorTopMenu />
            </>
            }
        </>
    )
}
export default Editors;