import * as React from "react";
import { Typography, Container } from "@mui/material";
import "./topCoursesAria.css";
import { Link } from "react-router-dom";
import myCourses from "../../assets/images/myCourses.png";
import TopCourseBg from "../../assets/images/top_course_bg.png";
import AliceCarousel from "react-alice-carousel";
import LessionIcon from "../../assets/images/lession.png";
import TotalEnrolledIcon from "../../assets/images/total-enrolled.png";
import getTopCourseApi from "../../apis/api/TopCourses";
import Rating from "@mui/material/Rating";
//import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
//import { useIsMobile } from "../../utils/mobileScreen";
const responsive = {
    400: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};

const TopCoursesAria = () => {
  //const isMobile = useIsMobile();
  const [topCourses, setTopCourses] = React.useState([]);
  const itemData = [
    {
      pic: myCourses,
      title1: "Sr. Full Stack Developer",
      title2: "Verizon Data Services",
      author: "Deepa Paikar",
    },
    {
      pic: myCourses,
      title1: "Software Engineer",
      title2: "Salesforce",
      author: "Anoosha Chandra",
    },
    {
        pic: myCourses,
        title1: "Software Engineer",
        title2: "Salesforce",
        author: "Anoosha Chandra",
      }
    ]
  
  React.useEffect(async () => {
    getTopCourse()
  },[])
  const getTopCourse = async() => {
    let topCourseData = await getTopCourseApi(setTopCourses);
    if(topCourseData){
        
    }
  }

  let items = topCourses.map((data, index) => (
    <TopCoursesData data={data} key={index}></TopCoursesData>
  ));

  return (
    <section>
      <Container className="admin-aria-container top-courses-box">
        <div className="top-courses-aria" 
            style={{
                background: `url(${TopCourseBg}`, 
                backgroundRepeat: 'no-repeat'}}>
            <Typography component="h2" className="techvanto-whyus-heading heading-alignment home-head">
                Top Courses
            </Typography>
            <div>
                <AliceCarousel
                    className="techvanto-student-says-carousel"
                    responsive={responsive}
                    autoPlayDirection={"rtl"}
                    autoPlayStrategy={"none"}
                    animationEasingFunction={"ease"}
                    autoPlay={false}
                    controlsStrategy="alternate"
                    infinite={true}
                    mouseTracking
                    items={items}
                    renderPrevButton={() => {
                        return <div className="left-arrow-studentSays">◄</div>;
                    }}
                    renderNextButton={() => {
                        return <div className="right-arrow-studentSays">►</div>;
                    }}
                />
            </div>
            {/* <div>
                <img src={AdminPanelPic} />
            </div>
            <div className="admin-text-box">
                <Typography component="h2" className="techvanto-whyus-heading admin-heading-2">
                    Lorem Ipsum
                </Typography>
                <Typography component="p" className="admin-aria-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sapien porttitor dolor, ultrices in ac at gravida morbi. 
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sapien porttitor dolor, ultrices in ac at gravida morbi.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sapien porttitor dolor, ultrices in ac at gravida morbi.
                </Typography>
            </div> */}
        </div>
      </Container>
    </section>
  );
};
const TopCoursesData = (props) => {
    return (
        <main className="main">
          <section className="container">
            <article className="review">
              <div className="img-container top-course-card-new">
                <Link to={`/courses/${props.data?._id}`} style={{
                  textDecoration: 'none'
                }}>
                  <div style={{position: 'relative'}}>
                      <img src={props.data?.posterImageUrl} alt="" className="t-course-img" />
                      <div className="price-circle">
                          <div style={{
                            display: 'flex',
                          }}>
                            <small>Rs</small>&nbsp; 
                            <div style={
                              {textDecoration: (new Date(props?.data?.discount_limit_date) - new Date() > 0) ? 'line-through' : 'none'}}>
                                {props.data?.price}
                              </div>
                          </div>
                          {(new Date(props?.data?.discount_limit_date) - new Date() > 0) && 
                          <div>
                            {props.data?.price - props.data.discount}
                          </div>}
                      </div>
                      {/* <div style={{position: 'absolute', width: '100px'}}>
                        <div className="horizontal-line">
                        </div>
                      </div> */}
                  </div>
                  <div className="t-course-detail">
                      <h4 className="t-course-cat">{props.data?.course_name}</h4>
                      {/* <h5 className="t-course-name">{props.data?.category}</h5> */}
                      <p className="t-course-desc">
                          {props.data?.subtitle}
                      </p>
                      <div className="t-course-bottom">
                          <div className="t-course-bottom-box">
                              <img src={LessionIcon} />
                              <p>{props.data?.noOfLectures} Lectures</p>
                          </div>
                          <div className="t-course-bottom-box">
                              <img src={TotalEnrolledIcon} />
                              <p>{props.data?.noOfEnrollment} Enrolled</p>
                          </div>
                          <div className="t-course-bottom-box">
                              <Rating
                                  name="size-small"
                                  value={props.data?.avgRating}
                                  precision={0.1}
                                  readOnly
                                  size={"small"}
                                  className="t-star-style"
                                  style={{left: 5}}
                              />
                              <span className="t-course-stars">({props.data?.noOfReviews} reviews)</span>
                          </div>
                      </div>
                  </div>
                </Link>
              </div>
            </article>
          </section>
        </main>
      );
}
export default TopCoursesAria;
