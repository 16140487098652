import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";
import "./CourseCard.css";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCourse } from "../../../redux/slices/course.slice";
//Code below for addToCart
import addToCart from "../../../apis/api/AddToCart";
import ButtonLoader from "../../../assets/images/button_loader.gif";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import logoTransperent from "../../../assets/images/logo-print-hd-transparent-removebg-preview.png";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function CourseCard({
  id,
  title,
  pic,
  gradient,
  noOfReviews,
  price,
  discount,
  rating,
  description,
  isBaught,
  courseType,
  eventDate,
  discountExpire
}) {
  const [courseData, setCourseData] = React.useState();
  const [courses ,setCourses] = React.useState();
  const [loading, setLoading] = React.useState();
  const [error, setError] = React.useState();
  const [loader, setLoader] = React.useState(false); //added loader
  let { isLogin } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let diffHour = Math.floor((new Date(discountExpire) - new Date()) / 3600000);

  const handleCourse = (id) => {
    if(courseType === "event"){

    }else{
      dispatch(getCourse({id, setCourseData, setCourses, setLoading, setError, isLogin}))
    }
  }
 
  return (
    // <Link to={`/${courseType === 'event' ? courseType : 'courses'}/${id}`} style={{ textDecoration: "none" }} onClick={() => handleCourse(id)}>
    <div className="new-top-card">
      <Card className="technovanto-course-card mobile-view-course-card">
        <div
          style={{
            background: `linear-gradient(${gradient})`,
          }}
          className="card-grad"
        > 
          <Link to={`/${courseType === 'event' ? courseType : 'courses'}/${id}`} style={{ textDecoration: "none" }} onClick={() => handleCourse(id)}>
          <CardMedia
            component="div"
            className="techvanto-all-course-image course-image-box"
          >
            <img src={`${pic}`} className="course-pic" alt="" />
          </CardMedia>
          </Link>
        </div>
        <CardContent
          style={{ borderBottom: "1px solid #d0d9df", position: "relative"}}
          className="card-title"
        >
          <Typography variant="body2" color="text.secondary">
            {title}{" "}
            {/* <span style={{ float: "right" }}>Rs.{price - discount}</span>
            </Chip> */}
          </Typography>
          {courseType === undefined && (!isBaught ? 
            <>
              {diffHour > 0 ?
              <>
                <p className="discounted-price">Rs<del>{price}</del> {price - discount}</p>
              </>:
              <Chip
                label= {`Rs ${price}`}
                style={{ float: "right", background: "#f1effe", marginRight: '10px' }}
              />}
            </>
            :
            <Chip
              label= {`Purchased`}
              style={{ float: "right", color: '#fff', marginRight: '10px' }}
              className="btn-purchased"
            />)
          }
        </CardContent>
        <div
          style={{
            display: "flex",
            whiteSpace: "nowrap",
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginTop: '16px'
          }}
        >
          {courseType === undefined || courseType === "course" ? 
          <>
            <div style={{display: "flex", 
              alignItems: "center", 
              justifyContent: 'center',
              }}>
              <Tooltip title={`${rating} out of 5`}>
                <span>
                  <Rating
                    name="size-small"
                    value={rating}
                    precision={0.1}
                    readOnly
                    size={"small"}
                    style={{ paddingTop: "0.5rem", left: 5 }}
                  />
                </span>
              </Tooltip>{" "}
              <span className="technovanto-course-card-review">
                {"\u00a0\u00a0"}({noOfReviews} reviews)
              </span>
              </div>
            {isLogin? (
              <button type="button" className="btn-topcourse arrange-loader" 
              // onClick={()=>addToCart(course._id)}
              style={

                loader
                  ? { backgroundColor: "var(--color-disable)" }
                  : { backgroundColor: "var(--color-secondary)" }
              }
              disabled={loader ? true : false}>
                {loader ? <img src={ButtonLoader} width="80" /> : "Add To Cart"}
              </button>
            ):(
              <button type="button" className="btn-topcourse arrange-loader" 
            onClick={handleClickOpen}
            style={
              loader
                ? { backgroundColor: "var(--color-disable)" }
                : { backgroundColor: "var(--color-secondary)" }
            }
            disabled={loader ? true : false}>
              {loader ? <img src={ButtonLoader} width="80" /> : "Add To Cart"}
            </button>
            )}
            
          </>:
            <Typography 
              variant='span' 
              component="span"
              sx={{
                fontSize: '14px',
                paddingLeft: '10px'
              }}
            >
              Event Date : {eventDate}
            </Typography>
          }
        </div>
      </Card>
      <Dialog className="pop-dialog"
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >  
        <div style={{display:'flex',justifyContent:'right',width:'100%'}}>
        <Button sx={{width:"50px",height:"50px"}} onClick={handleClose}>
          <CancelOutlinedIcon sx={{opacity:"70%"}}/>
        </Button>
        </div>
        <center><img src={logoTransperent} height="100%" width="250px" alt="Logo" /></center>
        
          <DialogContent sx={{width: "550px",height:"340px",}} >
            <DialogContentText className="curriculum-popup" >
              <center><p><h2 style={{color:"var(--color-secondary)",fontWeight:"bolder"}}>Oops !!</h2></p></center>
              <p 
              style={
                {fontWeight: "bolder",
                fontSize: "25px",
                textAlign:"center",
                marginTop:"3%",
                color:"black",
              }
              }>
                  Looks like you need to be logged in to perform this action.
              </p>
              <center><p>You can login by clicking below button</p></center>
                </DialogContentText>
                <Link to={`/auth-user`} style={{ textDecoration: "none" }} >
                  <center><button type="button" className="btn-grad" style={{width:'100px', height:'50px'}}>
                    LOGIN
                  </button></center>
                </Link>
          </DialogContent>
         
        </Dialog>
        </div>
    // </Link>
    
  );
}
