import routes from "../routes/Services.routes";
import axios from "axios";

export default async function getUndoneExercisesApi(){
    return await axios.get(`${routes.Exercise}/categories`,{
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then(res => {
        return res.data;
    }).catch(error => {
      if(error?.response){
        return error.response.data.message;
      }
      console.log(error);
    })
}
