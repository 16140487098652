
import IitHyderabad from "../../assets/images/topClient2/iit_hyderabad.png";
import IitBhumneswar from "../../assets/images/topClient2/iit_bhubnes.png";
import IitKanpur from "../../assets/images/topClient2/iit_kanpur.png";
import OsmaniaUniv from "../../assets/images/topClient2/osmania_univ.png";
import Lpu from "../../assets/images/topClient2/lpu_punjab.png";
import IitJalandhar from "../../assets/images/topClient2/nit_jalandhar.png";
import PunjabUniv from "../../assets/images/topClient2/punjab_univ.png";
import IimNoida from "../../assets/images/topClient2/iimt_noida.png";
import IitJabalpur from "../../assets/images/topClient2/iit_jabalpur.png";

const topClientData = [
    {
        image: IitHyderabad,
        name: "IIT Hyderabad",
    },
    {
        image: IitBhumneswar,
        name: "IIT Bhubaneswar",
    },
    {
        image: IitKanpur,
        name: "IIT Kanpur",
    },
    {
        image: OsmaniaUniv,
        name: "Osmania University",
    },
    {
        image: Lpu,
        name: "LPU",
    },
    {
        image: IitJalandhar,
        name: "IIT Jalandhar",
    },
    {
        image: PunjabUniv,
        name: "Punjab University",
    },
    {
        image: IimNoida,
        name: "IIM Noida",
    },
    {
        image: IitJabalpur,
        name: "IIT Jabalpur",
    }
]
export default topClientData;