import React, {useState, useEffect} from 'react';
import { useParams, useLocation } from "react-router-dom";
import ExcerciseHeader from './ExcerciseHeader';
import getDoneExercisesApi from '../../apis/api/GetDoneExcercises';
import getQuestionByCatApi from '../../apis/api/GetQuestion';
import addAnswerApi from '../../apis/api/AddAnswer';
import getUndoneExercisesApi from '../../apis/api/GetUndoneExercises';
import ExcerciseHeaderImage from '../../assets/images/excercise_header_pic.png';
import CrossIcon from '../../assets/images/black_cross_icn.png';
import MenuIcon from '../../assets/images/menu_icon.png';
import RightArrow from '../../assets/images/right-arrow.png';
import ButtonLoader from '../../assets/images/button_loader.gif';
import LoginPopup from "../Generic/LoginPopup/LoginPopup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../redux/slices/auth.slices";
import Loader from "../Loader";
import './excercise.css';
let answerArray = [];
function ExcerciseBody(){
    const [isSideBar, setIsSideBar] = useState(true);
    const [question, setQuestion] = useState([]);
    const [answer, setAnswer] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [isAnsSubmitted, setIsAnsSubmitted] = useState(false);
    const [nextQuestion, setNextQuestion] = useState(0);
    const [isExerciseCompleted, setIsExerciseCompleted] = useState(false);
    const [topicList, setTopList] = useState([]);
    const { category } = useParams();
    const [activeTab, setActiveTab] = useState(category);
    const [doneExercise, setDoneExercise] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [contentLoader, setContentLoader] = useState(false);
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [open, setOpen] = useState(false);
    let location = useLocation();
    let dispatch = useDispatch();
    
    useEffect(() => {
        var menu_btn = document.querySelector("#sidebarCollapse");
        menu_btn.addEventListener("click",() => {
            document.getElementById("sidebar").classList.toggle("active");
            setIsSideBar(!isSideBar)
        });
        getQuestionbyCat(category);
        getUndoneExercises();
        getDoneExercise();
    },[isSideBar]);

    const getQuestionbyCat = async(cat) => {
        setActiveTab(cat);
        setIsLoader(true);
        let res = await getQuestionByCatApi(cat);
        if(res?.statusCode === 200){
            setIsLoader(false);
            setQuestion(res?.data)
        }else{
            if(res === "Unauthorized" || res === "Session Expired"){
                setOpen(true);
                setIsLoader(false);
                errorToast('Session expired.')
                return;
            }
            setIsLoader(false);
            errorToast('Something went wrong.')
        }
    }
    const getDoneExercise = async() => {
        let res = await getDoneExercisesApi();
        if(res?.statusCode === 200){
            setDoneExercise(res?.data?.categories_completed);
        }
    }
    const getUndoneExercises = async() => {
        let res = await getUndoneExercisesApi();
        if(res?.statusCode === 200){
            console.log(res?.data)
            setTopList(res?.data)
        }
    }
    const toggleSideBar = () => {
        console.log('Toggle sidebar');
        var menu_btn = document.querySelector("#sidebar");
        var menu = document.querySelector("#menu");
        var cross = document.querySelector("#cross");
        console.log('Toggle sidebar',menu_btn.style.display)
        if(menu_btn.style.display === 'block'){
            menu_btn.style.display = 'none';
            menu.style.display = 'block';
            cross.style.display = 'none';
        }else{
            menu_btn.style.display = 'block';
            menu.style.display = 'none';
            cross.style.display = 'block';
        }
        // menu_btn.addEventListener("click",() => {
        //     document.getElementById("sidebar").classList.toggle("active");
        //     setIsSideBar(!isSideBar)
        // })
    }
    const selectMenuItem = (ind, value) => {
        setActiveTab(value);
        getQuestionbyCatByTab(value);
    }
    const getQuestionbyCatByTab = async(value) => {
        setActiveTab(value);
        setContentLoader(true);
        let res = await getQuestionByCatApi(value);
        if(res?.statusCode === 200){
            setContentLoader(false);
            setQuestion(res?.data)
        }else{
            if(res === "Unauthorized" || res === "Session Expired"){
                errorToast('Session expired.')
                setOpen(true);
                setContentLoader(false);
                dispatch(logoutAction());
                return;
            }
            setContentLoader(false);
            errorToast('Something went wrong.')
        }
    }
    const getInputValue = (text, index) => {
        //console.log("getInputValue", text, index)
        
        answerArray[index] = text;
        //console.log("getInputValue", answerArray);
        setAnswer(String(answerArray));
    }
    const postAnswer = async(id) => {
        if(answer === ''){
            errorToast('Select or fill the answer');
            return
        }
        let body = {
            question_id: id,
            answer: answer
        }
        setButtonLoader(true);
        let res = await addAnswerApi(body);
        console.log("res======>",res);
        if(res?.statusCode === 200){
            setButtonLoader(false);
            setIsAnsSubmitted(true);
            setIsCorrect(res?.data?.isCorrect);
            setCorrectAnswer(res?.data?.correct_answer);
            setAnswer('');
            answerArray = [];
            if((question?.length-1 === nextQuestion) && res?.data?.isCorrect){
                setIsExerciseCompleted(true);
            }
            // toast.success('Answer submitted successfully', {
            //     position: "bottom-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });

        }else{
            setButtonLoader(false);
            errorToast('Something went wrong.');
        }
    }
    const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const goToNextQuest = () => {
        if(question?.length !== nextQuestion){
            setNextQuestion(nextQuestion+1);
            setIsCorrect(false);
            setIsAnsSubmitted(false);
        }
    }
    const getCheckBoxVal = (event) => {
        if(event.target.checked){
            answer === '' ? setAnswer(answer+event.target.value) : setAnswer(`${answer},${event.target.value}`)
        }else{
            let index = 0;
            let array = answer.split(',');
            index = array.indexOf(event.target.value)

            let newArray = array.filter((item, ind) => {
                return ind !== index
            })
            setAnswer(String(newArray))
        }

    }
    const goToNextTopic = (cat) => {
        setIsAnsSubmitted(false);
        setIsCorrect(false);
        setIsExerciseCompleted(false);
        setNextQuestion(0);
        getQuestionbyCatByTab(cat);
        getDoneExercise();
    }
    return(
        <>
            {isLoader ? <Loader /> :
            <>
                <ExcerciseHeader 
                    heading={'Excercises'}
                    description={'Practice your way to the top.'}
                    image={ExcerciseHeaderImage} />
                <div className="sidebar-box">
                    <div className="wrapper">
                        <nav id="sidebar" style={{display: 'block'}}>
                            <div className="sidebar-header">
                                <div className="menu-icon-btn">
                                    {/* <img src={CrossIcon} width="24" height="24" /> */}
                                </div>
                            </div>
                            
                            <ul className="list-unstyled components">
                                {topicList?.map((item, index) => (
                                <li className={'menu-item-box '+(activeTab === item && 'active-li')} key={index} id={index}>
                                    {doneExercise[index] ?
                                    <div 
                                        className={'menu-item-text '+(activeTab === item && 'active-li-text')} id={`${index}_text`} 
                                        onClick={() => {selectMenuItem(index, item)}}
                                        style={doneExercise[index] === item && {color: 'var(--color-green-icon)'}}
                                        >
                                        {item}
                                    </div> : 
                                    <div 
                                        className={'menu-item-text '+(activeTab === item && 'active-li-text')} id={`${index}_text`} 
                                        onClick={() => {selectMenuItem(index, item)}}
                                        >
                                        {item}
                                    </div>
                                    }
                                </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div id="content" className="content-container">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light close-nav">
                            <div className="container-fluid">
                                <button 
                                    type="button" 
                                    id="sidebarCollapse" 
                                    className="btn btn-info btn-menu" 
                                    onClick={() => {toggleSideBar()}}
                                    >
                                    <i className="fas fa-align-left"></i>
                                    <img src={MenuIcon} id="menu" style={{display: 'none'}} />
                                    <img src={CrossIcon} id="cross" width="40" height="40" display="block" />
                                </button>
                            </div>
                        </nav>
                        <div style={{
                            position: 'relative',
                            height: '308px'
                            }}>
                            {contentLoader ?
                            <div style={{
                                textAlign: 'center',
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: '30%'
                                }}>
                                <img src={ButtonLoader} width="100"/>
                            </div>:
                            <QuestionBox 
                                data={question} 
                                topics={topicList}
                                loader={buttonLoader}
                                isCorrect={isCorrect}
                                correctAnswer={correctAnswer}
                                isExerciseCompleted={isExerciseCompleted}
                                dataPage={nextQuestion}
                                getCheckBoxValue={(event) => {getCheckBoxVal(event)}}
                                nextQuestion={() => {goToNextQuest()}}
                                retry={() => {
                                    setIsAnsSubmitted(false);
                                    if(((question?.length - 1) === nextQuestion) && isCorrect){
                                        setNextQuestion(0);
                                        setIsCorrect(false);
                                        setIsExerciseCompleted(false);
                                    }
                                }}
                                isAnsSubmitted={isAnsSubmitted}
                                inputValue={(text, index) => {getInputValue(text, index)}}
                                sendAnswer={(id) => {postAnswer(id)}}
                                nextTopic={(cat) => {goToNextTopic(cat)}}
                                activeTab={activeTab} />}
                        </div>
                    </div>
                </div>
                <LoginPopup openPopup={open} setClose={() => setOpen(false)} prevUrl={location.pathname} />
            </>
            }
        </>
    )
}
const QuestionBox = (props) => {
    //console.log("question data===",props.data)
    return <>
        <div className="question-aria">
            <h3 className="question-top-heading">Exercise of {props?.data[props?.dataPage]?.category}:</h3>
            {/* <p>Set the size of the image to 250 pixels wide and 400 pixels tall.</p> */}
            <div className="answer-aria">
                {(props.isCorrect && props.isAnsSubmitted && !props.isExerciseCompleted) && 
                <p className="correct-answer-text">Correct Answer!</p>}
                {(!props.isCorrect && props.isAnsSubmitted) && <p className="wrong-answer-text">
                    Wrong Answer!<br/>
                    <span className="correct-answer-text">Correct Answer - {props?.correctAnswer}</span>
                </p>}

                {(props.isCorrect && props.isExerciseCompleted) && 
                <div className="completion-text-box">
                    <p className="completion-text">Congratulations !</p>
                    <p className="completion-text">You have completed HTML exercise.</p>
                </div>}

                {!props.isAnsSubmitted &&
                <>
                {props?.data[props?.dataPage]?.question_type === 'fillUp' ? 
                    <>
                        {props.data[props?.dataPage]?.question.split('_').map((item, index) => (
                            <span key={index}>
                                <span>{item}</span>
                                {index !== props.data[props?.dataPage]?.question.split('_').length-1 &&
                                    <input 
                                        type="text" 
                                        id={index}
                                        className="input-answer" 
                                        onChange={(e) => {props.inputValue(e.target.value, index)}} />
                                }
                            </span>
                        ))}
                        
                    </> : 
                    <>
                        <span>{props.data[props?.dataPage]?.question}</span><br/>
                        {/* {console.log(props.data[props?.dataPage]?.options)} */}
                        {props.data[props?.dataPage]?.options.map((item, index) => (
                            <div key={index} className="mcq-option-box">
                                <div style={{display: 'flex'}}>
                                    <input type="checkbox" name={index} id={`${index}`} value={item} onChange={(e) => {
                                        props.getCheckBoxValue(e)
                                    }}/>
                                    <span style={{marginLeft: '8px', marginRight: '12px'}}>{item}</span>
                                </div>
                            </div>
                        ))}
                    </>
                    }
                </>
                }
            </div>
            {(!props.isCorrect && !props.isAnsSubmitted) &&
            <button 
                style={props.loader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-green-icon)'}}
                disabled={props.loader}
                className="btn-grad answer-submit-btn" 
                onClick={() => {props.sendAnswer(props?.data[props?.dataPage]?._id)}}>
                {props.loader ? <img src={ButtonLoader} width="80" alt="" /> : 'Submit Answer'}
            </button>}
            {(props.isCorrect && props.isAnsSubmitted && !props.isExerciseCompleted) &&
            <button 
                style={props.loader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-green-icon)'}}
                disabled={props.loader}
                className="btn-grad answer-submit-btn" 
                onClick={() => {props.nextQuestion()}}>
                {props.loader ? <img src={ButtonLoader} width="80" alt="" /> : 'Next Question'}
            </button>}
            <div className="completion-btn-box">
                {((props.isAnsSubmitted && !props.isCorrect) || (props.isCorrect && props.isExerciseCompleted)) && 
                    <button 
                        className="btn-grad try-again-btn"
                        onClick={() => {props.retry()}}
                    >Try it again</button>
                }
                {props.isExerciseCompleted && 
                    <button 
                        className="btn-grad answer-submit-btn" 
                        onClick={() => {
                            props.nextTopic(props?.topics[props?.topics?.indexOf(props?.data[props?.dataPage]?.category)+1])
                        }}
                        disabled={props?.activeTab === props?.topics[props?.topics?.length-1]}
                        >
                            {props?.activeTab === props?.topics[props?.topics?.length-1] ?
                            'Completed'
                            :
                            <>
                                Next &nbsp; {props?.topics[props?.topics?.indexOf(props?.data[props?.dataPage]?.category)+1]}
                                <img src={RightArrow} width="20" height="16" className="btn-right-icon"/>
                            </>
                        }
                    </button>
                }
            </div>
        </div>
    </>
}
export default ExcerciseBody;