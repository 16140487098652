import * as React from "react";
import { Typography, Container } from "@mui/material";
import "./upcomingEvent.css";
import { Link } from "react-router-dom";
import eventsApi from "../../apis/api/GetEvents";
import myCourses from "../../assets/images/myCourses.png";
import TopCourseBg from "../../assets/images/top_course_bg.png";
import AliceCarousel from "react-alice-carousel";
import LessionIcon from "../../assets/images/lession.png";
import TotalEnrolledIcon from "../../assets/images/total-enrolled.png";
import getTopCourseApi from "../../apis/api/TopCourses";
import Rating from "@mui/material/Rating";
import {arrangeDate} from "../../utils/date";
//import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
//import { useIsMobile } from "../../utils/mobileScreen";
const responsive = {
    400: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};

const UpcomingEvents = () => {
  //const isMobile = useIsMobile();
  const [events, setEventData] = React.useState([]);
  const [loading, setLoading] = React.useState();
  const [error, setError] = React.useState("");
  
  React.useEffect(async () => {
    getUpcomingEvent()
  },[])

  const getUpcomingEvent = async() => {
    let upcomingEvent = await eventsApi(
        "upcoming",
        setEventData,
        setLoading,
        setError
    );
  }
  let items = events.map((data, index) => (
    <TopCoursesData data={data} key={index}></TopCoursesData>
  ));

  return (
    <section>
      <Container className="admin-aria-container top-courses-box">
        <div className="top-courses-aria upcoming-event-caro">
            <Typography component="h2" className="techvanto-whyus-heading heading-alignment">
                Upcoming Events
            </Typography>
            <div>
                <AliceCarousel
                    className="techvanto-student-says-carousel"
                    responsive={responsive}
                    autoPlayDirection={"rtl"}
                    autoPlayStrategy={"none"}
                    animationEasingFunction={"ease"}
                    autoPlay={false}
                    controlsStrategy="alternate"
                    infinite={true}
                    mouseTracking
                    items={items}
                    renderPrevButton={() => {
                        return <div className="left-arrow-studentSays">◄</div>;
                    }}
                    renderNextButton={() => {
                        return <div className="right-arrow-studentSays">►</div>;
                    }}
                />
            </div>
            {/* <div>
                <img src={AdminPanelPic} />
            </div>
            <div className="admin-text-box">
                <Typography component="h2" className="techvanto-whyus-heading admin-heading-2">
                    Lorem Ipsum
                </Typography>
                <Typography component="p" className="admin-aria-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sapien porttitor dolor, ultrices in ac at gravida morbi. 
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sapien porttitor dolor, ultrices in ac at gravida morbi.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sapien porttitor dolor, ultrices in ac at gravida morbi.
                </Typography>
            </div> */}
        </div>
      </Container>
    </section>
  );
};
const TopCoursesData = (props) => {
    return (
        <main className="main main2">
          <section className="container container2">
            <article className="review review2">
              <div className="img-container top-course-card-new" style={{width: '100%'}}>
                <div style={{
                    textAlign: 'center',
                    position: 'relative', 
                    padding: '24px 0',
                    background: `linear-gradient(${props.data?.gradient})`}}>
                    <img src={props.data?.thumbnail} alt="" className="t-course-img" width="180" height="180" />
                    <div style={{
                        position: 'absolute',
                        background: 'var(--color-secondary-red)',
                        color: 'var(--color-white)',
                        top: '12px',
                        borderRadius: '4px',
                        padding: '4px 8px'
                    }}>
                        {props.data?.type[0].toUpperCase()+props.data?.type.substring(1)}
                    </div>
                </div>
                <div className="t-course-detail">
                    <h4 className="t-course-cat" style={{textAlign: 'right'}}>
                        {arrangeDate(props.data?.startDate)}
                    </h4>
                    <h5 className="t-course-name">{props.data?.name}</h5>
                    <p className="t-course-desc">
                        {props.data?.headline}
                    </p>
                </div>
                <div className="t-course-bottom" style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginRight: '12px'
                }}>
                    <Link to={`/event/${props.data?._id}`}>
                        <button style={{
                            background: 'var(--color-secondary-red)',
                            color: 'var(--color-white)',
                            border: 'none',
                            borderTopRightRadius: '20px',
                            borderBottomRightRadius: '20px',
                            padding: '8px 12px'
                        }} className="register-event">
                            Register Now
                        </button>
                    </Link>
                    <div style={{
                        color: 'var(--color-secondary-red)'
                    }} className="event-class-type">
                        Online - Zoom Meeting
                    </div>
                </div>
              </div>
            </article>
          </section>
        </main>
      );
}
export default UpcomingEvents;
