import React from 'react';
import { useState } from 'react';
import { Typography } from "@mui/material";
import addDemoApi from "../../apis/api/AddDemo";
import DatePicker from "react-datepicker";
import CalenderIcon from '../../assets/images/calendar.png';
import TimeIcon from '../../assets/images/clock.png';
import "./kidsCoding.css";
import ButtonLoader from "../../assets/images/button_loader.gif";
import yellowishLine from "../../assets/images/yellowish-line.png";
import greenishUser from "../../assets/images/greenish-user.png"; 
import femaleChild from "../../assets/images/female-child.png";
import maleChild from "../../assets/images/male-child.png";
import manIcon from "../../assets/images/man_icon.png";
import searchWithQIcon from "../../assets/images/search_with_q_icon.png";
import laptopnIcon from "../../assets/images/laptopn_icon.png";
import bookIcon from "../../assets/images/book_icon.png";
import yelowishCheck from "../../assets/images/yelowish_check.png";
import uniqueLearnImage from "../../assets/images/unique_learn_image.png";
import halfCircle1 from "../../assets/images/half_circle_1.png";
import halfCircle2 from "../../assets/images/half_circle_2.png";
import halfCircle3 from "../../assets/images/half_circle_3.png";
import halfCircle4 from "../../assets/images/half_circle_4.png";
import halfCircle5 from "../../assets/images/half_circle_5.png";
import leftCornor from "../../assets/images/left_cornor.png";
import rightCornor from "../../assets/images/right_cornor.png";
import techingPhilosBackImg from "../../assets/images/teching_philos_back_img.png";
import expert_1 from "../../assets/images/expert_1.png";
import expert_2 from "../../assets/images/expert_2.png";
import expert_3 from "../../assets/images/expert_3.png";
import bettrPhoto from "../../assets/images/bettr_photo.png";
import { toast } from "react-toastify";
const unigueLearn = [
    {
        icon: yelowishCheck,
        text: "We diagnose and identify the student’s current needs."
    },
    {
        icon: yelowishCheck,
        text: "We recommend topics that are right for the students."
    },
    {
        icon: yelowishCheck,
        text: "Student’s choose and work through the topics at their own space."
    },
    {
        icon: yelowishCheck,
        text: "We supplement the in-class learning with targeted at-home practice."
    }
]
const circleData = [
    {
        id: 1,
        topImage: halfCircle1,
        heading: 'Opening Group Activity',
        desc: '2-5 mins of brain warm-up exercise'
    },
    {
        id: 2,
        topImage: halfCircle2,
        heading: 'Adaptive Individual Activity',
        desc: '15-20 mins of personalised interactive exercises'
    },
    {
        id: 3,
        topImage: halfCircle3,
        heading: 'Mid-class Group Activity',
        desc: '2-5 mins of brain games'
    },
    {
        id: 4,
        topImage: halfCircle4,
        heading: 'Continuing Individual Activity',
        desc: '15-20 mins of personalised interactive exercises'
    },
    {
        id: 5,
        topImage: halfCircle5,
        heading: 'Closing Group Activity',
        desc: '2-5 mins of summary and closing activity'
    }
]
const expertData = [
    {
        name: 'Vishal Singhal',
        image: expert_1,
        backColor: 'var(--color-secondary-red)',
        desc: 'M.Tech in Electronics from Punjab University, Chandigarh'
    },
    {
        name: 'Renuka Sachdeva',
        image: expert_2,
        backColor: 'var(--color-yellw)',
        desc: 'M.Tech in Electronics from Punjab University, Chandigarh'
    },
    {
        name: 'Payal Singh',
        image: expert_3,
        backColor: 'var(--color-lit)',
        desc: 'M.Tech in Electronics from Punjab University, Chandigarh'
    }
]
const planCard = [
    {
        name: 'Basic Plan',
        duration: '6 months',
        hours: '48 Live classes',
        noOfClass: '2 Classes per week',
        price: '10000',
        discount: '40',
        backColor: 'rgb(0, 93, 146, 0.5)',
        fontColr: 'var(--color-primary)'
    },
    {
        name: 'Intermediate Plan',
        duration: '6 months',
        hours: '48 Live classes',
        noOfClass: '2 Classes per week',
        price: '10000',
        discount: '40',
        backColor: 'rgb(195, 34, 72, 0.5)',
        fontColr: 'var(--color-secondary-red)'
    },
    {
        name: 'Advanced Plan',
        duration: '6 months',
        hours: '48 Live classes',
        noOfClass: '2 Classes per week',
        price: '10000',
        discount: '40',
        backColor: 'rgb(33, 142, 51, 0.5)',
        fontColr: 'var(--color-green-icon)'
    }
]
let date = '';
let time = '';

const KidsCoding = () => {
    const [buttonLoader, setButtonLoader] = useState(false);
    const [resumeError, setResumeError] = useState(true);
    const [loader, setLoader] = useState(true);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [dateValue, onChange] = useState(new Date());
    const [timeValue, timeCahnge] = useState(new Date());
    const [dateError, setDateError] = useState(true);
    const [timeError, setTimeError] = useState(true);

    const [info, setInfo] = useState({
        name: "",
        phone: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).phone : "",
        email: "",
        date: "",
        source: "kids_coding"
    });
    let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const submitForm= async(e) =>{
        e.preventDefault();
        setLoader(true);
        if(!nameError && !phoneError && 
           !emailError && !dateError && 
           !timeError)
        {
            let res;
            setButtonLoader(true)
            res = await addDemoApi(info);
            console.log("res==========",res)
            if(res){
                // console.log("res==========",res)
                setButtonLoader(false)
                if(res.statusCode === 200){
                    console.log("success");
                    let fileInfo = {
                        name: "",
                        email: "",
                        phone: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).phone : "",
                        date:"",
                    }
                    setNameError(true);
                    setEmailError(true);
                    JSON.parse(localStorage.getItem('user')) ? setPhoneError(false) : setPhoneError(true);
                    setResumeError(true);
                    setDateError(true);
                    setTimeError(true);
                    setInfo(fileInfo);
                    toast.success(res.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }else{
                    toast.error('Something went wrong.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }
            }else{
                setButtonLoader(false);
                toast.error('Something went wrong.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }else{
            if(nameError) return errorToast('Name required.');
            if(phoneError) return errorToast('Invalid phone number entered.');
            if(emailError) return errorToast('Invalid email entered.');
            if(dateError) return errorToast('Date Required.');
            if(timeError) return errorToast('Time Required.');
        }
    }
    const formValidate = (event) => {
        if (event.name === "name") {
          setInfo({ ...info, name: event.value });
          if(event.value === "") return setNameError(true);
          setNameError(false);
        }
        if (event.name === "email") {
          setInfo({ ...info, email: event.value });
          if(event.value === "" || !event.value.match(emailValidate)) return setEmailError(true);
          setEmailError(false);
        }
        if (event.name === "phone") {
          setInfo({ ...info, phone: event.value });
          if(event.value !== "" && event.value.length === 10) return setPhoneError(false);
          setPhoneError(true);
        }
    }
    const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const handleDateSelect = (value, from) => {
        if(from === 'date'){
            date = value;
            onChange(value);
            setDateError(false);
        }
        if(from === 'time'){
            time = value;
            timeCahnge(value);
            setTimeError(false);
        }
        if(date !== '' && time !== ''){
            let y = new Date(dateValue).getFullYear();
            let m = new Date(dateValue).getMonth()+1;
            let d = new Date(dateValue).getDate();
            let hh = new Date(time).getHours();
            let min = new Date(time).getMinutes();
            let newDate = new Date(y + ',' + m + ',' + d + ' ' + hh + ':' + min).toISOString();
            setInfo({...info, date: `${newDate}`});
        }
    }
    return (
        <>
            <section className="summer-intern top-section" style={{position: "relative"}}>
                <Typography component="h2" 
                    className="techvanto-whyus-heading kids-code-heading"
                    style={{
                        color: "var(--color-dark-blue)",
                    }}>
                    Personalised Coding Program
                </Typography>
                <Typography component="h2" 
                    className="techvanto-whyus-heading kids-code-heading-2"
                    style={{
                        color: "var(--color-pinkish)",
                    }}>
                    for grade 6-12
                </Typography>
                <p style={{
                    color: "var(--color-secondary-text)",
                    textAlign: "center"
                }}>
                    Let the child be the director, and the actor in his own pis play
                </p>
                <div style={{
                    textAlign: "center"
                }}>
                    <button 
                        className='btn-grad btn-nav'
                        data-bs-toggle="modal" 
                        data-bs-target="#myModal"
                        style={{
                            backgroundColor: 'var(--color-yellowish)',
                            fontWeight: 'bold'
                        }}
                        >
                        Book a free demo now
                    </button>
                </div>
                <div className="top-point">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '36px'
                    }}>
                        <div style={{
                            marginRight: '20px'
                        }}>
                            <img src={yellowishLine} width="36" />
                        </div>
                        <div style={{
                            color: 'var(--color-darkish-blue)',
                            width: '336px',
                            fontWeight: 'bold',
                        }}>
                            We just don’t give our students only lecture but real life experimented filled experience throughout the 
                            journey!
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        marginTop: '24px'
                    }}>
                        <div style={{
                            marginRight: '16px',
                            boxShadow: '0.5px 0px 2px var(--color-border)',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                            paddingTop: '6px',
                            paddingBottom: '6px'
                        }}>
                            <img src={greenishUser} width="24" />
                        </div>
                        <div style={{
                            width: '340px'
                        }}>
                            <div style={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                            }}>100+</div>
                            <div style={{
                                width: '196px',
                                color: 'var(--color-secondary-text)'
                            }}>
                                Total active students taking part in this program
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <div className='child-box' style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <div className='male-child' style={{
                            backgroundColor: 'var(--color-yellowish)',
                            position: 'relative',
                            borderRadius: '50%',
                        }}>
                            <div className='male-child-pic' style={{
                                    position: 'absolute',
                                    top: '-60px'
                                }}>
                                <img src={maleChild} />
                            </div>
                        </div>
                        <div className="female-child">
                            <div style={{
                                backgroundColor: 'var(--color-primary)',
                                position: 'relative',
                                borderRadius: '50%',
                                height: '230px',
                                width: '230px'
                            }}>
                                <div style={{
                                        position: 'absolute',
                                        top: '-43px',
                                        width: '278px',
                                        right: '-40px'
                                    }}>
                                    <img src={femaleChild} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
             <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <Typography component="h2" 
                    className="techvanto-whyus-heading kids-code-heading"
                    style={{
                        color: "var(--color-dark-blue)",
                    }}>
                    Lorem ipsum dolor sit amet
                </Typography>
                <div className="kids-card-box" style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '40px'
                }}>
                    <div className='kids-card'>
                        <img src={bookIcon} />
                        <Typography component="h4" className='kids-card-heading'>
                            Adaptive Learning
                        </Typography>
                        <p className='kids-card-desc'>
                            Children can choose a topic and work at their own pace
                        </p>
                    </div>
                    <div className='kids-card'>
                        <img src={laptopnIcon} />
                        <Typography component="h4" className='kids-card-heading'>
                            Interactive Classes
                        </Typography>
                        <p className='kids-card-desc'>
                            A combination of group and individualised exercises
                        </p>
                    </div>
                    <div className='kids-card'>
                        <img src={searchWithQIcon} />
                        <Typography component="h4" className='kids-card-heading'>
                            Research - backed
                        </Typography>
                        <p className='kids-card-desc'>
                            A focus on conceptual understanding and reasoning
                        </p>
                    </div>
                    <div className='kids-card'>
                        <img src={manIcon} />
                        <Typography component="h4" className='kids-card-heading'>
                            Expert Teachers
                        </Typography>
                        <p className='kids-card-desc' style={{
                            paddingLeft: '12px',
                            paddingRight: '12px'
                        }}>
                            A personal tutor with you, every step of the way
                        </p>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <div className="unique_learn_box">
                <div>
                    <Typography component="h2" 
                        className="techvanto-whyus-heading unique-learn-heading"
                        style={{
                            color: "var(--color-dark-blue)",
                            textAlign: 'left',
                            marginLeft: 0
                        }}>
                        We create a unique adaptive learning path for you
                    </Typography>
                    <div>
                        <div>
                            {unigueLearn.map((item, index) => (
                                <div key={index} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '12px'
                                }}>
                                    <img src={item.icon} width="24" />
                                    <p style={{
                                        color: 'var(--color-black)',
                                        fontWeight: 'bold',
                                        marginBottom: 0,
                                        marginLeft: '8px'
                                    }}>{item.text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <img src={uniqueLearnImage} width="312" />
                </div>
            </div>
            <br />
            <br />
            <div>
                <div style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                }} className="campus-ambas-top-text">
                    <Typography component="h2" 
                        className="techvanto-whyus-heading unique-learn-heading"
                        style={{
                            color: "var(--color-dark-blue)",
                        }}>
                        How is our class is different?
                    </Typography>
                    <p style={{
                        margin: 0,
                        color: 'var(--color-secondary-text)'
                    }}>
                        Every class is teacher led and designed to ensure mastry and Coding wizardry
                    </p>  
                    <br/>
                    <br/>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <div style={{
                        }} className="circular-aria">
                            {circleData.map((item, index) => (
                                (window.innerWidth >= 1024) ?
                                (index < 3 && 
                                <div key={index} style={{
                                    position: 'relative',
                                }} className="circular-box">
                                    <div className="single-circular-box">
                                        <div style={{
                                            position: 'relative',
                                        }}>
                                            <img src={item.topImage}/>
                                            <div style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: -17,
                                                zIndex: -1
                                            }}>
                                                <img src={leftCornor} width="10" />
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                right: 0,
                                                bottom: -16,
                                                zIndex: -1
                                            }}>
                                                <img src={rightCornor} width="12" />
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: -16,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}>
                                                <div style={{
                                                    backgroundColor: 'var(--color-white)',
                                                    padding: '3px',
                                                    borderRadius: '50%'
                                                }}>
                                                    <div style={{
                                                        color: 'var(--color-white)',
                                                        fontWeight: 'bold',
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'var(--color-black)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        {item.id}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                textAlign: 'center',
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                width: '100%',
                                                bottom: 0,
                                                color: 'var(--color-white)',
                                                lineHeight: '16px',
                                                marginBottom: '2px'
                                            }}>
                                                {index === 0 && <div>Opening <br/>Group Activity</div>}
                                                {index === 1 && <div>Adaptive <br/> Individual Activity</div>}
                                                {index === 2 && <div>Mid-class <br/> Group Activity</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        width: '180px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                        <div style={{
                                            boxShadow: '0px 2px 4px var(--color-secondary-text)',
                                            borderRadius: '50%',
                                            padding: '6px',
                                            width: 'fit-content',
                                        }}>
                                            <div style={{
                                                height: '152px',
                                                width: '152px',
                                                borderRadius: '50%',
                                                border: '1px dashed black',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-end'
                                            }}>
                                                <div style={{
                                                textAlign: 'center',
                                                fontSize: 13,
                                                fontWeight: 'bold',
                                                color: 'var(--color-black)',
                                                lineHeight: '18px',
                                                marginBottom: index === 1 ? '8%' : 
                                                (index === 2 ? '20%' : '12%')
                                            }}>
                                                {index === 0 && 
                                                    <div>
                                                        2-5 mins of <br/> brain warm-up <br/> exercise
                                                    </div>
                                                }
                                                {index === 1 && 
                                                    <div>
                                                        15-20 mins of <br/> personalised <br/> interactive <br/> exercises
                                                    </div>
                                                }
                                                {index === 2 && 
                                                    <div>
                                                        2-5 mins of <br/> brain games
                                                    </div>
                                                }
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>):
                                <div key={index} style={{
                                    position: 'relative',
                                }} className="circular-box">
                                    <div className="single-circular-box">
                                        <div style={{
                                            position: 'relative',
                                        }}>
                                            <img src={item.topImage}/>
                                            <div style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: -17,
                                                zIndex: -1
                                            }}>
                                                <img src={leftCornor} width="10" />
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                right: 0,
                                                bottom: -16,
                                                zIndex: -1
                                            }}>
                                                <img src={rightCornor} width="12" />
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: -16,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}>
                                                <div style={{
                                                    backgroundColor: 'var(--color-white)',
                                                    padding: '3px',
                                                    borderRadius: '50%'
                                                }}>
                                                    <div style={{
                                                        color: 'var(--color-white)',
                                                        fontWeight: 'bold',
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'var(--color-black)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        {item.id}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                textAlign: 'center',
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                width: '100%',
                                                bottom: 0,
                                                color: 'var(--color-white)',
                                                lineHeight: '16px',
                                                marginBottom: '2px'
                                            }}>
                                                {index === 0 && <div>Opening <br/>Group Activity</div>}
                                                {index === 1 && <div>Adaptive <br/> Individual Activity</div>}
                                                {index === 2 && <div>Mid-class <br/> Group Activity</div>}
                                                {index === 3 && <div>Continuing <br/> Individual Activity</div>}
                                                {index === 4 && <div>Closing <br/> group activity</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        width: '180px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                        <div style={{
                                            boxShadow: '0px 2px 4px var(--color-secondary-text)',
                                            borderRadius: '50%',
                                            padding: '6px',
                                            width: 'fit-content',
                                        }}>
                                            <div style={{
                                                height: '152px',
                                                width: '152px',
                                                borderRadius: '50%',
                                                border: '1px dashed black',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-end'
                                            }}>
                                                <div style={{
                                                textAlign: 'center',
                                                fontSize: 13,
                                                fontWeight: 'bold',
                                                color: 'var(--color-black)',
                                                lineHeight: '18px',
                                                marginBottom: (index === 1 || index === 3) ? '8%' : 
                                                (index === 2 ? '20%' : '14%')
                                            }}>
                                                {index === 0 && 
                                                    <div>
                                                        2-5 mins of <br/> brain warm-up <br/> exercise
                                                    </div>
                                                }
                                                {index === 1 && 
                                                    <div>
                                                        15-20 mins of <br/> personalised <br/> interactive <br/> exercises
                                                    </div>
                                                }
                                                {index === 2 && 
                                                    <div>
                                                        2-5 mins of <br/> brain games
                                                    </div>
                                                }
                                                {index === 3 && <div>
                                                    15-20 mins of <br/> personalised <br/> interactive <br/> exercises
                                                </div>}
                                                {index === 4 && <div>
                                                    2-5 mins of <br/> summary and <br /> closing activity
                                                </div>}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {window.innerWidth >= 1024 &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            width: '70%'
                        }}>
                            {circleData.map((item, index) => (
                                index >= 3 &&
                                <div key={index} style={{
                                    position: 'relative',
                                }}>
                                    <div style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '180px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}>
                                        <div style={{
                                            position: 'relative',
                                        }}>
                                            <img src={item.topImage}/>
                                            <div style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: -17,
                                                zIndex: -1
                                            }}>
                                                <img src={leftCornor} width="10" />
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                right: 0,
                                                bottom: -16,
                                                zIndex: -1
                                            }}>
                                                <img src={rightCornor} width="12" />
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: -16,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}>
                                                <div style={{
                                                    backgroundColor: 'var(--color-white)',
                                                    padding: '3px',
                                                    borderRadius: '50%'
                                                }}>
                                                    <div style={{
                                                        color: 'var(--color-white)',
                                                        fontWeight: 'bold',
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'var(--color-black)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        {item.id}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                textAlign: 'center',
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                width: '100%',
                                                bottom: 0,
                                                color: 'var(--color-white)',
                                                lineHeight: '16px',
                                                marginBottom: '2px'
                                            }}>
                                                {index === 3 && <div>Continuing <br/> Individual Activity</div>}
                                                {index === 4 && <div>Closing <br/> group activity</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        width: '180px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                        <div style={{
                                            boxShadow: '0px 2px 4px var(--color-secondary-text)',
                                            borderRadius: '50%',
                                            padding: '6px',
                                            width: 'fit-content',
                                        }}>
                                            <div style={{
                                                height: '152px',
                                                width: '152px',
                                                borderRadius: '50%',
                                                border: '1px dashed black',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-end'
                                            }}>
                                                <div style={{
                                                textAlign: 'center',
                                                fontSize: 13,
                                                fontWeight: 'bold',
                                                color: 'var(--color-black)',
                                                lineHeight: '18px',
                                                marginBottom: index === 3 ? '8%' : '16%'
                                            }}>
                                                {index === 3 && <div>
                                                    15-20 mins of <br/> personalised <br/> interactive <br/> exercises
                                                </div>}
                                                {index === 4 && <div>
                                                    2-5 mins of <br/> summary and <br /> closing activity
                                                </div>}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>}
                    </div>
                </div>
            </div>
            <br />
            <section style={{
                backgroundColor: 'rgb(255, 129, 88, 0.2)',
                paddingTop: '12px',
                marginTop: '16px'
            }} className="philos-box">
                <Typography component="h2" 
                    className="techvanto-whyus-heading kids-code-heading"
                    style={{
                        color: "var(--color-dark-blue)",
                    }}>
                    Our teaching philosophy
                </Typography>
                <p style={{
                    margin: 0,
                    color: 'var(--color-secondary-text)',
                    textAlign: 'center'
                }}>
                    Guided by the current educational research on achieving Coding mastery
                </p>
                <br/>
                <br/>
                <br/>
                <div style={{
                    position: 'relative'
                }}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <div style={{
                            backgroundColor: 'var(--color-yellowish)',
                            height: '244px',
                            width: '244px',
                            borderRadius: '50%',
                            position: 'relative'
                        }} className="philos-box">
                            <div style={{
                                position: 'absolute',
                                top: '-17px',
                                width: '280px',
                                left: '-16px',
                            }}>
                                <img src={techingPhilosBackImg} />
                            </div>
                            <div style={{
                                position: 'absolute',
                                padding: '12px 8px',
                                boxShadow: '0px 1px 2px var(--color-secondary-text)',
                                background: 'var(--color-white)',
                                width: '316px',
                                borderRadius: '8px',
                                fontWeight: 'bold'
                            }} className="philos-card-1">
                                Children learn by doing coding. No boring lectures
                            </div>
                            <div style={{
                                position: 'absolute',
                                padding: '12px 8px',
                                boxShadow: '0px 1px 2px var(--color-secondary-text)',
                                background: 'var(--color-white)',
                                width: '316px',
                                borderRadius: '8px',
                                fontWeight: 'bold'
                            }} className="philos-card-2">
                                Children build a conceptual understanding. Not just repeating steps.
                            </div>
                            <div style={{
                                position: 'absolute',
                                padding: '12px 8px',
                                boxShadow: '0px 1px 2px var(--color-secondary-text)',
                                background: 'var(--color-white)',
                                width: '316px',
                                borderRadius: '8px',
                                fontWeight: 'bold'
                            }} className="philos-card-3">
                                Children analyse and reason with Coding. No need to memorise.
                            </div>
                            <div style={{
                                position: 'absolute',
                                padding: '16px 8px',
                                boxShadow: '0px 1px 2px var(--color-secondary-text)',
                                background: 'var(--color-white)',
                                width: '316px',
                                borderRadius: '8px',
                                fontWeight: 'bold'
                            }} className="philos-card-4">
                                Children learn at a pace that's right for them. Not a one-size-fits-all schedule.
                            </div>
                        </div>
                        
                    </div>
                    <div style={{
                        textAlign: "center",
                        position: 'absolute',
                        width: '100%'
                    }} className="book-kids-demo-btn">
                        <button 
                            className='btn-grad btn-nav'
                            data-bs-toggle="modal" 
                            data-bs-target="#myModal"
                            style={{
                                backgroundColor: 'var(--color-yellowish)',
                                fontWeight: 'bold'
                            }}
                            >
                            Book a free demo now
                        </button>
                    </div>
                </div>
            </section>
            <br />
            <br />
            <br />
            <br />
            <section className="expert-section">
                {expertData.map((item, index) => (
                <div key={index} className="ind-expert-card">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            position: 'relative',
                            backgroundColor: item.backColor,
                            height: '144px',
                            width: '144px',
                            borderRadius: '50%',
                        }}>
                            <div style={{
                                position: 'absolute',
                                top: '-17px',
                                left: index === 2 ? -3 : 23,
                                width: index === 1 ? 96 : (index === 2 ? 150 : 100)
                            }}>
                                <img src={item.image}/>
                            </div>
                        </div>
                        <h5 style={{
                            color: 'var(--color-black)',
                            fontWeight: 'bold',
                            margin: '8px 0'
                        }}>{item.name}</h5>
                        <p style={{
                            color: '--color-black',
                            width: '200px',
                            textAlign: 'center'

                        }}>{item.desc}</p>
                    </div>
                </div>))}
            </section>
            <br />
            <br />
            <br />
            <section className="plan-card-box">
                {planCard.map((item, index) => (
                    <div key={index} style={{
                        width: 260,
                        borderTopRightRadius: '50px',
                        borderBottomLeftRadius: '50px',
                        backgroundImage: `linear-gradient(to bottom right,  ${item.backColor}, rgb(255, 255, 255, 0.1)`,
                        padding: '24px 24px 28px 24px',
                    }} className="single-plan-card">
                        <h6 style={{
                            fontWeight: 'bold',
                            marginTop: '8px'
                        }}>{item.name}</h6>
                        <p style={{
                            color: item.fontColr,
                            fontSize: '12px',
                            fontWeight: 'bold',
                            marginBottom: '0px'
                        }}>{item.duration}</p>
                        <hr style={{
                            margin: '4px 0 12px',
                            color: item.fontColr,
                        }}/>
                        <p style={{
                            color: item.fontColr,
                            opacity: 0.5,
                            marginBottom: '0'
                        }}>LIVE CLASSES</p>
                        <p style={{
                            color: item.fontColr,
                            fontSize: '12px',
                            fontWeight: 'bold',
                            marginBottom: '0px'
                        }}>{item.hours}</p>
                        <p style={{
                            color: item.fontColr,
                            opacity: 0.5,
                            marginBottom: '0',
                            marginTop: '24px'
                        }}>LIVE FREQUENCY</p>
                        <p style={{
                            color: item.fontColr,
                            fontSize: '12px',
                            fontWeight: 'bold',
                            marginBottom: '0px'
                        }}>{item.noOfClass}</p>
                        <div style={{
                            display: 'flex',
                            marginTop: '24px',
                            marginBottom: '24px',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                        }}>
                            <h5 style={{
                                fontWeight: 'bold',
                                fontSize: '18px',
                                marginBottom: 0
                            }}>Rs {item.price - item.price*item.discount/100}</h5>
                            <div style={{
                                display: 'flex',
                            }}>
                                <p style={{
                                    marginBottom: '0px',
                                    color: 'var(--color-green-icon)',
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                }}>Save {item.discount}%</p>
                                <del style={{
                                    marginBottom: '0px',
                                    color: 'var(--color-secondary-text)',
                                    fontSize: '10px',
                                    marginLeft: '8px'
                                }}>Rs {item.price}</del>
                            </div>
                        </div>
                        <button style={{
                            border: 'none',
                            boxShadow: '0px 1px 2px var(--color-secondary-text)',
                            width: '100%',
                            borderBottomLeftRadius: '16px',
                            padding: '8px 0',
                            fontWeight: 'bold'
                        }}>Choose plan</button>
                    </div>
                ))}
            </section>
            <br />
            <br />
            <br />
            <section style={{
                display: 'flex',
            }} className="better-future-box">
                <div className="better-parts" style={{
                    backgroundColor: 'var(--color-yello)',
                    padding: '24px'
                }}>
                    <Typography component="h2" 
                    className="techvanto-whyus-heading better-future"
                    style={{
                        color: "var(--color-black)",
                        textAlign: 'left'
                        
                    }}>
                        Better Future For Your<br/> Kids
                    </Typography>
                    <p style={{
                        marginBottom: '24px'
                    }}>
                        Like what you see ? Take the next step<br/> to know more.
                    </p>
                    <button 
                        className='btn-grad btn-nav'
                        data-bs-toggle="modal" 
                        data-bs-target="#myModal"
                        style={{
                            backgroundColor: 'var(--color-green-icon)',
                            fontWeight: 'bold'
                        }}
                        >
                        Book a free demo now
                    </button>
                </div>
                <div style={{
                    backgroundColor: 'var(--color-yello1)',
                }} className="better-parts">
                    <img src={bettrPhoto} />
                </div>
            </section>
            <div className="modal" id="myModal" style={{
                zIndex: 10000
            }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">

                        <div className="modal-header" style={{
                            border: 'none'
                        }}>
                            <h4 className="modal-title modal-header-text-style">
                                Book Demo
                            </h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div>
                                    <form onSubmit={submitForm} className="or-form">
                                        <div>
                                            <div className="form-group">
                                                <label className="secondary-text-color">Name</label>
                                                <input 
                                                type="text" 
                                                name="name" 
                                                className="form-control or-input-box" 
                                                value={info.name}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={nameError}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div>
                                            <div className="form-group">
                                                <label className="secondary-text-color">Contact Number</label>
                                                <input 
                                                type="number"
                                                name="phone" 
                                                className="form-control or-input-box" 
                                                value={info.phone}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={phoneError}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div>
                                            <div className="form-group">
                                                <label className="secondary-text-color">Email id</label>
                                                <input 
                                                type="email" 
                                                name="email" 
                                                className="form-control or-input-box" 
                                                value={info.email}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={emailError} 
                                                //helperText={name ? "Enter Your Name." : ""}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div style={{width: '47%'}}>
                                                <div className="form-group">
                                                    <label className="secondary-text-color">Date</label>
                                                    <div className="datepicker-box kids-date-input">
                                                        <DatePicker
                                                            selected={dateValue}
                                                            //onSelect={() => {handleDateSelect()}}
                                                            onChange={(date) => handleDateSelect(date, 'date')}
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={new Date()}
                                                        />
                                                        <div className="calender-icon">
                                                            <img src={CalenderIcon} width="20" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{width: '47%'}}>
                                                <div className="form-group">
                                                    <label className="secondary-text-color">Time</label>
                                                    <div className="datepicker-box kids-date-input">
                                                        <DatePicker
                                                            selected={timeValue}
                                                            //onChange={handleDateChange}
                                                            //onTimeSelect={() => {handleDateSelect()}}
                                                            dateFormat="HH ':' mm"
                                                            //dateFormat="HH ':' mm aa"
                                                            //onChange={timeCahnge}
                                                            onChange={(date) => handleDateSelect(date, 'time')}
                                                            minDate={new Date()}
                                                            timeIntervals={30}
                                                            timeFormat="HH:mm"
                                                            timeCaption="time"
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                        />
                                                        <div className="calender-icon">
                                                            <img src={TimeIcon} width="20" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div>
                                            <center> <button type="submit"
                                            className="btn-grad-resume resume-upload-btn"
                                            style={buttonLoader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-primary)'}}
                                                disabled={buttonLoader}
                                            >
                                                {buttonLoader ? <img src={ButtonLoader} width="80" alt="" /> : 'Submit'}
                                            </button></center>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        </>
    )
}
export default KidsCoding;