import * as React from "react";
import { Typography, Container } from "@mui/material";
import "./partner.css";
import IitHyderabad from "../../assets/images/topClient2/iit_hyderabad.png";
import IitBhumneswar from "../../assets/images/topClient2/iit_bhubnes.png";
import IitKanpur from "../../assets/images/topClient2/iit_kanpur.png";
import OsmaniaUniv from "../../assets/images/topClient2/osmania_univ.png";
import Lpu from "../../assets/images/topClient2/lpu_punjab.png";
import IitJalandhar from "../../assets/images/topClient2/nit_jalandhar.png";
import PunjabUniv from "../../assets/images/topClient2/punjab_univ.png";
import IimNoida from "../../assets/images/topClient2/iimt_noida.png";
import IitJabalpur from "../../assets/images/topClient2/iit_jabalpur.png";


//import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
//import { useIsMobile } from "../../utils/mobileScreen";
const topClientData = [
    {
        image: IitHyderabad,
        name: "IIT Hyderabad",
    },
    {
        image: IitBhumneswar,
        name: "IIT Bhubaneswar",
    },
    {
        image: IitKanpur,
        name: "IIT Kanpur",
    },
    {
        image: OsmaniaUniv,
        name: "Osmania University",
    },
    {
        image: Lpu,
        name: "LPU",
    },
    {
        image: IitJalandhar,
        name: "IIT Jalandhar",
    },
    {
        image: PunjabUniv,
        name: "Punjab University",
    },
    {
        image: IimNoida,
        name: "IIM Noida",
    },
    {
        image: IitJabalpur,
        name: "IIT Jabalpur",
    }
]

const Partner = (props) => {
  //const isMobile = useIsMobile();
  return (
    <section>
      <Container className="main-container-box-prev">
        <Typography component="h2" className="techvanto-whyus-heading heading-alignment">
            {props.heading}
        </Typography>
        <div style={{
            textAlign: "center",
        }}>
            {props.data.map((item, index) => (
                <div key={index} style={{
                    display: 'inline-block'
                    }} className="client-box">
                    <img 
                        src={item.image} 
                        // height={index == 1 && '124'}
                        style={
                            index == 1  ? {
                                width: '128px'
                            } : 
                            (index == 2 ? {width: '100px'} : (
                                index == 3 ? {width: '170px'} : (index == 4 ? {width: '90px'} : {width: '120px'})))
                            }
                        />
                </div>
            ))}
        </div>
      </Container>
    </section>
  );
};

export default Partner;
