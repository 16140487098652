import React, {useState, useEffect} from 'react';
import './discussion.css';
import User from '../../assets/images/user.png';
import {commentTimeCount} from '../../utils/commentTimeCount';
import CardImage from '../../assets/images/forum_image.png';
import FilledUpArrow from '../../assets/images/filled-up-arrow.jpg';
import UpArrowIcon from '../../assets/images/up_arrow_icon.png';
import DownArrowIcon from '../../assets/images/down_arrow_icon.png';
import FilledDownArrowIcon from '../../assets/images/filled-down-arrow.jpg';
import ShareTypeOne from '../../assets/images/share_type_one.png';
import ShareTypeTwo from '../../assets/images/share_type_two.png';
import CommentIcon from '../../assets/images/comment_icon.png';
import ReplyIcon from '../../assets/images/reply_icon.png';
import ButtonLoader from '../../assets/images/button_loader.gif';
// import DemoHeader from './DemoHeader';
// import { Typography } from "@mui/material";
// import { toast } from "react-toastify";
// import DownArrow from '../../assets/images/down-arrow.png';
// import addDemoApi from "../../../src/apis/api/AddDemo";
// import AllCourseApi from "../../apis/api/AllCourse";
// import ButtonLoader from "../../assets/images/button_loader.gif";

function ForumCard(props){
    //console.log(props.data)
    return(
        <div className="forum-card-box">
            <div className="user-box">
                <img src={User} />
                <h5 className="user-name-text">{props.data?.name}</h5>
            </div>
            <p className="forum-description">
                {props.data?.description}
            </p>
            <img src={`${props.data?.image_url}`} />
            <div className="action-box">
                <div className="action-aria">
                    <div className="action-inner-box">
                        <div 
                            style={{cursor: "pointer"}} 
                            onClick={() => {
                                props.likePressed(props.data?._id, '', '', props.data?.isLikedByUser, props.data?.isDislikedByUser)
                            }}>
                            <img 
                                src={props.data?.isLikedByUser ? FilledUpArrow : UpArrowIcon} 
                                className="icon-style" 
                                style={{marginBottom: '4px'}} />
                        </div>
                        <span className="action-value">{props.data?.like_count}</span>
                    </div>
                    <div className="action-inner-box">
                        <div 
                            style={{cursor: "pointer"}} 
                            onClick={() => {
                                props.dislikePressed(props.data?._id, '', '', props.data?.isDislikedByUser, props.data?.isLikedByUser)
                            }}>
                            <img 
                                src={props.data?.isDislikedByUser ? FilledDownArrowIcon : DownArrowIcon} 
                                className="icon-style" 
                                style={{marginBottom: '4px'}} />
                        </div>
                        <span className="action-value">{props.data?.dislike_count}</span>
                    </div>
                    <div className="action-inner-box">
                        <div style={{cursor: "pointer"}}>
                            <img src={ShareTypeOne} className="icon-style" style={{marginBottom: '4px'}} />
                        </div>
                        <span className="action-value">{props.data.share_count}</span>
                    </div>
                    <div className="action-inner-box">
                        <div style={{cursor: "pointer"}} onClick={() => {props.viewComments(props.data?._id)}}>
                            <img src={CommentIcon} className="icon-style" style={{marginBottom: '4px'}} />
                        </div>
                        <span className="action-value">{props.data?.commentCount}</span>
                    </div>
                </div>
                <div className="action-inner-box">
                    <div style={{cursor: "pointer"}} 
                        onClick={() => {props.sharePressed(props.data._id, props.data?.image_url)}} 
                        data-bs-toggle="modal" 
                        data-bs-target="#myModal">
                        <img src={ShareTypeTwo} className="icon-style" style={{marginBottom: '4px'}} />
                    </div>
                    <span className="action-value">Share</span>
                </div>
            </div>
            <div className="comment-aria">
                <div className="comment-input-aria">
                    <img src={User} height="48" width="48" />
                    <div>
                        <input placeholder="Add a comment..." className="comment-box" onChange={(e) => {
                            props.onCommentChange(e.target.value)
                        }} />
                    </div>
                </div>
                <div className="add-comment-btn">
                    <button type="button" 
                        className="btn-grad-resume comment-btn"
                        onClick={() => {
                            props.commentPressed(props.data?._id)
                        }}
                        style={(props.loader && (props.commentButtonId === props.data?._id)) ? 
                            {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-primary)'}}
                                            disabled={props.loader}
                        >
                        {(props.loader && (props.commentButtonId === props.data?._id)) ? 
                        <img src={ButtonLoader} width="80" alt="" /> : 'Add Comment'}
                    </button>
                </div>
            </div>
            {(props?.postId === props.data?._id) &&
                <>
                    {props.comments.map((item, ind) => (
                        <div className="reply-aria" key={ind}>
                            <img src={User} height="48" width="48" />
                            <div className="reply-text-box">
                                <div className="reply-user-name-box">
                                    <p className="reply-by">{item.name}</p>
                                    <p className="reply-time">{commentTimeCount(item.created_at)} ago</p>
                                </div>
                                <div className="reply-text">
                                    {item.description}
                                </div>
                                <div className="action-aria">
                                    <div className="action-inner-box">
                                        <div 
                                            style={{cursor: "pointer"}} 
                                            onClick={() => {
                                                props.likePressed(item?._id, 'comment', props.data?._id, item.isLikedByUser, item.isDislikedByUser)
                                                }}>
                                            <img 
                                                src={item.isLikedByUser ? FilledUpArrow : UpArrowIcon} 
                                                className="icon-style" 
                                                style={{marginBottom: '4px'}} />
                                        </div>
                                        <span className="action-value">{item.like_count}</span>
                                    </div>
                                    <div className="action-inner-box">
                                        <div 
                                            style={{cursor: "pointer"}} 
                                            onClick={() => {
                                                props.dislikePressed(item?._id, 'comment', props.data?._id, item.isDislikedByUser, item.isLikedByUser)
                                                }}>
                                            <img 
                                                src={item.isDislikedByUser ? FilledDownArrowIcon : DownArrowIcon} 
                                                className="icon-style" />
                                        </div>
                                        <span className="action-value">{item.dislike_count}</span>
                                    </div>
                                    {/* <div className="action-inner-box">
                                        <div style={{cursor: "pointer"}} onClick={() => {props.replyPressed(item._id)}}>
                                            <img src={ReplyIcon} className="icon-style" />
                                        </div>
                                        <span className="action-value">Reply</span>
                                    </div> */}
                                </div>
                                {/* {props.commentId === item._id &&
                                    <div className="comment-aria">
                                        <div className="comment-input-aria">
                                            <img src={User} height="48" width="48" />
                                            <div>
                                                <input placeholder="Add a comment..." className="comment-box" onChange={(e) => {
                                                    props.onCommentChange(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="add-comment-btn">
                                            <button type="button" 
                                                className="btn-grad-resume comment-btn"
                                                onClick={() => {
                                                    props.AddReplyPressed(item?._id)
                                                }}
                                                style={props.loader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-primary)'}}
                                                                    disabled={props.loader}
                                                >
                                                {props.loader ? <img src={ButtonLoader} width="80" alt="" /> : 'Add Reply'}
                                            </button>
                                        </div>
                                    </div>
                                } */}
                            </div>
                        </div>
                    ))}
                </>
            }

        </div>
    )
}
export default ForumCard;