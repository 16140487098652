import React from 'react';
import OBJECTS from '../../../assets/Svgs/OBJECTS.svg';
import '../../BookDemo/BookDemo.css';
import { Typography } from "@mui/material";

const PageHeader = (props) => {
    return(
    <div className="demo-head header-height header-height2">
        <div className="header-image-box"> 
            <img src={props.image} alt="object" className="demo-img image-style"/>
        </div>
        <div className="demo-text new-text-style2 new-text-style3">
            <Typography component="h2" className="page-heading-text">
                <span className="schedule">{props.heading}</span>
            </Typography>
            <Typography className="page-heading-desctiption forum-desc" style={{color:"white",fontSize:"20px",marginTop:"1%"}}>
                {props.description}
            </Typography>
        </div>
    </div>
    )
}
export default PageHeader;