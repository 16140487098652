import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    popup:{
        backgroundColor: "var(--color-secondary)",
        height: '100px',
        padding: '40px 15px 40px 15px',
        borderRadius: '50%',
        display: 'flex',
        width: '100px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        textAlign: 'center',
        color: '#fff',
        textDecoration: 'none',
        fontWeight: '600',
        margin: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        "&:hover":{
            color: '#fff'
        }
    }
}))
const Popup = () => {
    const classes = useStyles();
    return (
        <>
            <Link to="/techvanto-summer-internship" className={classes.popup}>
                Apply for Summer Internship
            </Link>
            {/* <Link to="/techvanto-winter-internship" className={classes.popup}>
                Apply for Winter Internship
            </Link> */}
        </>
    )
}
export default Popup;