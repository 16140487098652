import "./Flier.css";
import { styled } from '@mui/material/styles';
import closeIcon from '../../assets/images/closeIcon.jpg';
import {
  Dialog,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const Flier = ({ open, setOpen, data, flier }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div >
      <BootstrapDialog
      className="flyer-dialog-title"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="customized-dialog-description"
        open={open}   
      > 
        <div className="flyer-dialog">
          <div className="closeButton">
            <img src={closeIcon} alt="Close" style={{borderRadius:"50%"}}/>
          </div>
        <Link 
            to={
              flier?.redirect_info?.redirect_type === "event" &&
              `/event/${flier?.redirect_info?.event_id}`
            }
          >
            <img src={`${flier?.bannerUrl}`} alt="" onClick={handleClose} />
            
          </Link>
          {console.log(flier,'flier')}
          
        </div>
      </BootstrapDialog>
    </div>
  );
};

export default Flier;
