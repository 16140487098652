import React from 'react';
import {useState} from 'react';
import Loader from "../../components/Loader";
import Resume from "../../components/Resume/Resume";

function Resource(){
    const [loader, setLoader] = useState(true);
return(
    <div>
        <Resume />
    </div>
)
}
export default Resource;