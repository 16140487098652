import React from 'react';
import { useEffect, useState } from 'react';
import { Typography, Container, CardActions } from "@mui/material";
import { Link } from 'react-router-dom';
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.development";
import { useNavigate, useLocation } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import getAllReferals from '../../apis/api/GetReferals';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import "./olympiad.css";
import { Button } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Partner from "../../components/Partners/Partners";
import ReviewAria from "../../components/RviewAria/ReviewAria";
import Partners from "../../components/Partners/Partners";
import AllCourseApi from "../../apis/api/AllCourse";
import ButtonLoader from "../../assets/images/button_loader.gif";
import { toast } from "react-toastify";
import Calendar from "../../assets/images/b_calender.png";
import Clock from "../../assets/images/b_clock.png";
import Rupee from "../../assets/images/b_rupee.png";
import Question from "../../assets/images/b_question.png";
import YCalender from "../../assets/images/y_calender.png";
import OlympiadIntro from "../../assets/images/olympiad_intro.png";
import OlympiadBenefits from "../../assets/images/olympiad_benefits.png";
import OlympiadProcess from "../../assets/images/olympiad_process.png";
import OlympiadRegister from "../../assets/images/olympiad_register.png";
import DownArrow from "../../assets/images/down-arrow.png";
import OlympiadBottomImage from "../../assets/images/olympiad.png";
import OlympiadHeaderImage from "../../assets/images/olympiad_header_image.png";
import addOlympiadApi from "../../apis/api/AddOlympiad";

const Olympiad = () => {
    const [file, setFile] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [resumeError, setResumeError] = useState(true);
    const [loader, setLoader] = useState(true);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [collegeError, setCollegeError] = useState(false);
    const [branchError, setBranchError] = useState(true);
    const [info, setInfo] = useState({
        name: "",
        email: "",
        phone: "",
        college: "",
        branch: ""
    });
    let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const submitForm= async(e) =>{
        e.preventDefault();
        setLoader(true);
        if(!nameError && !phoneError && 
           !emailError && !collegeError && 
           !branchError)
        {
            let res;
            setButtonLoader(true)
            res = await addOlympiadApi(info);
            if(res){
                setButtonLoader(false)
                if(res.statusCode === 200){
                    console.log("success");
                    let fileInfo = {
                        name: "",
                        email: "",
                        phone: "",
                        college: "",
                        branch: ""
                    }
                    setNameError(true);
                    setEmailError(true);
                    JSON.parse(localStorage.getItem('user')) ? setPhoneError(false) : setPhoneError(true);
                    setResumeError(true);
                    setFile(null);
                    setInfo(fileInfo);
                    toast.success(res.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }else{
                    toast.error('Something went wrong.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }
            }else{
                setButtonLoader(false);
                toast.error('Something went wrong.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            }
        }else{
            console.group("hee",branchError)
            if(nameError) return errorToast('Name required.');
            if(phoneError) return errorToast('Invalid phone number entered.');
            if(emailError) return errorToast('Invalid email entered.');
            if(branchError) return errorToast('Branch required.');
            if(collegeError) return errorToast('College name required.');
        }
    }
    const formValidate = (event) => {
        if (event.name === "name") {
          setInfo({ ...info, name: event.value });
          if(event.value === "") return setNameError(true);
          setNameError(false);
        }
        if(event.name === "college"){
            setInfo({ ...info, college: event.value });
            if(event.value === "") return setCollegeError(true);
            setCollegeError(false);
        }
        if (event.name === "email") {
          setInfo({ ...info, email: event.value });
          if(event.value === "" || !event.value.match(emailValidate)) return setEmailError(true);
          setEmailError(false);
        }
        if (event.name === "phone") {
          setInfo({ ...info, phone: event.value });
          if(event.value !== "" && event.value.length === 10) return setPhoneError(false);
          setPhoneError(true);
        }
        if (event.name === "branch") {
            setInfo({ ...info, branch: event.value });
            if(event.value !== "") return setBranchError(false);
            setBranchError(true);
          }

      }
    const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    return (
        <>
            <section className="summer-intern olymp-header" style={{
                background: `url(${OlympiadHeaderImage})`
                }}>
                <div className="prize-text">
                    <p className="olymp-header-text">TECHVANTO <br/> TECH <br/> OLYMPIAD</p>
                    <div className="prize-value">
                        <p>Prizes worth</p>
                        <p>7.5 Lakhs</p>
                    </div>
                </div>
                <div className="olymp-main-box">
                    <div className="olymp-main-date">
                        <div>
                            <img src={YCalender} width="24"/>
                        </div>
                        <p style={{
                            margin: 0,
                            fontWeight: 'bold',
                            marginLeft: '12px',
                        }}>Tuesday, 15th November 2022</p>
                    </div>
                    <div className="olymp-information-main-box">
                        <div className="olymp-information-box">
                            <img src={Calendar} width="16" />
                            <div className="olymp-information-box-text olymp-start-at">
                                Starting at
                            </div>
                            <div className="olymp-information-box-value">
                                - Tuesday, 15th Nov 2022 10:00 AM EST
                            </div>
                        </div>
                        <div className="olymp-information-box">
                            <img src={Clock} width="16" />
                            <div className="olymp-information-box-text olymp-duration">
                                Duration
                            </div>
                            <div className="olymp-information-box-value">
                                - 60 minutes
                            </div>
                        </div>
                        <div className="olymp-information-box">
                            <img src={Rupee} width="10" style={{marginRight: "6px"}} />
                            <div className="olymp-information-box-text olymp-fee">
                                Fee
                            </div>
                            <div className="olymp-information-box-value">
                                - No Registration Fee
                            </div>
                        </div>
                        <div className="olymp-information-box">
                            <img src={Question} width="10" style={{marginRight: "6px"}} />
                            <div className="olymp-information-box-text olymp-question">
                                Questions
                            </div>
                            <div className="olymp-information-box-value">
                                - 60 Questions
                            </div>
                        </div>
                        <div style={{
                            textAlign: "center",
                            marginBottom: "8px"
                        }}>
                            <HashLink to="/olympiad/#registerForm" style={{
                                backgroundColor: "var(--color-secondary)",
                                border: "none",
                                padding: "4px 20px",
                                color: "var(--color-white)",
                                fontWeight: "bold",
                                fontSize: "18px",
                                borderRadius: "8px",
                                textDecoration:'none'
                            }}>
                                Register
                            </HashLink>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <section className="olymp-intro">
                <div>
                    <img src={OlympiadIntro} />
                </div>
                <div className="olymp-intro-text">
                    Techvanto Tech Olympiad is an online test done at the national level for engineering students. This test aimed for 
                    checking basic technological as well as aptitude knowledge of a student. Ranking of students is done on the basis of 
                    their score at national level. Recognised certificates will be provided to the merit students.
                </div>
            </section>
            <br />
            <section className="olymp-benefits">
                <Typography component="h2" className="techvanto-whyus-heading heading-alignment">
                    Benefits to the Students
                </Typography>
                <div>
                    <img src={OlympiadBenefits} />
                </div>
            </section>
            <br />
            <section className="olymp-benefits">
                <Typography component="h2" className="techvanto-whyus-heading heading-alignment">
                    Process Of Olympiad
                </Typography>
                <div>
                    <img src={OlympiadProcess} />
                </div>
            </section>
            <br />
            <section className="olymp-benefits">
                <Typography component="h2" className="techvanto-whyus-heading heading-alignment">
                    Register For Techvanto Tech Olympiad
                </Typography>
                <div className="olymp-registr-form" id="registerForm">
                    <div>
                        <img src={OlympiadRegister} className="olymp-form-image" />
                    </div>
                    <div>
                        <form onSubmit={submitForm} className="right-panel-form">
                            <div>
                                <div className="form-group" style={{textAlign: 'left'}}>
                                    <label className="resume-form-label">Name</label>
                                    <input 
                                    type="text" 
                                    name="name" 
                                    className="form-control resume-input-box" 
                                    value={info.name}
                                    onChange={((e)=>formValidate(e.target))}
                                    error={nameError}
                                    required/>
                                </div>
                            </div>
                            <br/>
                            <div>
                                <div className="form-group" style={{textAlign: 'left'}}>
                                    <label className="resume-form-label">College Name</label>
                                    <input 
                                    type="text" 
                                    name="college" 
                                    className="form-control resume-input-box" 
                                    value={info.college}
                                    onChange={((e)=>formValidate(e.target))}
                                    error={nameError}
                                    required/>
                                </div>
                            </div>
                            <br/>
                            <div>
                                <div className="form-group" style={{textAlign: 'left'}}>
                                    <label className="resume-form-label">Contact Number</label>
                                    <input 
                                    type="number"
                                    name="phone" 
                                    className="form-control resume-input-box" 
                                    value={info.phone}
                                    onChange={((e)=>formValidate(e.target))}
                                    error={phoneError}
                                    required/>
                                </div>
                            </div>
                            <br/>
                            <div>
                                <div className="form-group" style={{textAlign: 'left'}}>
                                    <label className="resume-form-label">Email id</label>
                                    <input 
                                    type="email" 
                                    name="email" 
                                    className="form-control resume-input-box" 
                                    value={info.email}
                                    onChange={((e)=>formValidate(e.target))}
                                    error={emailError} 
                                    //helperText={name ? "Enter Your Name." : ""}
                                    required/>
                                </div>
                            </div>
                            <br/>
                            <div>
                                <div className="form-group" style={{
                                    textAlign: 'left',
                                    position: 'relative',
                                    }}>
                                    <label className="resume-form-label">Branch</label>
                                    <select name="branch" className="form-control input-style-coun" 
                                        onChange={((e)=>formValidate(e.target))}>
                                        <option 
                                            value="Select Education" 
                                            selected
                                            disabled>Select Education</option>
                                        <option value="In 12th">CSE</option>
                                        <option value="12th completed">MEC</option>
                                        <option value="Graduating">ELE</option>
                                        <option value="Graduated">BIO</option>
                                    </select>
                                    <div className="down-arrow">
                                        <img src={DownArrow} height="24" width="24" />
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div>
                                <center><button type="submit"
                                className="btn-grad-resume resume-upload-btn"
                                style={buttonLoader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-secondary)'}}
                                    disabled={buttonLoader}
                                >
                                    {buttonLoader ? <img src={ButtonLoader} width="80" alt="" /> : 'Submit'}
                                </button></center>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <br />
            <section className="olymp-finger-tips" style={{
                background: `url(${OlympiadBottomImage})`,
                backgroundSize: 'cover'
                }}>
                <Typography component="h2" className="techvanto-whyus-heading">
                    Knowledge At Your <br/>Fingertips
                </Typography>
                <p style={{
                    marginLeft: '25px'
                }}>Get your hands on our Top Courses<br/> and get smarter with Tech today.</p>
                <div style={{
                    marginLeft: '25px',
                    paddingBottom: '25px'
                }}>
                    <Link to="/all-courses/college" 
                        className="btn-grad-resume resume-upload-btn olymp-enroll"
                        style={{
                            backgroundColor: 'var(--color-secondary)'
                        }}>
                        Enroll Now
                    </Link>
                </div>
            </section>
        </>
    )
}
export default Olympiad;