import React from 'react';
import '../BookDemo/BookDemo.css';
import { Typography } from "@mui/material";

const EditorHeader = (props) => {
    return(
    <div className="demo-head header-height header-height3 header-box">
        <div className="header-image-box2"> 
            <img src={props.image} alt="object" className="demo-img image-style2"/>
        </div>
        <div className="demo-text new-text-style2">
            <Typography component="h2" className="page-heading-text page-heading-text2">
                <span className="schedule">{props.heading}</span>
            </Typography>
            <Typography 
                className="page-heading-desctiption page-heading-desctiption2" 
                style={{color:"white",fontSize:"20px",marginTop:"1%"}}>
                {props.description}
            </Typography>
        </div>
    </div>
    )
}
export default EditorHeader;