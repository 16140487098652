import * as React from "react";
import Sidebar from "./Sidebar";
import "./singlePost.css";
import Typography from "@mui/material/Typography";
//import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ShareSharpIcon from '@mui/icons-material/ShareSharp';
import Popover from '@mui/material/Popover';
import { RWebShare } from "react-web-share";

export default function SinglePost({ blogDetails }) {
  const currentUrl = window.location.href;
  return (
    <>
      <div className="blog-author-box">
        <div style={{
          height: '64px',
          width: '64px',
          borderRadius: '50%'
        }} className="blog-author-pic">
          <img src={blogDetails?.author_image} style={{
            borderRadius: '50%'
          }} />
        </div>
        <div className="blog-auth-detail">
          <h5 style={{
            fontWeight: 'bold',
            marginTop: '12px'
          }}>by {blogDetails?.author_name}</h5>
          <p style={{
            color: 'var(--color-secondary-text)'
          }}>{blogDetails?.author_detail}</p>
          <p>
            {blogDetails?.posted_on} . 5 min read
          </p>
        </div>
      </div>
      <div className="singlePost">
        <div className="singlePostWrapper">
          <div className="singlePostInfo">
            <span>
              {/* Author: */}
              <b className="singlePostAuthor">
                <span className="link" >
                  {/* {blogDetails && blogDetails.author_name} */}
                  <div style={{display:'flex'}}>   
                              
                  <Typography gutterBottom variant="h2" color="black" component="h2" className="techvanto-whyus-heading blog-title">                  
                    {blogDetails && blogDetails.title}
                  <RWebShare
                    data={{
                      text: `Blog - ${blogDetails && blogDetails.title}`,
                      url: currentUrl,
                      title: `${blogDetails && blogDetails.title}`,
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <ShareSharpIcon sx={{marginLeft: "23px",marginTop: "-15%",color:"#294d6a"}}/>      
                  </RWebShare>                  
                  {/* <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <Typography>
                          <div className="share-btn-container" style={{width:'200px',height:'20px'}}>
                     <a href="https://www.facebook.com/sharer.php?u=[post-url]" className="facebook">
                      <i className='fab fa-facebook'></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-whatsapp"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </div></Typography> 
                      </Popover> */}
                  </Typography>
                  </div>
                  
            <span className="date">{blogDetails && blogDetails.posted_on}   <span style={{fontSize:'19px',fontWeight:'bolder'}}> · </span>  5min read </span> 
                </span>
                <br/>
              {blogDetails && blogDetails.author_name}
              </b>  
            </span>
          </div>
          <p className="singlePostDesc">
            {blogDetails &&
              blogDetails.description.map((data) => (
                <>
                  <h3>{data.title}</h3>
                  <p style={{ textAlign: "justify" }}>{data.detail}</p>
                </>
              ))}
          </p>
        </div>
        <Sidebar sideBarDetail={blogDetails} />
      </div>
    </>
  );
}
