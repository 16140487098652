import React, {useState, useEffect} from 'react';
import ForumCard from './ForumCard';
import addDiscussionPostApi from '../../apis/api/AddDiscussionPost';
import getAllDiscussionPostsApi from '../../apis/api/GetDiscussionPosts';
import addCommentApi from '../../apis/api/AddComment';
import addLikeApi from '../../apis/api/AddLike';
import addDislikeApi from '../../apis/api/AddDislike';
import addShareCountApi from '../../apis/api/AddShareCount';
import getPostCommentApi from '../../apis/api/GetPostComments';
import { toast } from "react-toastify";
import UploadOnS3 from "../../apis/api/UploadOnS3";
import User from '../../assets/images/user.png';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchIcon from '../../assets/images/search-icon.png';
import FilterIcon from '../../assets/images/filter_icon.png';
import DownArrow from '../../assets/images/down-arrow.png';
import PageHeader from '../Generic/Header/PageHeader';
import HeaderImage from '../../assets/images/discussion_forum.png';
import ImageUpload from '../../assets/images/image_upload.png';
import ButtonLoader from '../../assets/images/button_loader.gif';
import {
    FacebookIcon,
    FacebookShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterIcon,
    TwitterShareButton,
    LinkedinShareButton,
    LinkedinIcon
  } from "react-share";
// import DemoHeader from './DemoHeader';
// import { Typography } from "@mui/material";
// import { toast } from "react-toastify";
// import DownArrow from '../../assets/images/down-arrow.png';
// import addDemoApi from "../../../src/apis/api/AddDemo";
// import AllCourseApi from "../../apis/api/AllCourse";
// import ButtonLoader from "../../assets/images/button_loader.gif";

function DiscussionForum(){
    const [isFilterDropdown, setIsFilterDropdown] = useState(false);
    const [description, setDescription] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [file, setFile] = useState(null);
    const [fileLoader, setFileLoader] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [commentBtnLoader, setCommentBtnLoader] = useState(false);
    const [discussionPostData, setDiscussionPostData] = useState([]);
    const [comment, setComment] = useState('');
    const [allCommentOfOnePost, setAllCommentOfOnePost] = useState({});
    const [postId, setPostId] = useState('');
    const [commentId, setCommentId] = useState('');
    const [modalForShare, setModalForShare] = useState(false);
    const [shareUrl, setShareUrl] = useState('');
    const [currentUrl, setCurrentUrl] = useState('');
    const [sharePostId, setSharePostId] = useState('');
    const [commentButtonId, setCommentButtonId] = useState('');
    const [unauthorized, setUnauthorized] = useState(false);
    let { isLogin } = useSelector((state) => state.AuthReducer);

    useEffect(() => {
        getPosts();
    },[])

    const getPosts = async() => {
        if(isLogin){
            let res = await getAllDiscussionPostsApi();
            console.log("res====",res)
            if(res){
                if(res === "Unauthorized"){
                    setUnauthorized(true);
                    errorToast("Session expired, please login.");
                    return;
                }
                setDiscussionPostData(res?.data);
            }
        }
    }

    const addDiscussionPost = async() => {
        if(description === ''){
            errorToast('Type something about post.');
            return;
        }
        // if(imageUrl === ''){
        //     errorToast('Please upload an image.');
        //     return;
        // }
        let body = {
            name: JSON.parse(localStorage.getItem('user')).name,
            description: description,
            image_url: imageUrl,
        }
        try{
            setButtonLoader(true);
            let res = await addDiscussionPostApi(body);
            if(res?.statusCode === 200){
                setImageUrl('');
                setDescription('');
                getPosts();
                toast.success('Post added successfully.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }catch(e){
            console.log(e);
            toast.error('Something went wrong.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setButtonLoader(false);
        }finally{
            setButtonLoader(false);
        }
    }

    const handleUploadOnS3 = async(e) => {
        try {
            setFileLoader(true);
            const image = new FormData();
            image.append("file", e.target.files[0]);
            if(e.target.files[0]?.type === 'image/jpeg' || 
                e.target.files[0]?.type === 'image/png'){
                setFile(e.target.files[0]?.name);
                const data = await UploadOnS3(image);
                // setInfo({...info, resume_link: data?.data?.urls[0]})
                setImageUrl(data?.data?.urls[0]);
                setFileLoader(false);
            }else{
                errorToast('Only png or jpg files are allowed')
            }
            
        } catch (e) {
            console.log(e);
            toast.error('Something went wrong.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setFileLoader(false);
        } finally {
            console.log("Finally called");
            setFileLoader(false);
        }
      };
      const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const addLike = async(id, from, postId, userLiked, userDisliked) => {
        if(userLiked || userDisliked) return;
        let body = {
            discussion_id: id
        }
        let res = await addLikeApi(body);
        if(res?.statusCode === 200){
            if(from === 'comment'){
                getComments(postId);
                return;
            }
            getPosts();
        }
    }

    const addDislike = async(id, from, postId, userDisliked, userLiked) => {
        if(userDisliked || userLiked) return;
        let body = {
            discussion_id: id
        }
        let res = await addDislikeApi(body);
        if(res?.statusCode === 200){
            if(from === 'comment'){
                getComments(postId);
                return;
            }
            getPosts();
        }
    }
    const addComment = async(id) => {
        if(comment === ''){
            errorToast('Write something in comment box.');
            return;
        }
        setCommentBtnLoader(true);
        setCommentButtonId(id);
        let body = {
            name: JSON.parse(localStorage.getItem('user')).name,
            description: comment,
            parent_discussion_id: id,
        }
        let res = await addCommentApi(body);
        if(res.statusCode === 200) {
            setCommentBtnLoader(false);
            setComment('');
            getPosts();
            getComments(id);
            toast.success('Comment added successfully.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const getComments = async(id) => {
        let res = await getPostCommentApi(id);
        console.log("response here======",res)
        if(res){
            setAllCommentOfOnePost(res?.data);
            setPostId(id);
        }
    }
    const getCommentText = (text) => {
        setComment(text);
    }
    const replyButton = (id) => {
        setCommentId(id);
    }   
    const addReplyButton = () => {
        addComment(commentId);
    }
    const shareButton = async(id, url) => {
        setModalForShare(true);
        setShareUrl(url);
        setCurrentUrl(window.location.href);
        setSharePostId(id);
        //document.querySelector(".modal").classList.add("show");
        //document.querySelector(".modal").style.display = "block";
        // let res = await addShareCountApi(body);
        // console.log("reply count",res);
    }
    const socialMediaShareClicked = async() => {
        let body = {
            discussion_id: sharePostId
        }
        let res = await addShareCountApi(body);
        if(res.statusCode === 200){
            getPosts();
        }
    }
    return(
        <>
            <PageHeader 
                heading={'Discussion Forum'}
                description={'Ask anything from anywhere and get heard.'}
                image={HeaderImage} />

            <div className="discussion-forum-box">
                {/* <div className="search-input-box">
                    <div className="search-box">
                        <input placeholder="Search" className="search-input-aria"/>
                        <div className="search-icon">
                            <img src={SearchIcon} width="36" height="36"/>
                        </div>
                    </div>
                    <div className="filter-big-box">
                        <div className="filter-box" onClick={() => {setIsFilterDropdown(!isFilterDropdown)}}>
                            <div className="filter-text">
                                <div style={{fontWeight: "bold"}}>Filter</div>
                                <img src={FilterIcon} width="14" height="14" className="filter-icon"/>
                            </div>
                            <div>
                                <img src={DownArrow} width="24" height="24" />
                            </div>
                        </div>
                        {isFilterDropdown && 
                            <div className="filter-dropdown">
                                <div style={{textAlign: 'center', fontWeight: 'bold'}}>Filter</div>
                                <div className="dropdown-aria">
                                    <div className="filter-text">
                                        <div style={{fontWeight: "bold", fontSize: '14px'}}>Sort By</div>
                                    </div>
                                    <div>
                                        <img src={DownArrow} width="20" height="20" />
                                    </div>
                                </div>
                                <div className="date-text">Start Date</div>
                                <div className="dropdown-aria">
                                    <div className="filter-text" style={{width: '100%'}}>
                                        <input type="date" className="date-input" />
                                    </div>
                                </div>
                                <div className="date-text">End Date</div>
                                <div className="dropdown-aria">
                                    <div className="filter-text" style={{width: '100%'}}>
                                        <input type="date" className="date-input" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div> */}
                <div className="share-button-box">
                    {(isLogin && !unauthorized) ? 
                    <button className="share-post-button" data-bs-toggle="modal" data-bs-target="#myModal" onClick={() => {
                        setModalForShare(false)
                    }}>
                        <img src={User} height="48" width="48" />
                        <div className="share-btn-text">What do you want to share ?</div>
                    </button>:
                    <Link to="/auth-user" 
                        className="share-post-button" 
                        style={{textDecoration: "none"}}
                        onClick={() => errorToast('Session expired or not logged in.')}>
                        <img src={User} height="48" width="48" />
                        <div className="share-btn-text">What do you want to share ?</div>
                    </Link>
                    }
                </div>
                {(isLogin && !unauthorized) ?
                <>
                    {discussionPostData?.length > 0 ?
                    <>
                        {discussionPostData.map((item, index) => (
                            <ForumCard 
                                data={item} 
                                key={index}
                                postId={postId}
                                commentId={commentId}
                                loader={commentBtnLoader}
                                comments={allCommentOfOnePost}
                                likePressed={(id, from, postId, userLiked, userDisliked) => {addLike(id, from, postId, userLiked, userDisliked)}}
                                dislikePressed={(id, from, postId, userDisliked, userLiked) => {addDislike(id, from, postId, userDisliked, userLiked)}}
                                viewComments={(id) => {getComments(id)}}
                                commentPressed={(id) => {addComment(id)}}
                                onCommentChange={(text) => {getCommentText(text)}}
                                replyPressed={(id) => {replyButton(id)}}
                                AddReplyPressed={() => {addReplyButton()}}
                                sharePressed={(id, url) => {shareButton(id, url)}}
                                commentButtonId={commentButtonId}
                            />
                        ))} 
                    </>
                    :
                    <>
                        <div>
                            <p style={{
                                textAlign: 'center',
                                fontSize: '24px',
                                fontWeight: 'bold',
                                marginTop: '24px'
                            }}>No posts available.</p>
                        </div>
                    </>}
                </>
                :
                <>
                    <div>
                        <p style={{
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: 'bold',
                            marginTop: '24px'
                        }}>Please login to share and see the posts.</p>
                    </div>
                </>
                }
                {/* <div className="forum-card-box">
                    
                </div> */}
                <div className="modal" id="myModal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title" className="modal-header-text-style">
                                {modalForShare && 'Share Post'}
                                {!modalForShare && 'Add Post'}
                            </h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>

                        <div className="modal-body">
                            {!modalForShare &&
                            <>
                            <div>
                                <img src={User} height="48" width="48" />
                                <span className="post-user-name">
                                    {isLogin ? JSON.parse(localStorage.getItem('user')).name : 'User Name'}
                                </span>
                            </div>
                            <div>
                                <textarea 
                                    placeholder={`What do you want to share, ${isLogin && JSON.parse(localStorage.getItem('user')).name.split(' ')[0]} ?`} 
                                    rows="4" 
                                    cols="50"
                                    className="text-aria-input"
                                    onChange={(e) => {
                                        setDescription(e.target.value)
                                    }}
                                />
                            </div>
                            </>
                            }
                            {modalForShare &&
                            <div>
                                <FacebookShareButton 
                                    url={shareUrl} 
                                    quote={`${currentUrl}`} 
                                    hashtag={'#TechvantoAcademy'} 
                                    onClick={() => {socialMediaShareClicked()}}>
                                    <FacebookIcon size={40} round={true} style={{marginRight: '12px'}}/>
                                </FacebookShareButton>
                                <WhatsappShareButton url={shareUrl} title={'Techvanto Post'}>
                                    <WhatsappIcon size={40} round={true} style={{marginRight: '12px'}}/>
                                </WhatsappShareButton>
                                <LinkedinShareButton url={shareUrl} title={'Techvanto Post'}>
                                    <LinkedinIcon size={40} round={true} style={{marginRight: '12px'}}/>
                                </LinkedinShareButton>
                                <TwitterShareButton url={shareUrl} title={'Techvanto Post'}>
                                    <TwitterIcon size={40} round={true} style={{marginRight: '12px'}}/>
                                </TwitterShareButton>
                            </div>
                            }
                        </div>

                        {!modalForShare && <div className="modal-footer">
                            <div className="modal-footer-box">
                                <div style={{cursor: "pointer"}}>
                                    <label for="files" className="upload-icon">
                                        <img src={ImageUpload} width="24" height="24" />
                                    </label>
                                    <input id="files" type="file" style={{visibility:"hidden"}}
                                    onChange={handleUploadOnS3} accept=".png,.jpg,.jpeg" 
                                    className="file-input"/>
                                    {(!fileLoader && file) && <p className="file-name">{file}</p>}
                                    {fileLoader && 
                                        <div className="upload-loader-file">
                                            <img src={ButtonLoader} width="80" alt="" />
                                        </div>
                                    }
                                </div>
                                <div className="modal-footer-button-box">
                                    <button type="button" data-bs-dismiss="modal" className="cancel-button">Cancel</button>
                                    <button 
                                        onClick={addDiscussionPost}
                                        type="button"
                                        className="btn-grad-resume resume-upload-btn"
                                        style={buttonLoader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-primary)'}}
                                            disabled={buttonLoader}
                                        >
                                        {buttonLoader ? <img src={ButtonLoader} width="80" alt="" /> : 'Add Post'}
                                    </button>
                                </div>
                            </div>
                        </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DiscussionForum;