/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";
import { NavHashLink } from "react-router-hash-link";
import { useRecoilState } from "recoil";
import AppBar from "@mui/material/AppBar";
import { Container, Button, Avatar, Tooltip } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link, NavLink } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu"; 
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Badge from "@mui/material/Badge";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import Draggable from "react-draggable";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PrimaryNavBar from "../primaryNavBar/PrimaryNavBar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
// import { HashLink } from "react-router-hash-link";
// import logo from "../../../assets/images/logo-print-hd-transparent-removebg-preview.png";
import logoOnScroll from "../../../assets/images/on-scroll-logo.png";
import LogoWhiteBg from "../../../assets/images/logo_white_bg.png"
import SchoolIcon from "../../../assets/images/new-course-icon-school.svg";
import IntermediateIcon from "../../../assets/images/new-course-icon-intermediate.svg";
import CollegeIconIcon from "../../../assets/images/new-course-icon-college.svg";
import AllCourseApi from "../../../apis/api/AllCourse";
import getFromCartApi from "../../../apis/api/GetFromCart";
import "./newNavBar.css";
// import Login from "../../Login/Login";
import { cartItemList, userAuth } from "../../../recoil/store";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { getCourse } from "../../../redux/slices/course.slice";
import { useLocation } from 'react-router-dom';

// import Paper from "@mui/material/Paper";
import { logoutAction } from "../../../redux/slices/auth.slices";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.development";
import DownwardArrow from "../../../assets/images/downward_arrow.png"
import DownwarWhitedArrow from "../../../assets/images/down-arrow-white.png"

const NavBar = (props) => {
  const [scroll, setScroll] = useState(false);
  const [allCourse, setAllCourse] = useState({});
  const [coursesByCategory, setCoursesByCategory] = useState({});
  const [loading, setLoading] = useState();
  const [cartData, setCartData] = useState();
  const [cartItem, setCartItem] = useRecoilState(cartItemList);
  const [isLogged, setIsLogged] = useRecoilState(userAuth);
  const [error, setError] = useState();
  const [courseValue, setCourse] = useState();
  const [courseData, setCourseData] = React.useState();
  const [cartCounting, setCartCount] = useState();
  const [y, setY] = useState([]);
  let dispatch = useDispatch();
  const [collapse_open, setcollapse_open] = React.useState(true);
  let location = useLocation();
  let newDate = new Date().getTime();

  const collapseHandler = () => {
    setcollapse_open(!collapse_open);
  };

  const [drawable, setDrawable] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  let { admin, isLogin } = useSelector((state) => state.AuthReducer);
  let { cartCount } = useSelector((state) => state.CartReducer);
  const logoutHandler = () => {
    dispatch(logoutAction());
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsLogged(false);
    toast.success("Logged Out Successfully", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const [user, setUser] = useState(false);
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   setOpen(false);
  //   setOpen(false);
  // };
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawable({ ...drawable, [anchor]: open });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  });
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setUser(true);
    } else {
      // console.log("false");
      setUser(false);
    }
  }, [user]);
  useEffect(() => {
    const getCartData = async () => {
      if (!cartData) {
        let data = await getFromCartApi(
          setCartData,
          setY,
          setLoading,
          setError
        );
        setCartCount(data?.length);
      }
    };
    getCartData();
    setCartItem(cartData);
  }, []);
  useEffect(() => {
    AllCourseApi(setAllCourse, setCoursesByCategory, setLoading);
  }, []);
  if (loading === true) {
    toast.error("Weak Network", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setLoading(false);
  }
  const changeCourse = (id) => {
    dispatch(getCourse({ id, setCourseData, setCourse, setLoading, setError, isLogin }));
  };

  const list = (anchor) => (
    <Box
      component="nav"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        p: 2,
      }}
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {admin && (
          <>
            <ListItem>
              <Avatar alt={admin?.name.toUpperCase()} src="authorImage/png" />
            </ListItem>
            <ListItem sx={{ pb: 0, pt: 0 }}>
              <ListItemText
                primary={admin.name[0].toUpperCase() + admin.name.substring(1)}
              />
            </ListItem>

            <ListItem sx={{ pt: 0 }}>
              <ListItemText primary={admin?.email} />
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem onClick={toggleDrawer(anchor, false)}>
          <HashLink to={`services/${'corporates'}`} className="sidebar-link">
            Services
          </HashLink>
        </ListItem>

        <ListItem onClick={toggleDrawer(anchor, false)}>
          <Link className="sidebar-link" to="/all-courses/all">
            Courses
          </Link>
        </ListItem>
        <ListItem onClick={toggleDrawer(anchor, false)}>
          <Link className="sidebar-link" to="/all-events">
            Events
          </Link>
        </ListItem>
        <ListItem onClick={toggleDrawer(anchor, false)}>
          <Link className="sidebar-link" to="/interview-questions">
            Interview Questions
          </Link>
        </ListItem>
        <ListItem onClick={toggleDrawer(anchor, false)}>
          <Link className="sidebar-link" to="/blogs">
            Blogs
          </Link>
        </ListItem>
        <ListItem onClick={toggleDrawer(anchor, false)}>
          <Link className="sidebar-link" to="/about-us">
            About Us
          </Link>
        </ListItem>
        <ListItem onClick={collapseHandler}>
          <ListItemText primary="Resources" className="sidebar-link" />
          {collapse_open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapse_open} unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/resources/resume">
                Resume Review
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/resources/counseling">
              Career Counseling
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/resources/discussion-forum">
              Discussion Forum
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/campus-ambassador">
              Campus Ambassador
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="placement-training">
              Placement Training
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/resources/counseling">
                Career Guidance
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/kids-coding">
                Kids Coding
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/olympiad">
              Olympiad
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/resources/excercise">
              Exercise
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/resources/editors">
              Tutorials
              </Link>
            </ListItem>
          </List>
        </Collapse>
        <ListItem onClick={collapseHandler}>
          <ListItemText primary="Contact Us" className="sidebar-link" />
          {collapse_open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapse_open} unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="organise-workshop">
                Organise Workshop
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/contact-us-for-hiring">
                Hire from Us
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 4 }} onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/contact-us-to-get-hired">
                Get Hired
              </Link>
            </ListItem>
          </List>
        </Collapse>
        {isLogin ? (
          <>
            <ListItem onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/my-courses">
                My Purchases
              </Link>
            </ListItem>
            <ListItem onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/my-cart">
                My Cart
              </Link>
            </ListItem>
            <ListItem onClick={toggleDrawer(anchor, false)}>
              <Link className="sidebar-link" to="/" onClick={logoutHandler}>
                Logout
              </Link>
            </ListItem>
          </>
        ) : (
          <ListItem onClick={toggleDrawer(anchor, false)}>
            
            {/* state={{ from: prevUrl }} */}
            <Link to={`/auth-user`} state={{ from: location.pathname }} className="sidebar-link">
              <button className="btn-grad btn-nav">LogIn / SignUp</button> 
            </Link>
          </ListItem>
        )}
      </List>
    </Box>
  );
  return (
    <>
      <AppBar className={scroll ? "navbar-background" : "bg-royal-transparent"}>
        <div className={scroll ? "hide-primary-navbar" : ""}>
          <PrimaryNavBar />
        </div>
        <Container
          maxWidth="lg"
          className="secondary-navbar"
          style={{ whiteSpace: "nowrap" }}
        >
          <Toolbar
            className="main-logo"
            style={{ paddingTop: "3px", width: "250px" }}
          >
            <Link to="/">
              <img src={scroll ? logoOnScroll : LogoWhiteBg} height="100%" width="250px" alt="Logo" />
            </Link>
          </Toolbar>
          <Toolbar>
            <Button
              onClick={toggleDrawer("left", true)}
              className="techvanto-navbar-menu-icon menu-icon"
            >
              <MenuIcon sx={scroll ? {color: 'var(--color-white)'}: {color: 'var(--color-black)'}} />
            </Button>
            <SwipeableDrawer
              anchor={"left"}
              open={drawable["left"]}
              onClose={toggleDrawer("left", false)}
              onOpen={toggleDrawer("left", true)}
            >
              {list("left")}
            </SwipeableDrawer>
            <Container className="navbar-category">
              <div className="menu-item">
                <div className="item">
                  <div className="dropdown">
                    <a className="menu-text">
                      <span
                        className={
                          scroll === false ? "color-black" : "color-white"
                        }
                      >
                        for Colleges
                        <img src={!scroll ? DownwardArrow : DownwarWhitedArrow} alt="arrow"
                          style={{
                            marginLeft: '4px',
                            width: '14px'
                          }}
                        />
                      </span>
                    </a>
                    <div className="dropdown-content-contact" 
                      style={{backgroundColor: '#f7f1f1',
                      boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',
                      borderRadius: '2%',
                      right: '-40px',
                      padding: '12px 16px',
                      border: '1px solid var(--color-border)'
                      }}>
                        <Link to="/all-courses/college">Courses</Link>
                        <Link to="/campus-ambassador">Campus Ambassador</Link>
                        <Link to="/olympiad">Olympiad</Link>
                        <Link to={`services/${'colleges'}`}>Services</Link>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="dropdown">
                    <a className="menu-text">
                      <span
                        className={
                          scroll === false ? "color-black" : "color-white"
                        }
                      >
                        for Schools
                        <img src={!scroll ? DownwardArrow : DownwarWhitedArrow} 
                        style={{marginLeft: '4px', width: '14px'}} alt="arrow" />
                      </span>
                    </a>
                    <div className="dropdown-content-contact" 
                      style={{
                        backgroundColor: '#f7f1f1',
                        boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',
                        borderRadius: '3%',
                        left: '-20px',
                        padding: '12px 24px',
                        border: '1px solid var(--color-border)'
                      }}>
                        <Link to="/all-courses/school">Courses</Link>
                        <Link to="/resources/counseling">Career Guidance</Link>
                        <Link to="/kids-coding">Kids Coding</Link>
                        <Link to={`services/${'schools'}`}>Services</Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <a className="menu-text" style={{marginLeft: '24px'}}>
                      <span
                        className={
                          scroll === false ? "color-black" : "color-white"
                        }
                      >
                        Resources
                        <img src={!scroll ? DownwardArrow : DownwarWhitedArrow} alt="arrow" style={{marginLeft: '4px', width: '14px'}} />
                      </span>
                    </a>
                    <div className="dropdown-content-contact" 
                      style={{
                        backgroundColor: '#f7f1f1',
                        boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',
                        borderRadius: '3%',
                        padding: '12px 24px',
                        left: '-10px',
                        border: '1px solid var(--color-border)'
                      }}>
                        <Link to="/resources/resume">Resume Review</Link>
                        <Link to="/resources/counseling">Career Counseling</Link>
                        <Link to="/resources/discussion-forum">Discussion Forum</Link>
                        <Link to="/resources/excercise">Excercise</Link>
                        <Link to="/resources/blogs">Blogs</Link>
                        <Link to="/resources/editors">Tutorials</Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <a className="menu-text" style={{marginLeft: '24px'}}>
                      <span
                        className={
                          scroll === false ? "color-black" : "color-white"
                        }
                      >
                        for Corporates
                        <img src={!scroll ? DownwardArrow : DownwarWhitedArrow} alt="arrow" style={{marginLeft: '4px', width: '14px'}} />
                      </span>
                    </a>
                    <div className="dropdown-content-contact" 
                      style={{
                        backgroundColor: '#f7f1f1',
                        boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',
                        borderRadius: '3%',
                        padding: '12px 24px',
                        left: '0px',
                        border: '1px solid var(--color-border)'
                      }}>
                        <Link to="placement-training">Placement Training</Link>
                        <Link to="hire-from-us">Hire From Us</Link>
                        <Link to="organise-workshop">Organise Workshop</Link>
                        <Link to={`services/${'corporates'}`}>Services</Link>
                    </div>
                  </div>
                  {/* <a href="/#services" className="menu-text">
                    <span
                      className={
                        scroll === false ? "color-white" : "color-black"
                      }
                    >
                      Services
                    </span>
                  </a> */}
                </div>
              </div>
            </Container>
            <div className="item user-aria" style={{ position: "relative" }}>
              {isLogin ? (
                <>
                  <Link to="/my-cart" style={{ marginRight: "30px" }}>
                    <IconButton aria-label="cart" className={scroll ? "color-white" : "color-full-black"}>
                      <Badge
                        badgeContent={cartCount ? cartCount : cartCounting}
                        color="error"
                      >
                        <ShoppingCartIcon fontSize="large" />
                      </Badge>
                    </IconButton>
                  </Link>
                  <div className="dropdown">
                    <span className="user-name">
                      Hey!&nbsp;
                      {admin.name.split(" ")[0].length < 7
                        ? admin.name[0].toUpperCase() + admin.name.split(" ")[0].substr(1,admin.name.split(" ")[0].length)
                        : admin.name[0].toUpperCase() + admin.name.split(" ")[0].substr(1, 6) + ".."}
                    </span>
                    <Link to="/">
                      <span className={scroll ? "color-white" : "color-full-black"}>
                        <Tooltip title={admin.name}>
                          <AccountCircleIcon fontSize="large" />
                        </Tooltip>
                      </span>
                    </Link>
                    <div
                      className="dropdown-content-contact"
                      style={{
                        right: "-20px",
                        top: "36px"
                      }}
                    >
                      <div className="dropdown-inner-container">
                        <div className="up-arrow" style={{right: "27px", top: "-6px"}}></div>
                        <Link to="/my-courses">My Purchases</Link>
                        <a style={{ color: "black" }} onClick={logoutHandler}>
                          Log Out
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Link
                    // onClick={handleClickOpen}
                    to="/auth-user"
                    state={{ from: location.pathname }}
                  >
                    <button className="btn-grad btn-nav">
                      <span
                        className={
                          scroll === false ? "color-white" : "color-black"
                        }
                      >
                        LogIn / SignUp
                      </span>
                    </button>
                  </Link>
                </>
              )}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default NavBar;
