let months = require('../data/months/month.json');
export const arrangeDate = (date) => {
    let day = new Date(date).getDate()-1;
    let month = new Date(date).getMonth();
    let year = new Date(date).getFullYear();
    if(day === 1 || day === 21 || day === 31){
        day = `${day}st`;
    }else if(day === 2 || day == 22){
        day = `${day}nd`;
    }else if(day === 3 || day === 23){
        day = `${day}rd`;
    }else{
        day = `${day}th`;
    }
    let finalDate = `${day} ${months[month].name}, ${year}`;
    return finalDate;
}