import * as React from "react";
import {useState} from 'react';
import { Box,Button,Typography,Checkbox } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import addResumeApi from "../../../src/apis/api/Resume";
import UploadOnS3 from "../../../src/apis/api/UploadOnS3";
import "./resume.css";
import { toast } from "react-toastify";
import {Link, useNavigate } from "react-router-dom";
import ResumeHeader from './ResumeHeader';
import Loader from "../../components/Loader";
import ButtonLoader from "../../assets/images/button_loader.gif";
import { useEffect } from "react";

const Input = styled("input")({
    display: "none",
  });
function Resume({redirectTo}){
    const [loader, setLoader] = useState(true);
    let { isLogin } = useSelector((state) => state.AuthReducer);
    const [name, validateName] = useState(false);
    const [email, validateEmail] = useState(false);
    const [phone, validatePhone] = useState(false);
    const [selectedFile, setSelectedFile] = useState( {image:{},});
    const [fileFieldError, setFileFieldError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [error, setError] = useState(false);
    const [fileLoader, setFileLoader] = useState(false);
    const [terms, setTerms] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [file, setFile] = useState(null);
    const [resumeError, setResumeError] = useState(true);
    const navigate = useNavigate();

    let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const [info, setInfo] = useState({
        name: "",
        email: "",
        phone: "",
        resume_link:"",
      });
      useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        
        if(user){
            setInfo({...info, phone: user.phone})
        }else{
            setInfo({...info, phone: ''})
        }
      },[])
      const formValidate = (event) => {
        if (event.name === "name") {
          setInfo({ ...info, name: event.value });
          if(event.value === "") return setNameError(true);
          setNameError(false);
        }
        if (event.name === "email") {
          setInfo({ ...info, email: event.value });
          if(event.value === "" || !event.value.match(emailValidate)) return setEmailError(true);
          setEmailError(false);
        }
        if (event.name === "phone") {
          setInfo({ ...info, phone: event.value });
          if(event.value !== "" && event.value.length === 10) return setPhoneError(false);
          setPhoneError(true);
        }
      }
      const handleUploadOnS3 = async(e) => {
        try {
            setFileLoader(true);
            const image = new FormData();
            image.append("file", e.target.files[0]);
            if(e.target.files[0]?.type === 'application/pdf' || 
                e.target.files[0]?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                setFile(e.target.files[0]?.name);
                const data = await UploadOnS3(image);
                setInfo({...info, resume_link: data?.data?.urls[0]})
                setFileLoader(false);
                setResumeError(false);
            }else{
                errorToast('Only pdf or doc files are allowed')
            }
            
        } catch (e) {
            console.log(e);
            setFileLoader(false);
        } finally {
            console.log("Finally called");
            setFileLoader(false);
        }
      };
    const handleRegister= async(event)=>{
        //console.log('submit')
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setError("");
        let body={
            name : data.get("name"),
            phone :data.get("phone"),
            email : data.get("email"),
        };
        if (!body.name || !body.phone || !body.email) {
            //setLoader(false);
            return setError("All Fields Are Required");
        }
        if(
            name === false &&
            phone === false &&
            email === false
        ){
            let res = await addResumeApi(body, setError, setLoader);
            if(res && res.status === 201 && res.data.message){
                //password-message code to be write here--;
                toast.success(res.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            }
        }        
    }
    const submitForm= async(e) =>{
        e.preventDefault();
        setLoader(true);
        if(!nameError && !phoneError && 
           !emailError && terms && !resumeError)
        {
            let res;
            setButtonLoader(true)
            res = await addResumeApi(info,setLoader);
            if(res){
                // console.log("res==========",res)
                setButtonLoader(false)
                if(res.statusCode === 200){
                    console.log("success");
                    let fileInfo = {
                        name: "",
                        email: "",
                        phone: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).phone : "",
                        resume_link:"",
                    }
                    setNameError(true);
                    setEmailError(true);
                    JSON.parse(localStorage.getItem('user')) ? setPhoneError(false) : setPhoneError(true);
                    setResumeError(true);
                    setFile(null);
                    setInfo(fileInfo);
                    toast.success(res.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }else{
                    toast.error('Something went wrong.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }
            }else{
                setButtonLoader(false);
                toast.error('Something went wrong.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            }
        }else{
            if(nameError) return errorToast('Name required.');
            if(phoneError) return errorToast('Invalid phone number entered.');
            if(emailError) return errorToast('Invalid email entered.');
            if(!terms) return errorToast('Agree the policy of Techvanto Academy.');
            if(resumeError) return errorToast('Upload your resume.');
            
        }
    }
    const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
return(
    <section className="resume-section">
        <ResumeHeader />
        <div className="resume-box">
            <div className="file-upload">
                <Button component="div" className="upload-btn">
                        <label for="files" className="upload-icon resume-upload-aria">
                            <UploadFileIcon sx={{fontSize:"40px",marginBottom:"24%"}}/>  
                        </label>
                        <input id="files" type="file" style={{visibility:"hidden"}}
                        onChange={handleUploadOnS3} accept=".doc,.docx,.pdf"
                        className={selectedFile ? "upload-res-text" : "upload-res-text disabled"}/>
                        {file && <p className="file-name select-file-name">{file}</p>}
                        {fileLoader && 
                        <div className="upload-loader">
                            <img src={ButtonLoader} width="80" alt="" />
                        </div>
                        }
                        <p className="upload-res-text upload-text-style">Upload my resume</p>                    
                    </Button>
                <br/>
                {fileFieldError && (
                <p style={{ marginTop: "0", color: "red" }}>
                    Field Required or Upload File{" "}
                </p>
                )}
            </div>
            <div>
                <form onSubmit={submitForm} className="right-panel-form">
                    <div>
                        <div className="form-group">
                            <label className="resume-form-label">Name</label>
                            <input 
                            type="text" 
                            name="name" 
                            className="form-control resume-input-box" 
                            value={info.name}
                            onChange={((e)=>formValidate(e.target))}
                            error={nameError}
                            required/>
                        </div>
                    </div>
                    <br/>
                    <div>
                        <div className="form-group">
                            <label className="resume-form-label">Contact Number</label>
                            <input 
                            type="number"
                            name="phone" 
                            className="form-control resume-input-box" 
                            value={info.phone}
                            onChange={((e)=>formValidate(e.target))}
                            error={phoneError}
                            required/>
                        </div>
                    </div>
                    <br/>
                    <div>
                        <div className="form-group">
                            <label className="resume-form-label">Email id</label>
                            <input 
                            type="email" 
                            name="email" 
                            className="form-control resume-input-box" 
                            value={info.email}
                            onChange={((e)=>formValidate(e.target))}
                            error={emailError} 
                            //helperText={name ? "Enter Your Name." : ""}
                            required/>
                        </div>
                    </div>
                    <br/>
                    <div
                        style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        }}
                    >
                        <span className="terms-size">
                        <Checkbox onChange={(e)=>{
                            setTerms(e.target.checked)
                        }}/>
                            I accept all the <Link to="/placement-policy" target="_blank">Policies of Techvanto Academy</Link>
                        </span>           
                    </div>
                    <br/>
                    <div>
                    <center> <button type="submit"
                    className="btn-grad-resume resume-upload-btn"
                    style={buttonLoader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-primary)'}}
                        disabled={buttonLoader}
                    >
                        {buttonLoader ? <img src={ButtonLoader} width="80" alt="" /> : 'Submit'}
                    </button></center>
                    </div>
                    {/* {isLogin ? (
                    <>
                    <div className="col-lg-8">
                    <center> <button 
                    className="btn-grad-resume"
                    style={loader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-secondary)'}}
                        disabled={loader ? true : false}
                    >Submit
                    </button></center>
                    </div>
                    </>
                    ):(
                    <>
                    <div className="col-lg-8">
                    <center> <button 
                    className="btn-grad-resume"
                    onClick={handleRegister}
                    >Register
                    </button></center>
                    </div>
                    </>
                    )} */}
                </form>
            </div>
        </div>
        <div className="resume-content">
        <center> <h2 style={{fontWeight: "bolder"}}>How it works</h2></center>
            <div className="description-box">
                    <div className="content-div">
                        <Typography 
                        sx={{fontSize:"48px",fontWeight:"400"}}>
                            01
                        </Typography>
                        <p className="min-text" style={{fontSize:"18px"}}>Upload your resume</p>
                        <p>Upload your resume (PDF or Doc File) and one of our resume experts will review it.</p>
                    </div>
                    <div className="content-div">
                    <Typography 
                        sx={{fontSize:"48px",fontWeight:"400"}}>
                            02
                        </Typography>
                        <p className="min-text" style={{fontSize:"18px"}}>Wait while we analyze</p>
                        <p>Once your resume is received, 
                        our resume experts will give you a comprehensive 
                        analysis getting your one step closer to your 
                        dream job.</p>
                    </div>
                    <div className="content-div">
                    <Typography 
                        sx={{fontSize:"48px",fontWeight:"400"}}>
                            03
                        </Typography>
                        <p className="min-text" style={{fontSize:"18px"}}>View your results</p>
                        <p>Your analysis and critique is now ready
                            with actionable insights that can help you
                            showcase what recruiters looking.
                        </p>
                    </div>    
            </div>
        </div>
    </section>
)
}
export default Resume;