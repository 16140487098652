/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState, useEffect } from "react";
import { NavHashLink } from "react-router-hash-link";
import "./editor.css";
import RightArrow from "../../assets/images/right-arrow.png";
import SaveFileImage from "../../assets/images/saving_file.png";
import HtmlViewImage from "../../assets/images/html_view.png";
import Editor from "@monaco-editor/react";
import getEditorsTopicApi from "../../apis/api/GetEditorsTopics";
import getEditorsSubTopicApi from "../../apis/api/GetEditorsSubTpoics";
import getEditorsHtmlInfoApi from "../../apis/api/GetHtmlEditorInfo";
import ReactHtmlParser from "react-html-parser";
import Loader from "../../assets/images/button_loader.gif";
import {isEmpty} from '../../utils/isObjectEmpty';
import compileCodeApi from "../../apis/api/CodeCompile";
import Select from 'react-select';
import { toast } from "react-toastify";
const menuItem = [
    {
        name: "HTML",
        isActive: true,
    },
    {
        name: "Python",
        isActive: false,
    },
    {
        name: "C++",
        isActive: false,
    },
    {
        name: "AI",
        isActive: false,
    },
    {
        name: "IOT",
        isActive: false,
    }
]
const languages = [
    { value: "c", label: "C" },
    { value: "cpp", label: "C++" },
    { value: "python", label: "Python" },
    { value: "java", label: "Java" },
];
const EditorTopMenu = (props) => {
  const [menuList, setMenuList] = useState(menuItem);
  const [subTopics, setSubTopics] = useState([]);
  const [userTheme, setUserTheme] = useState("vs-dark");
  const [userLang, setUserLang] = useState("python");
  const [userCode, setUserCode] = useState('');
  const [userInput, setUserInput] = useState("");
  const [fontSize, setFontSize] = useState(20);
  const [activeTab, setActiveTab] = useState('HTML');
  const [activeSubTopic, setActiveSubTopic] = useState('home');
  const [subContentLoader, setSubContentLoader] = useState(false);
  const [userOutput, setUserOutput] = useState('');
  const [compilerLoader, setCompilerLoader] = useState(false);
//   userTheme={userTheme} setUserTheme={setUserTheme}
  var html = '<!DOCTYPE html><html><body><h1>My First Heading</h1><p>My first paragraph.</p></body></html>'
  const [htmlInfo, setHtmlInfo] = useState({});
  const options = {
    fontSize: fontSize
  }
  useEffect(() => {
    getEditorTopic();
    getEditorsSubTopics(activeTab);
    getHtmlInfo("HTML", "home");
  },[])

  const getEditorTopic = async() => {
    let topics = await getEditorsTopicApi();
    if(topics){
        let array = [];
        topics?.data?.map((item, index) => {
            if(index === 0){ 
                setActiveTab(item);
                getEditorsSubTopics(item);
            };
            array.push({name: item, isActive: index === 0 ? true : false})
        })
        setMenuList([...array]);
    }
  }
  const getEditorsSubTopics = async(tab) => {
    let res = await getEditorsSubTopicApi(tab);
    console.log("res====",res);
    if(res){
        let array = [];
        res?.data?.map((item, index) => {
            index === 0 && setActiveSubTopic(item);
            console.log("checking",item)
            index === 0 && getHtmlInfo(tab, item?.split(" ")[1].toLowerCase());
            array.push({name: item, isActive: index === 0 ? true : false})
        })
        setSubTopics([...array]);
    }
  }
  const menuClicked = (item) => {
    let array = menuList;
    let index = array.indexOf(item);
    setActiveTab(item?.name);
    getEditorsSubTopics(item?.name);
    if(index > -1){
        item.isActive = true;
        array[index] = item;
        for(let i = 0; i < array.length; i++){
            if(index !== i){
                array[i].isActive = false;
            } 
        }
    }
    setMenuList([...array])
  }
  const subMenuClicked = (item) => {
    let array = subTopics;
    let index = array.indexOf(item);
    setActiveSubTopic(item.name);
    // setActiveSubTopic(item.name.split(" ")[1]);
    getHtmlInfo(activeTab, item.name.split(" ")[1].toLowerCase())
    if(index > -1){
        item.isActive = true;
        array[index] = item;
        for(let i = 0; i < array.length; i++){
            if(index !== i){
                array[i].isActive = false;
            } 
        }
    }
    setSubTopics([...array])
  }
  const getHtmlInfo = async(topic, subtopic) => {
    setSubContentLoader(true);
    let res = await getEditorsHtmlInfoApi(topic, subtopic);
    if(res){
        setHtmlInfo(res?.data);
        setSubContentLoader(false)
    }
  }
  const compile = async() => {
    setCompilerLoader(true);
    if (userCode === ``) {
        setCompilerLoader(false);
        return
    }
    let body = {
        language: userLang,
        input: userInput,
        code: userCode
    }
    let res = await compileCodeApi(body);
    console.log("res====",res);
    if(res){
        setCompilerLoader(false);
        res?.data?.success ? setUserOutput(res?.data?.output) : setUserOutput(res?.data?.error)
    }else{
        errorToast("Something went Wrong!");
        setCompilerLoader(false);
    }
  }
  const onNextPrevClicked = (actionType) => {
    let array = subTopics;
    let filter = array.filter((item) => {
        return item.name === activeSubTopic
    })
    if(filter.length > 0){
        let index = array.indexOf(filter[0]);
        if(actionType === "next"){
            if(index !== array.length-1){
                array[index+1].isActive = true;
                getHtmlInfo(activeTab, array[index+1].name.split(" ")[1].toLowerCase())
                setActiveSubTopic(array[index+1].name);
                for(let i = 0; i < array.length; i++){
                    if(index+1 !== i){
                        array[i].isActive = false;
                    } 
                }
            }
        }else{
            if(index !== 0){
                array[index-1].isActive = true;
                setActiveSubTopic(array[index-1].name);
                getHtmlInfo(activeTab, array[index-1].name.split(" ")[1].toLowerCase())
                for(let i = 0; i < array.length; i++){
                    if(index-1 !== i){
                        array[i].isActive = false;
                    } 
                }
            }
        }
        setSubTopics([...array])
    }
    
  }

  const errorToast = (message) => {
    toast.warn(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
  }
  return (
    <>
        <div className="editors-top-menu">
            <ul className="editors-menu-list">
                {menuList.map((item, index) => (
                    <li 
                        key={index} 
                        className={item.isActive && 'active-editor-item'}
                        onClick={() => menuClicked(item)}>{item.name}</li>
                ))}
            </ul>
        </div>
        <div className="editors-main-content">
            <div className="editors-side-menu">
                <h6 className="editors-side-menu-heading">HTML Tutorial</h6>
                <ul>
                    {subTopics?.map((item, index) => (
                        <li 
                            key={index} 
                            className={item.isActive && 'active-editor-sidemenu-item'}
                            onClick={() => subMenuClicked(item)}>{item.name}</li>
                    ))}
                </ul>
            </div>
            <div className="editors-content-box">
                <ButtonArea 
                    isBorder={true}
                    nextClick={() => {onNextPrevClicked("next")}} 
                    prevClick={() => {onNextPrevClicked("prev")}} />
                {!subContentLoader ? 
                    (!isEmpty(htmlInfo) ? ReactHtmlParser(htmlInfo) : 
                    <div style={{
                        textAlign:'center',
                        marginTop: '12px'
                    }}>
                        <p>No Content Available</p>
                    </div>
                    ) 
                    : 
                    <div style={{
                        textAlign:'center',
                    }}>
                        <img src={Loader} width="140"/>
                    </div>
                }
                {/* <div>
                    <div
                        style={{
                            padding: '12px 0px 0px 0px',
                            borderBottom: '1px solid var(--color-border)'
                        }}
                    >
                        <h5 style={{fontWeight: 'bold'}}>HTML Documents</h5>
                        <p>
                            HTML documents start with a Doc type declaration and the document itself will begin using {'<html>'} and end with {'</html>'}.
                            The content of the web page that needs to be shown to the user it will be written between {'<body>'} and {'</body>'}.<br/><br/>
                            Example:<br />
                            {'<!DOCTYPE html>'}<br/>
                            {'<html>'}<br/>
                            {'<body>'}<br/>
                            {'<h1>Heading</h1>'}<br/>
                            {'<p>Paragraph</p>'}<br/>
                            {'</body>'}<br/>
                            {'</html>'}

                        </p>
                    </div>
                    <div
                        style={{
                            padding: '12px 0px 0px 0px'
                        }}>
                        <h6
                            style={{
                                fontWeight: 'bold'
                            }}
                        >{'<!DOCTYPE >'} Declaration</h6>
                        <p>
                            {'<!DOCTYPE >'} declaration defines the document type, and allows the browser to correctly identify the contents of the web page 
                            that need to be displayed.<br/><br/>
                            This declaration must only appear before any HTML tags at the top of the page and only once in the document. It is not case 
                            sensitive.<br/><br/>
                            The declaration for HTML5 is:  <b>{'<!DOCTYPE html>'}</b>
                        </p>
                        <div
                            style={{
                                padding: '12px 0px 0px 0px',
                                borderBottom: '1px solid var(--color-border)'
                            }}
                        >
                            <h5 style={{fontWeight: 'bold'}}>HTML Headings</h5>
                            <p>
                                To define HTML headings we use the tags <b>{'<h1>'}</b> through <b>{'<h6>'}</b>.<br/>
                                {'<h1>'} defines the most important heading. {'<h6>'} defines the least important heading.<br/>
                                Example:<br/><br/>
                            </p>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <img src={'https://tv-backend-s3.s3.amazonaws.com/html-image-1.jpeg'} />
                            </div><br/>
                        </div>
                        <div
                            style={{
                                padding: '12px 0px 0px 0px',
                                borderBottom: '1px solid var(--color-border)'
                            }}
                        >
                            <h5 style={{fontWeight: 'bold'}}>HTML Paragraphs</h5>
                            <p>
                                HTML paragraphs are defined using the <b>{'<p>'}</b> tag.<br/><br/>
                                Example:<br/><br/>
                            </p>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <img src={'https://tv-backend-s3.s3.amazonaws.com/html-image-1.jpeg'} />
                            </div><br/>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <img src={'https://tv-backend-s3.s3.amazonaws.com/html-image-1.jpeg'} />
                            </div><br/>
                        </div>
                        <div
                            style={{
                                padding: '12px 0px 0px 0px',
                                borderBottom: '1px solid var(--color-border)'
                            }}
                        >
                            <h5 style={{fontWeight: 'bold'}}>HTML Links</h5>
                            <p>
                                Links within HTML are defined using the {'<a>'} tag. The <b>href</b> attribute is what specifies the link's 
                                destination.<br/><br/>
                                Example:<br/><br/>
                                {'<a href=" https://techvantoacademy.com/ ">This link redirects to Techvanto Academy.</a>'}
                            </p>
                        </div>
                        <div
                            style={{
                                padding: '12px 0px 0px 0px',
                                borderBottom: '1px solid var(--color-border)'
                            }}
                        >
                            <h5 style={{fontWeight: 'bold'}}>HTML Images</h5>
                            <p>
                                HTML images are defined with the {'<img>'} tag.<br/>
                                The source file (src), alternative text (alt), width, and height are provided as attributes.<br/><br/>
                                Example: {'<img src="image.jpg" alt="Image" width="1280" height="720">'}
                            </p>
                        </div>
                        <div
                            style={{
                                padding: '12px 0px 0px 0px',
                                borderBottom: '1px solid var(--color-border)'
                            }}
                        >
                            <h5 style={{fontWeight: 'bold'}}>HTML Source</h5>
                            <p>
                                You can also view a page source by right clicking an HTML page and selecting the “View Page Source” option on your 
                                browser. This will allow you to look at the HTML source code of the page in a different window.<br/><br/>
                                To see what elements the page is made up of you can directly right click on and element or blank area and chose 
                                “Inspect Element”. You can then on the newly visible panel now edit the HTML or CSS elements and styles in real time.<br/><br/>
                            </p>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <img src={'https://tv-backend-s3.s3.amazonaws.com/html-image-1.jpeg'} />
                            </div><br/>
                        </div>
                    </div>
                </div> */}
                {/* <div>
                    <div
                        style={{
                            padding: '12px 0px 0px 0px',
                            borderBottom: '1px solid var(--color-border)'
                        }}
                    >
                        <h5 style={{fontWeight: 'bold'}}>HTML Editors</h5>
                        <p>A simple text editor is all you need to learn HTML.</p>
                    </div>
                    <div
                        style={{
                            padding: '12px 0px 0px 0px'
                        }}>
                        <h6
                            style={{
                                fontWeight: 'bold'
                            }}
                        >Learn HTML Using Notepad or TextEdit</h6>
                        <p>Web pages can be created and modified by using professional HTML editors. However, for learning HTML we 
                            recommend a simple text editor like Notepad (PC) or TextEdit (Mac). We believe that using a simple text 
                            editor is a good way to learn HTML.
                        </p>
                        <p style={{
                            textDecoration: 'underline',
                            fontWeight: 'bold'
                        }}>
                            Follow the steps below to create your first web page with Notepad or TextEdit.
                        </p>
                        <div style={{padding: '24px', borderBottom: '1px solid var(--color-border)'}}>
                            <h6 className="editors-content-main-head" style={{
                                color: 'var(--color-primary)',
                                fontWeight: 'bold'
                            }}>Open Notepad (PC)</h6>
                            <p style={{
                                fontWeight: 'bold'
                            }}>Windows 8 or later:</p>
                            <p>Open the Start Screen (the window symbol at the bottom left on your screen). Type Notepad.</p>
                            <p style={{
                                fontWeight: 'bold'
                            }}>Windows 7 or earlier:</p>
                            <p>{`Open Start > Programs > Accessories > Notepad`}</p>
                        </div>
                        
                        <div style={{padding: '24px', borderBottom: '1px solid var(--color-border)'}}>
                            <h6 style={{
                                color: 'var(--color-primary)',
                                fontWeight: 'bold'
                            }}>Write some HTML</h6>
                            <p style={{
                                fontWeight: 'bold'
                            }}>Write or copy the following HTML code into Notepad:</p>
                            <div style={{
                                background: 'var(--color-grey-box)',
                                padding: '12px',
                                borderRadius: '4px'
                            }}>
                                <div style={{
                                    background: 'var(--color-white)',
                                    position: 'relative'
                                }}>
                                    <div style={{
                                        background: 'var(--color-primary)',
                                        width: '8px',
                                        height: '100%',
                                        minHeight: '100%',
                                        position: 'absolute'
                                    }}></div>
                                    <div style={{marginLeft: '24px', padding: '12px 0'}}>
                                        {'<!DOCTYPE html>'}<br/>
                                        {'<head>'}<br/>
                                        {'</head>'}<br/>
                                        {'<body>'}<br/>
                                        {'<h1>My first heading</h1>'}<br/>
                                        {'<p>My first paragraph</p>'}<br/>
                                        {'</body>'}<br/>
                                        {'</html>'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{padding: '24px', borderBottom: '1px solid var(--color-border)'}}>
                            <h6 style={{
                                color: 'var(--color-primary)',
                                fontWeight: 'bold'
                            }}>Save the html page</h6>
                            <p>Save the file on your computer. Select <b>File {`>`} Save as</b> in the <b>Notepad menu</b></p>
                            <p>Name of the file <b>{`"index.htm"`}</b> and set the encoding to <b>UTF-8</b> (which 
                            is the preferred in HTML files)</p>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <img src={'https://tv-backend-s3.s3.amazonaws.com/html-image-1.jpeg'}/>
                            </div>
                        </div>
                        <div style={{padding: '24px', borderBottom: '1px solid var(--color-border)'}}>
                            <h6 style={{
                                color: 'var(--color-primary)',
                                fontWeight: 'bold'
                            }}>View the HTML Page in Your Browser</h6>
                            <p>Open the saved HTML file in your favorite browser (double click on the file, 
                                or right-click - and choose "Open with").</p>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <img src={'https://tv-backend-s3.s3.amazonaws.com/html-image-2.jpeg'}/>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div>
                    <div
                        style={{
                            padding: '12px 0px 0px 0px',
                            borderBottom: '1px solid var(--color-border)'
                        }}
                    >
                        <h5 style={{fontWeight: 'bold'}}>What is HTML?</h5>
                        <p>
                            HTML stands for HyperText Markup Language and is the standard markup language used for creating web pages and making them 
                            functional by combining the use of Hypertext and Markup language. Hypertext defines the link between the web pages and 
                            Markup language defines the text document within the tag that defines the structure of web pages.
                        </p>
                    </div>
                    <div
                        style={{
                            padding: '12px 0px 0px 0px'
                        }}>
                        <h6
                            style={{
                                fontWeight: 'bold'
                            }}
                        >Learn more about HTML</h6>
                        <p>
                            It contains Tags and Attributes and a series of elements that are used to design and display content on a web page. 
                            Hyperlinks are also available that may be used to link multiple. A Web page can be either static or dynamic but while 
                            solely using, only static web pages may be created.
                        </p>
                        <div style={{padding: '24px', borderBottom: '1px solid var(--color-border)'}}>
                            <h6 style={{
                                color: 'var(--color-primary)',
                                fontWeight: 'bold'
                            }}>Basic Page Structure</h6>
                            <p style={{
                                fontWeight: 'bold'
                            }}>HTML pages follow a basic structure as shown below:</p>
                            <div style={{
                                background: 'var(--color-grey-box)',
                                padding: '12px',
                                borderRadius: '4px',
                                marginBottom: '12px'
                            }}>
                                <div style={{
                                    background: 'var(--color-white)',
                                    position: 'relative',
                                }}>
                                    <div style={{
                                        background: 'var(--color-primary)',
                                        width: '8px',
                                        height: '100%',
                                        minHeight: '100%',
                                        position: 'absolute'
                                    }}></div>
                                    <div style={{
                                        marginLeft: '24px', 
                                        padding: '12px 0'}}>
                                        {'<!DOCTYPE >'}<br/>
                                        {'<html>'}<br/>
                                        {'<head>'}<br/>
                                        {'<title>Web page title</title>'}<br/>
                                        {'</head>'}<br/>
                                        {'<body>'}<br/>
                                        {'<h1>My first heading</h1>'}<br/>
                                        {'<p>My first paragraph</p>'}<br/>
                                        {'</body>'}<br/>
                                        {'</html>'}
                                    </div>
                                </div>
                            </div>
                            <p><b>{'<!DOCTYPE >'}</b> declaration defines the document type and instructs the browser about the markup language and 
                                HTML version.
                            </p>
                            <p>
                                <b>{'<html>'}</b> element defines the root element of an HTML page.
                            </p>
                            <p>
                                <b>{'<head>'}</b> element defines the head of the document and contains metadata about the HTML page which is not visible on the 
                                    front-end.
                            </p>
                            <p>
                                <b>{'<title>'}</b> element defines the title for the HTML page and appears in the browser's title.
                            </p>
                            <p>
                                <b>{'<body>'}</b> element defines the document's body. It is what contains all visible content, such as headings, paragraphs, 
                                    images, hyperlinks, tables, lists, etc..
                            </p>
                            <p>
                                <b>{'<h1>'}</b> element defines a large heading.
                            </p>
                            <p>
                                <b>{'<p>'}</b> element defines a paragraph.
                            </p>
                        </div>
                        <div
                            style={{
                                padding: '12px 0px 0px 0px',
                                borderBottom: '1px solid var(--color-border)'
                            }}
                        >
                            <h5 style={{fontWeight: 'bold'}}>HTML Elements</h5>
                            <p>
                                An HTML file is made of elements. They are responsible for and define content that is visible on the web 
                                pages. An element in HTML usually consists of a start tag {'<tag name>'}, close tag {'</tag name>'} and 
                                content inserted between them. All in all, an element is the amalgamation of start tags, attributes, 
                                end tags and the content between them.<br/><br/>
                                Example:<br />
                                {'<tagname>'} Content goes here... {'</tagname>'}<br />
                                {'<h1>'} Heading{'</h1>'}<br />
                                {'<p>'}Paragraph{'</p>'}<br /><br/>
                                Some elements do not have content and end tag such elements are known as Void elements or empty elements or 
                                self-closing elements.
                            </p>
                        </div>
                        <div
                            style={{
                                padding: '12px 0px 0px 0px',
                                borderBottom: '1px solid var(--color-border)'
                            }}
                        >
                            <h5 style={{fontWeight: 'bold'}}>Tags and Attributes</h5>
                            <p>
                                Tags and attributes are the basis of HTML and perform different functions while still working together.<br/>
                                <b>Tags</b> markup the start of an element and are usually enclosed in angle brackets.<br/><br/>
                                Example: {'<h1>'}<br /><br/>
                                In order to function tags must usually be opened and closed but there are exceptions that do not require tags to be 
                                closed called void elements.<br /><br/>
                                Example: {'<br>'} & {'<hr>'}<br /><br/>
                                <b>Attributes</b> define the behaviour of the element and enclose additional pieces of information. Attributes are found in 
                                the opening tag where additional info is given to that particular tag.<br /><br/>
                                An example of an attribute is:<br />
                                {'<img src="computer.jpg" alt="A photo of a computer.">'}<br />
                                As seen above, the attributes of the {'<img>'} tag are the image source (src) and the alt text (alt).
                            </p>
                        </div>
                    </div>
                </div> */}
                {/* <div style={{marginTop: '24px', marginBottom: '52px'}}>
                    <h5 style={{fontWeight: 'bold'}}>Techvanto Online Editor - “Try It Yourself”</h5>
                    <p>With our free online editor, you can edit the HTML code and view the result in your browser.</p>
                    <button className="editors-try-yourself">
                        Try it yourself
                        <img src={RightArrow} width="12" style={{marginLeft: '4px'}} />
                        <img src={RightArrow} width="12" style={{marginLeft: '-4px'}}/>
                    </button>
                </div> */}
                <div style={{
                    marginTop: '24px',
                }}>
                    <ButtonArea 
                        isBorder={false} 
                        nextClick={() => {onNextPrevClicked("next")}} 
                        prevClick={() => {onNextPrevClicked("prev")}}/>
                </div>
                <div className="compiler-aria" style={{
                    marginTop: '20px'
                }}>
                    <CompilerNavbar
                        userLang={userLang} setUserLang={setUserLang}
                        userTheme={userTheme} setUserTheme={setUserTheme}
                        fontSize={fontSize} setFontSize={setFontSize}
                    />
                    <div className="main">
                        <div className="left-container">
                            <Editor
                                options={options}
                                // height="calc(100vh - 50px)"
                                // width="100%"
                                theme={userTheme}
                                language={userLang}
                                defaultLanguage="python"
                                defaultValue="# Enter your code here"
                                onChange={(value) => { setUserCode(value) }}
                            />
                            <button className="run-btn" 
                                onClick={() => compile()}
                                >
                                Run
                            </button>
                        </div>
                        <div className="right-container">
                            <h4>Input:</h4>
                            <div className="input-box">
                                <textarea id="code-inp" 
                                onChange={(e) => setUserInput(e.target.value)}
                                >
                                </textarea>
                            </div>
                            <h4>Output:</h4>
                            {compilerLoader ? (
                                <div className="spinner-box">
                                <img src={Loader} alt="Loading..." />
                                </div>
                            ) : (
                                <div className="output-box">
                                <pre>{userOutput}</pre>
                                <button onClick={() => {setUserOutput("")}}
                                    className="clear-btn">
                                    Clear
                                </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

const ButtonArea = (props) => {
    console.log(props.isBorder)
    return (
        <div className={'editors-content'} >
            <div>
                <button className="editors-btn" onClick={() => {props.prevClick()}}>
                    <img src={RightArrow} width="12" style={{marginRight: '4px', transform: 'rotate(180deg)'}} />
                    Previous
                </button>
            </div>
            <div>
                <button className="editors-btn" onClick={() => {props.nextClick()}}>
                    Next
                    <img src={RightArrow} width="12" style={{marginLeft: '4px'}} />
                </button>
            </div>
        </div>
    )
}

const CompilerNavbar = ({userLang, setUserLang, userTheme,
    setUserTheme, fontSize, setFontSize}) => {
    const languages = [
        { value: "c", label: "C" },
        { value: "cpp", label: "C++" },
        { value: "python", label: "Python" },
        { value: "java", label: "Java" },
    ];
    const themes = [
        { value: "vs-dark", label: "Dark" },
        { value: "light", label: "Light" },
    ]
    return (
        <div className="navbar-box">
            <h1 style={{fontSize: fontSize}}>Code Compiler</h1>
            <Select options={languages} value={userLang}
                    onChange={(e) => setUserLang(e.value)}
                    placeholder={userLang} />
            <Select options={themes} value={userTheme}
                    onChange={(e) => setUserTheme(e.value)}
                    placeholder={userTheme} />
            <label>Font Size</label>
            <input type="range" min="18" max="30"
                value={fontSize} step="2"
                onChange={(e) => { setFontSize(e.target.value)}}/>
        </div>
    )
}
export default EditorTopMenu;
