import * as React from 'react';
import { Container} from "@mui/material";
import "./Banner.css";
import {Link} from 'react-router-dom';


const BannerHome=(data)=>{
return(
    <>
    <Container style={{maxWidth:"100%"}}>                 
            <div style={{display:'flex', justifyContent: 'center'}}>
            <div className="banner-poster">
                  <div style={{display:'flex',justifyContent:'space-between',width:'100%',}}>
                   
                    {data.data.redirect_info.redirect_type==='event' && 
                    <Link to={`/event/${data.data.redirect_info.event_id}`}>
                    <img src={`${data.data.bannerUrl}`} alt="bannerImage" style={{borderRadius:"8px"}}/>
                    </Link>}
                    {data.data.redirect_info.redirect_type==='course' && 
                    <Link to={`/courses/${data.data.redirect_info.course_id}`}>
                    <img src={`${data.data.bannerUrl}`} alt="bannerImage" style={{borderRadius:"8px"}}/>
                    </Link>}
                    {data.data.redirect_info.redirect_type==='custom' && 
                    <Link to={`${data.data.redirect_info.custom_url}`}>
                    <img src={`${data.data.bannerUrl}`} alt="bannerImage" style={{borderRadius:"8px"}}/>
                    </Link>}
                  </div>                                
            </div> 
            </div>
        </Container>
    </>
)
}

export default BannerHome;