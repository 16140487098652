import React from "react";
//import { useEffect } from "react";
import { Container } from "@mui/material";
import BasicModal from "../basicModal/BasicModal";
import "./contentHeaderImage.css";
//import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
//import { useIsMobile } from "../../utils/mobileScreen";

const ContentHeaderImage = (props) => {
  //const isMobile = useIsMobile();
  // useEffect(() => {
  //   window.onload = function() {
  //     document.getElementById("home-header-text").style.opacity = '1';
  //     document.getElementById("home-header-text").style.marginLeft = '50px';
  //   };
  // },[])
  return (
    <div
      className="techvanto-content-header-image"
      style={{ background: `var(--color-primary) url(${props.background}) no-repeat`, backgroundSize: 'cover'}}
    >
      <Container>
        <div className="techvanto-header-content">
          {/* <motion.div
              initial={[{ opacity: 0 },isMobile ? {marginLeft: 0} : {marginLeft: 0}]}
              animate={[{ opacity: 1 }, isMobile ? {marginLeft: 0} : {marginLeft: '32px'}]}
              transition={{ duration: 1 }}
            //  style={{ opacity: 1, scale: 0.5 }}
          > */}
            <p
              style={{ cursor: "default" }}
              className="techvanto-campus-ambassador-right-content header-text"
            >
              <span className="large-text">{props.title}</span>
              <div style={{ 
                lineHeight: "normal", 
                color: 'var(--color-black)',
                }} className="small-heading">
                <span className="small-text" style={{
                  fontStyle: 'italic'
                }}>{props.overview} </span>
                <br />
              </div>
              <br />
              <div>
                <BasicModal
                  text="Book Demo ►"
                  name="Home"
                  sx={{ color: "white" }}
                ></BasicModal>
              </div>
            </p>
          {/* </motion.div> */}
        </div>
        <div>
          <img src={props.headerMainImage} className="header-main-image"  />
        </div>
      </Container>
    </div>
  );
};

export default ContentHeaderImage;
