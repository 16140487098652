import React from "react";
import { useState } from "react";
import { Typography, Container } from "@mui/material";
import HeaderImage from '../../assets/images/scholarship-header.png';
import TestConductImage from '../../assets/images/test-conduct.png';
import './internScholarshipTest.css';
import CertificateImage from "../../assets/images/scholarshipTest/certificate.png";
import PaidInternImage from "../../assets/images/scholarshipTest/paid-intern.png";
import PlacementImage from "../../assets/images/scholarshipTest/placement.png";
import PrizesAndDiscountsImage from "../../assets/images/scholarshipTest/prizes-and-discounts.png";
import SummerInternImage from "../../assets/images/scholarshipTest/summer-intern.png";
import ArchitectPana from '../../assets/images/architect-pana.png';
import BookDemo from "../../components/BookDemo/BookDemo";
import addToCartApi from "../../apis/api/AddToCart";
import { toast } from "react-toastify";
import ButtonLoader from "../../assets/images/button_loader.gif";
import getFromCartApi from "../../apis/api/GetFromCart";
import { useSelector, useDispatch } from "react-redux";
import { cartAction } from "../../redux/slices/cart.slice";
import { logoutAction } from "../../redux/slices/auth.slices";
import { useNavigate, useLocation } from "react-router-dom";
import LoginPopup from "../Generic/LoginPopup/LoginPopup";

const InternScholarshipTest = ({event, isEventBaught, months, days}) => {
    const [loader, setLoader] = useState(false);
    const [cartData, setCartData] = useState();
    const [y, setY] = useState([]);
    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);
    let { isLogin } = useSelector((state) => state.AuthReducer);
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let location = useLocation();

    let first = [1, 21, 31];
    let second = [2, 22];
    let third = [3, 23];
    const addToCart = async (id) => {
        setLoader(true);
        let body = {
          course_type: "event",
          event_name: event.name,
          event_description: event.description,
          event_image: event.thumbnail,
          event_date: event.startDate,
          price: event.price ? event.price : 0,
          event_id: id,
        };
        let message = await addToCartApi(body, setLoader);
        if (message === "Item Already Added") {
          toast.warn("Event already added ", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (message === "Item Added") {
          toast.success("Event added to your cart", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          let data = await getFromCartApi(setCartData, setY, setLoader, setError);
          dispatch(cartAction({ cartCount: data?.length }));
        } else if (message === "Unauthorized") {
          setLoader(false);
          dispatch(logoutAction());
          setOpen(true);
        } else {
          setLoader(false);
          dispatch(logoutAction());
          setOpen(true);
        }
      };
    return (
        <>
            <section className="summer-intern" >
                <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                    <img src={`${HeaderImage}`} alt="Summer Internship Image" className="intern-header-image"/>
                    <Typography component="div" variant="div" sx={{marginLeft:'30px'}}>
                            <p className="intern-text" style={{textAlign:'center'}}>{event.name}</p>
                            <div className="scholar-text-aria">
                                <p className="scholar-text">{event.headline}</p>
                                {!isEventBaught ? <button 
                                    className="btn-grad btn-nav apply-now" 
                                    onClick={() => addToCart(event._id)}
                                    style={
                                        loader
                                          ? { backgroundColor: "var(--color-disable)" }
                                          : { backgroundColor: "var(--color-secondary)" }
                                    }
                                    disabled={loader ? true : false}
                                    >
                                        {loader ? <img src={ButtonLoader} width="80" /> : 
                                            <>{event.price > 0 ? `Add To Cart` : `Apply Now`}</>
                                        }
                                </button>: 
                                <button
                                    type="button"
                                    className="btn-grad btn-purchased"
                                    style={{height: '44px'}}
                                        
                                >
                                    Purchased
                                </button>
                                }
                            </div>
                    </Typography>
               </div>
            </section>
            <Typography 
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    background: '#fff'
                    }}
                className="second-section">
                <Typography component="h2" variant="h2" className="section-heading">
                    The Test Shall Be Conducted On:
                </Typography>
                <Typography 
                    component="div" 
                    variant="div"
                    className="test-detail-box test-details-aria"
                >
                    <div className="test-detail-aria">
                        <div>
                            <p>Date:</p>&nbsp;
                            <span>
                            {new Date(event.startDate).getDate()-1}
                                {first.includes(new Date(event.startDate).getDate()-1) ? "st" :
                                    (second.includes(new Date(event.startDate).getDate()-1) ? "nd":
                                    (third.includes(new Date(event.startDate).getDate()-1) ? "rd" : "th" )
                                    )
                                },{" "} 
                                {months[new Date(event.startDate).getMonth()]},{" "} 
                                {new Date(event.startDate).getFullYear()}
                                ({days[new Date(event.startDate).getDay()-1]})
                                {/* 17th, April, 2022 (Sunday) */}
                            </span>
                        </div>
                        <div>
                            <p>Time:</p>&nbsp;
                            <span>{event.startTime}</span>
                        </div>
                        <div>
                            <p>Duration:</p>&nbsp;
                            <span>{event.duration} hr</span>
                        </div>
                        <div>
                            <p>Prize Worth:</p>&nbsp;
                            <span>{event.prize}</span>
                        </div>
                        <div>
                            <p>Fee:</p>&nbsp;
                            <span>{event.price > 0 ? event.price : `No registration fee required`}</span>
                        </div>
                        <div>
                            <p>Question Type:</p>&nbsp;
                            <span>{event.questionType}</span>
                        </div>
                        <div>
                            <p>Negative Marking:</p>&nbsp;
                            <span>{event.negativeMarking}</span>
                        </div>
                        <div>
                            <p>Mode of Test:</p>&nbsp;
                            <span>{event.modeOfTest}</span>
                        </div>
                    </div>
                    <div>
                        <img src={`${TestConductImage}`} alt="Test Image" className="test-image" />
                    </div>
                </Typography>
            </Typography>
            <Typography 
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    }}
                className="second-section">
                <Typography component="h2" variant="h2" className="section-heading section-heading-2">
                    National Scholarship Test
                </Typography>
                <Typography variant="p" component="p" className="para-1 para-3">
                    {event.description}
                </Typography>
            </Typography>
            <Typography
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    backgroundColor: '#fff'
                    }}
                className="second-section"
            >
                <Typography component="h2" variant="h2" className="section-heading"
                    sx={{marginTop: '42px'}}
                >
                    What do you get ?
                </Typography>
                <Typography component="div" variant="div"
                    sx={{marginTop: '26px'}}
                >
                    <Typography component="div" variant="div" className="benefit-section">
                        <div className="benefit-box">
                            <div>
                                <img src={`${SummerInternImage}`} alt="Evaluation Image" />
                            </div>
                            <div className="bene-title">
                                <p style={{width: '202px'}}>Top 10 Students will get Free Summer Internship' 22</p>
                            </div>
                        </div>
                        <div className="benefit-box">
                            <div>
                                <img src={`${PaidInternImage}`} alt="Global Networking Image" />
                            </div>
                            <div className="bene-title">
                                <p>Paid Internship for Rank Holders</p>
                            </div>
                        </div>
                        <div className="benefit-box">
                            <div>
                                <img src={`${CertificateImage}`} alt="Instruction Image" />
                            </div>
                            <div className="bene-title">
                                <p>Certificate of Participation for Participants</p>
                            </div>
                        </div>
                    </Typography>
                    <Typography component="div" variant="div" className="benefit-section">
                        <div className="benefit-box">
                            <div>
                                <img src={`${PrizesAndDiscountsImage}`} alt="Placement Image" />
                            </div>
                            <div className="bene-title">
                                <p style={{width: '288px'}}>
                                    Amazing Prizes and Discounts on Summer Internship for the Rank Achievers
                                </p>
                            </div>
                        </div>
                        <div className="benefit-box">
                            <div>
                                <img src={`${PlacementImage}`} alt="Practical Training Image" />
                            </div>
                            <div className="bene-title">
                                <p>100% Placement Assistance</p>
                            </div>
                        </div>
                    </Typography>
                </Typography>
            </Typography>
            <Typography 
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    background: '#fff'
                    }}
                className="second-section">
                <Typography component="h2" variant="h2" className="section-heading">
                    Who can apply ?
                </Typography>
                <Typography 
                    component="div" 
                    variant="div"
                    className="test-detail-box"
                >
                    <div>
                        <Typography variant="p" component="p" className="para-1 who-can-apply"
                            sx={{    
                                fontSize: '24px',
                                fontWeight: '600',
                                color: 'var(--color-primary)'
                            }}
                        >
                            Open to everybody !
                            <div
                                style={{ 
                                    marginTop: '20px', 
                                    textAlign:'center',
                                    display: 'flex',
                                    justifyContent:'center'
                                }}
                            >
                                {!isEventBaught ? 
                                    <button 
                                        className="btn-grad btn-nav apply-now" 
                                        onClick={() => addToCart(event._id)}
                                        style={
                                            loader
                                            ? { backgroundColor: "var(--color-disable)" }
                                            : { backgroundColor: "var(--color-secondary)" }
                                        }
                                        disabled={loader ? true : false}
                                        >
                                            {loader ? <img src={ButtonLoader} width="80" /> : 
                                                <>{event.price > 0 ? `Add To Cart` : `Apply Now`}</>
                                            }
                                        </button>: 
                                        <button
                                            type="button"
                                            className="btn-grad full-width btn-purchased"
                                        >
                                            Purchased
                                        </button>
                                }
                            </div>
                        </Typography>
                    </div>
                    <div>
                        <img src={`${ArchitectPana}`} alt="Test Image" className="test-image" />
                    </div>
                </Typography>
            </Typography>
            <Typography
                className="second-section"
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    backgroundColor: '#fff',
                    paddingTop: '0'
                }}
            >
                <BookDemo/>
            </Typography>
            <LoginPopup openPopup={open} setClose={() => setOpen(false)} prevUrl={location.pathname} />
        </>
    )
}
export default InternScholarshipTest;