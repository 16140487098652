import React, {useEffect} from "react";
import { Typography, Container } from "@mui/material";
//import { motion } from 'framer-motion/dist/framer-motion';
import {
  ContentHeaderImage,
  WhatAndWhyWeDo,
  AboutUs,
  TopClient,
  OurIndustryExperts,
  Sliderr,
  HiringPartners,
  Banner,
  ServiceGrid,
  TopCourses,
  CampusAmbassador,
  WhyUs,
  WhatOurStudentSays,
  BookDemo,
  PreviousWork,
  AdminAria,
  TopCoursesAria,
  Partners,
  UpcomingEvents,
  ExerciseAria,
  EnrollCard,
  ReviewAria
  
} from "../../components";
import './home.css';

import backgroundImage from "../../assets/Svgs/hero.svg";
import navBackground from "../../assets/images/nav_background.png";
import headerMainImage from "../../assets/images/header_main_image.png";
import IitHyderabad from "../../assets/images/topClient2/iit_hyderabad.png";
import IitBhumneswar from "../../assets/images/topClient2/iit_bhubnes.png";
import IitKanpur from "../../assets/images/topClient2/iit_kanpur.png";
import OsmaniaUniv from "../../assets/images/topClient2/osmania_univ.png";
import Lpu from "../../assets/images/topClient2/lpu_punjab.png";
import IitJalandhar from "../../assets/images/topClient2/nit_jalandhar.png";
import PunjabUniv from "../../assets/images/topClient2/punjab_univ.png";
import IimNoida from "../../assets/images/topClient2/iimt_noida.png";
import IitJabalpur from "../../assets/images/topClient2/iit_jabalpur.png";

import Accenture from "../../assets/images/hiring2/accenture.png";
import Amazon from "../../assets/images/hiring2/amazon.png";
import Barclays from "../../assets/images/hiring2/barclays.png";
import Delivery from "../../assets/images/hiring2/delivery.png";
import Eduvacancy from "../../assets/images/hiring2/eduvacancy.png";
import Hotstar from "../../assets/images/hiring2/hotstar.png";
import OrangeCup from "../../assets/images/hiring2/orange_cup.png";
import Pinglash from "../../assets/images/hiring2/pinglash.png";
import Rapido from "../../assets/images/hiring2/rapido.png";
import Sysclound from "../../assets/images/hiring2/sysclound.png";
import zomato from "../../assets/images/hiring2/zomato.png";
import { useSelector, useDispatch } from "react-redux";
import { logoutAction } from "../../redux/slices/auth.slices";

const hiringData = [
  {
    image: Accenture,
    name: "Accenture",
  },
  {
    image: Amazon,
    name: "Amazon",
  },
  {
    image: Barclays,
    name: "Barclays",
  },
  {
    image: Delivery,
    name: "Delivery",
  },
  {
    image: Eduvacancy,
    name: "Eduvacancy",
  },
  {
    image: Hotstar,
    name: "Hotstar",
  },
  {
    image: OrangeCup,
    name: "OrangeCup",
  },
  {
    image: Pinglash,
    name: "Pinglash",
  },
  {
    image: Rapido,
    name: "Rapido",
  },
  {
    image: Sysclound,
    name: "Sysclound",
  },
  {
    image: zomato,
    name: "zomato",
  },
]
const topClientData = [
  {
      image: IitHyderabad,
      name: "IIT Hyderabad",
  },
  {
      image: IitBhumneswar,
      name: "IIT Bhubaneswar",
  },
  {
      image: IitKanpur,
      name: "IIT Kanpur",
  },
  {
      image: OsmaniaUniv,
      name: "Osmania University",
  },
  {
      image: Lpu,
      name: "LPU",
  },
  {
      image: IitJalandhar,
      name: "IIT Jalandhar",
  },
  {
      image: PunjabUniv,
      name: "Punjab University",
  },
  {
      image: IimNoida,
      name: "IIM Noida",
  },
  {
      image: IitJabalpur,
      name: "IIT Jabalpur",
  }
]

const Home = () => {
  let { isLogin } = useSelector((state) => state.AuthReducer);
  let dispatch = useDispatch();
  useEffect(() => {
    if(!isLogin){
      dispatch(logoutAction());
    }
  },[])
  return (
    <>
      {/* <ContentHeaderImage
        title="Indulge in limitless learning"
        overview="Anytime, Anywhere."
        background={
          window.matchMedia("(max-width: 668px)").matches ? "" : backgroundImage
        }
      /> */}
      <ContentHeaderImage
        title="Indulge in limitless learning"
        overview="Anytime, Anywhere."
        background={navBackground}
        headerMainImage={headerMainImage}
      />
      <br />
      {/* <WhatAndWhyWeDo /> */}
      <PreviousWork />
      <br />
      <section
        //className="techvanto-aboutus-section"
        className="techvanto-admin-aria-box"
      >
        {/* <AboutUs title="About us"></AboutUs>
        <TopClient title="Our College/University partners:"></TopClient> */}
        <AdminAria />
      </section>
      <br/>
      {/* <Banner/> */}
      <section>
        <TopCoursesAria />
      </section>
      <br />
      {/* <Sliderr /> */}
      <Partners data={topClientData} heading="Partnership with Top Universities" />
      <br />
      <UpcomingEvents />
      <br />
      <ExerciseAria />
      <br/>
      <ReviewAria />
      <br />
      <EnrollCard />
      <br />
      <Partners data={hiringData} heading="Hiring Partner" />
      {/* <motion.div 
        className="bg-image bg-image hiring-partner-section animation-margin"
        initial={[{ opacity: 0 }]}
        whileInView={[{ opacity: 1, marginLeft: 0 }]}
        transition={{ duration: 2 }}
        > */}
        {/* <Container className="section-spacing new-hiring">
          <Typography component="div" className="techvanto-whyus ">
            <Typography component="h2" className="techvanto-whyus-heading">
              Hiring Partners
            </Typography>
          </Typography>
          <HiringPartners />
        </Container> */}
      {/* </motion.div> */}
      <br />
      {/* <ServiceGrid /> */}
      {/* <motion.div 
        className="bg-image animate-margin"
        initial={[{ opacity: 0 }]}
        whileInView={[{ opacity: 1, marginLeft: 0 }]}
        transition={{ duration: 2 }}
        > */}
        {/* <TopCourses /> */}
      {/* </motion.div> */}
      <br />
      {/* <motion.div 
      className="bg-image bg-image hiring-partner-section animate-margin"
      initial={[{ opacity: 0 }]}
      whileInView={[{ opacity: 1, marginLeft: 0 }]}
      transition={{ duration: 2 }}
      > */}
        {/* <Container className="section-spacing">
          <OurIndustryExperts />
        </Container> */}
      {/* </motion.div> */}

      <br />
      {/* <motion.div
      className="animate-margin"
      initial={[{ opacity: 0 }]}
      whileInView={[{ opacity: 1, marginLeft: 0 }]}
      transition={{ duration: 1 }}
      > */}
        {/* <CampusAmbassador /> */}
      {/* </motion.div> */}
      <br />
      {/* <motion.div
      className="animate-margin"
      initial={[{ opacity: 0 }]}
      whileInView={[{ opacity: 1, marginLeft: 0 }]}
      transition={{ duration: 1 }}
      > */}
        {/* <div className="bg-image section-spacing">
          <WhyUs />
        </div> */}
      {/* </motion.div> */}
      {/* <motion.div
      className="animate-margin"
      initial={[{ opacity: 0 }]}
      whileInView={[{ opacity: 1, marginLeft: 0 }]}
      transition={{ duration: 1 }}
      > */}
        {/* <div className="background">
          <WhatOurStudentSays name="Home"></WhatOurStudentSays>
        </div> */}
      {/* </motion.div> */}
      {/* <motion.div
      className="animate-margin"
      initial={[{ opacity: 0 }]}
      whileInView={[{ opacity: 1, marginLeft: 0 }]}
      transition={{ duration: 1 }}
      > */}
        {/* <div className="bg-image section-spacing" id="need-assistance">
          <BookDemo />
        </div> */}
      {/* </motion.div> */}
    </>
  );
};
export default Home;
