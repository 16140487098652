import routes from "../routes/Services.routes";
import axios from "axios";

export default async function getAllDiscussionPostsApi(){
    return await axios.get(routes.DiscussionPost,{
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then(res => {
        return res.data;
    }).catch(error => {
      console.log("Discussion================>",error?.response)
        if(error?.response){
          return error.response.data.message
        }
    })
}
