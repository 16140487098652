import React, {useState, useEffect} from 'react';
import DemoHeader from './DemoHeader';
import './BookDemo.css';
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import DownArrow from '../../assets/images/down-arrow.png';
import addDemoApi from "../../../src/apis/api/AddDemo";
import AllCourseApi from "../../apis/api/AllCourse";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ButtonLoader from "../../assets/images/button_loader.gif";
import CalenderIcon from '../../assets/images/calendar.png';
import TimeIcon from '../../assets/images/clock.png';

let date = '';
let time = '';
let today = '';

function BookDemoNew(){
    const [courseError, setCourseError] = useState(true);
    const [nameError, setNameError] = useState(true);
    const [emailError, setEmailError] = useState(true);
    const [phoneError, setPhoneError] = useState(true);
    const [dateError, setDateError] = useState(true);
    const [timeError, setTimeError] = useState(true);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [allCourse, setAllCourse] = useState([]);
    const [coursesByCategory, setCoursesByCategory] = useState({});
    const [loading, setLoading] = useState();
    const [dateValue, onChange] = useState(new Date());
    const [timeValue, timeCahnge] = useState(new Date());

    const [info, setInfo] = useState({
        name: "",
        email: "",
        phone: "",
        course_id:"",
        course_name:"",
        date: ""
    });

    let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    useEffect(() => {
        todayDate();
        getAllcourse();
        let user = JSON.parse(localStorage.getItem('user'));
        if(user){
            setPhoneError(false)
            setInfo({...info, phone: user.phone})
        }else{
            setPhoneError(true)
            setInfo({...info, phone: ''})
        }
    },[])
    const todayDate = () => {
        let fullYear = new Date().getFullYear();
        let month =  new Date().getMonth()+1;
        let date = new Date().getDate();
        month = month < 10 ? `0${month}` : month;
        date = date < 10 ? `0${date}` : date;
        today = `${fullYear}-${month}-${date}`;
    }
    const getAllcourse = async() => {
        await AllCourseApi(setAllCourse, setCoursesByCategory, setLoading);
    }

    const formValidate = (e) => {
        if(e.name === 'name'){
            setInfo({...info, name: e.value});
            setNameError(false);
        }
        if(e.name === 'phone'){
            setInfo({...info, phone: e.value});
            if(e.value !== "" && e.value.length === 10) return setPhoneError(false);
            setPhoneError(true);
        }
        if(e.name === 'email'){
            setInfo({...info, email: e.value});
            if(e.value === "" || !e.value.match(emailValidate)) return setEmailError(true);
            setEmailError(false);
        }
        if(e.name === 'education'){
            let selectedCourse = [];
            if(allCourse?.length > 0) {
                selectedCourse = allCourse.filter((item) => {
                    return e.value === item._id
                })
            }
            setInfo({...info, course_id: e.value, course_name: selectedCourse[0]?.course_name});
            setCourseError(false);
        }
        // if(e.name === 'date' || e.name === 'time'){
        //     if(e.name === 'date'){
        //         date = e.value;
        //         setDateError(false);
        //     }
        //     if(e.name === 'time'){
        //         time = e.value;
        //         setTimeError(false);
        //     }
        //     console.log(date,time);
        //     if(date !== '' && time !== ''){
        //         let y = date.split('-')[0];
        //         let m = date.split('-')[1];
        //         let d = date.split('-')[2];
        //         let hh = time.split(':')[0];
        //         let min = time.split(':')[1];
        //         let newDate = new Date(y + ',' + m + ',' + d + ' ' + hh + ':' + min);
        //         console.log("new date===",newDate);
        //         setInfo({...info, date: `${newDate}`});
        //     }
        // }
    }

    const submitForm = async() => {
        if(!nameError && !emailError && !phoneError && !dateError && !timeError && !courseError){
            setButtonLoader(true);
            console.log(info);
            let res = await addDemoApi(info);
            if(res?.statusCode === 200){
                let newInfo = {
                    name: "",
                    email: "",
                    course_id: "",
                    course_name: "",
                    phone: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user'))?.phone : "",
                }
                setNameError(true);
                JSON.parse(localStorage.getItem('user')) ? setPhoneError(false)  : setPhoneError(true);
                setEmailError(true);
                setDateError(true);
                setTimeError(true);
                setCourseError(true);
                timeCahnge(new Date());
                onChange(new Date());
                setInfo(newInfo);
                setButtonLoader(false);
                toast.success(res.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else{
                console.log("error", res);
                setButtonLoader(false)
                toast.error('Something went wrong.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }else{
            if(nameError) return alertErrr('Name required');
            if(phoneError) return alertErrr('Invalid phone number entered');
            if(emailError) return alertErrr('Invalid email entered');
            if(courseError) return alertErrr('Course required');
            if(dateError) return alertErrr('Date required');
            if(timeError) return alertErrr('Time required');
        }
    }
    const alertErrr = (message) => {
        toast.warning(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const handleDateSelect = (value, from) => {
        if(from === 'date'){
            date = value;
            onChange(value);
            setDateError(false);
        }
        if(from === 'time'){
            time = value;
            timeCahnge(value);
            setTimeError(false);
        }
        if(date !== '' && time !== ''){
            let y = new Date(dateValue).getFullYear();
            let m = new Date(dateValue).getMonth()+1;
            let d = new Date(dateValue).getDate();
            let hh = new Date(time).getHours();
            let min = new Date(time).getMinutes();
            let newDate = new Date(y + ',' + m + ',' + d + ' ' + hh + ':' + min);
            setInfo({...info, date: `${newDate}`});
        }
    }
    return(
        <div style={{backgroundColor:"rgba(217, 217, 217, 0.12)"}}>
            <DemoHeader/>
            <div className="demo-body-new">
                <div className="col-lg-6 col-md-6" >
                    <Typography className="demo-body-text" component="h2">
                        <span>See how Techvanto Academy</span><br/>
                        <span>works with a free</span><br/>
                        <span>demo</span>
                    </Typography>
                    <Typography className="demo-body-subtext" 
                    style={{
                        fontSize:"18px",
                        lineHeight:"2rem",
                        fontWeight:"600",
                        }}>
                        <p>What to expect when completing our form :</p>
                        <ul type="disc">
                            <li>You will book a time with our team straight away.</li>
                            <li>We will follow up and ask for relevant details.</li>
                            <li>Get ready for personalized demo.</li>
                        </ul>
                    </Typography>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form-aria">
                    <form className='booking-form'>
                        <div className="input-box">
                            <div className="form-group">
                                <label className='label-text'>Name</label>
                                <input 
                                    type="text" 
                                    name="name" 
                                    className="form-control form-input"  
                                    onChange={((e)=>formValidate(e.target))} 
                                    value={info.name}
                                    required/>
                            </div>
                        </div>
                        <div className="input-box">
                            <div className="form-group">
                                <label className='label-text'>Contact Number</label>
                                <input 
                                    type="number" 
                                    name="phone" 
                                    className="form-control form-input" 
                                    value={info.phone}
                                    onChange={((e)=>formValidate(e.target))}
                                    required/>
                            </div>
                        </div>
                        <div className="input-box">
                            <div className="form-group">
                                <label className='label-text'>Email id</label>
                                <input 
                                    type="email" 
                                    name="email" 
                                    value={info.email}
                                    className="form-control form-input" 
                                    onChange={((e)=>formValidate(e.target))}
                                    required/>
                            </div>
                        </div>
                        <div className="input-box">
                            <div className="form-group" style={{position: "relative"}}>
                                <label className='label-text'>Course</label>
                                <select name="education" className="form-control input-style-coun" 
                                    onChange={((e)=>formValidate(e.target))}>
                                    <option 
                                        value="Select Course" 
                                        selected 
                                        disabled>Select Course</option>
                                    {allCourse?.map((item, index) => (
                                        <option value={`${item?._id}`} key={index}>{item?.course_name}</option>
                                    ))}
                                </select>
                                <div className="down-arrow">
                                    <img src={DownArrow} height="24" width="24" />
                                </div>
                            </div>
                        </div>
                        <div className="input-box">
                            <div className="form-group">
                                <label className='label-text'>Date</label>
                                    <div className="datepicker-box">
                                        <DatePicker
                                            selected={dateValue}
                                            //onSelect={() => {handleDateSelect()}}
                                            onChange={(date) => handleDateSelect(date, 'date')}
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()}
                                        />
                                        <div className="calender-icon">
                                            <img src={CalenderIcon} width="20" />
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="input-box">
                            <div className="form-group">
                                <label className='label-text'>Time</label>
                                    <div className="datepicker-box">
                                        <DatePicker
                                            selected={timeValue}
                                            //onChange={handleDateChange}
                                            //onTimeSelect={() => {handleDateSelect()}}
                                            dateFormat="HH ':' mm"
                                            //dateFormat="HH ':' mm aa"
                                            //onChange={timeCahnge}
                                            onChange={(date) => handleDateSelect(date, 'time')}
                                            minDate={new Date()}
                                            timeIntervals={30}
                                            timeFormat="HH:mm"
                                            timeCaption="time"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            />
                                        <div className="calender-icon">
                                            <img src={TimeIcon} width="20" />
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <br/>
                        <div>
                            <center> 
                                <button type="button" 
                                    onClick={submitForm}
                                    className="btn-grad-resume resume-upload-btn"
                                    style={buttonLoader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-primary)'}}
                                        disabled={buttonLoader}
                                    >
                                    {buttonLoader ? <img src={ButtonLoader} width="80" alt="" /> : 'Submit'}
                                </button>
                            </center>
                        </div>
                    </form>                    
                </div>
            </div>
        </div>
    )
}
export default BookDemoNew;