import { BrowserRouter } from "react-router-dom";
import MainRouters from "./routers/MainRouters";
import { SecondaryNavBar } from "./components";
import { NewNavBar } from "./components"; 
import Footer from "./components/footer/Footer";
import { RecoilRoot } from "recoil";
import * as React from "react";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./redux";
import { PersistGate } from "redux-persist/integration/react";
import Flier from "./components/flier/Flier";
import getFlier from "./apis/api/Configuration";

function App() {
  const [open, setOpen] = React.useState(false);
  const [flier, setFlier] = React.useState();


  // React.useEffect(() => {
  //   async function fetch() {
  //     //console.log(localStorage.getItem("flier"),'localstorageflier')
  //     if (!localStorage.getItem("flier") && !flier) {
  //       //console.log('ok')
  //       await getFlier(setFlier); //API CALL
  //       setOpen(true); //fLIER OPENING
  //     }
  //   }
  //   fetch();
  // }, [flier]);

  return (
    <RecoilRoot>
      {/* <BrowserRouter> */}
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          {/* {console.log(flier,'fliersetopen')} */}
          {/* <Flier open={open} setOpen={setOpen} flier={flier} /> */}
          {/* <SecondaryNavBar /> */}
          <NewNavBar />
          <MainRouters />
          <Footer />
        </PersistGate>
      </Provider>
      {/* </BrowserRouter> */}
    </RecoilRoot>
  );
}

export default App;
