import React from 'react';
import { Typography, Container } from "@mui/material";
import Character from '../../assets/Svgs/Character.svg';
import './resume.css';

const ResumeHeader=()=>{
    return(
        <div className="resume-header-top">
            <div className="resume-header-box">
                <img src={Character} alt="character" className="resume-header-pic" />
                <div className="resumer-header-text">
                    <Typography component="h2" className="techvanto-whyus-heading resume-heading-text">
                        How Strong is your resume?
                    </Typography>
                    <Typography className="resume-long-header-text">
                        Get a free analysis from our resume experts and help your resume stand out
                    </Typography>
                </div>
            </div>
        </div>
    )
}
export default ResumeHeader;