import * as React from "react";
import { Typography, Container } from "@mui/material";
import "./previousWork.css";
import question from "../../assets/Svgs/WhatWeDo1.svg";
import question2 from "../../assets/Svgs/WhatWeDo2.svg";
import WorkshopDel from "../../assets/images/workshop_del.png";
import PromiseDel from "../../assets/images/promise_del.png";
import CommunityMem from "../../assets/images/community_mem.png";
import TotalCourse from "../../assets/images/total_course.png";
//import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
//import { useIsMobile } from "../../utils/mobileScreen";

const PreviousWork = () => {
  //const isMobile = useIsMobile();
  return (
    <section>
      <Container className="main-container-box-prev">
        <div className="container-prev-box">
            <div className="double-prev-box">
                <div className="single-prev-box">
                    <img src={WorkshopDel} />
                    <div className="prev-box-content">
                        <p className="prev-content-text">100+</p>
                        <p className="prev-content-yellow-line"></p>
                        <p className="prev-content-sec-text">WORKSHOPS DELIVERED</p>
                    </div>
                </div>
                <div className="single-prev-box">
                    <img src={CommunityMem} />
                    <div className="prev-box-content">
                        <p className="prev-content-text">10000</p>
                        <p className="prev-content-yellow-line"></p>
                        <p className="prev-content-sec-text">COMMUNITY MEMBERS</p>
                    </div>
                </div>
            </div>
            <div className="double-prev-box">
                <div className="single-prev-box single-prev-box-2">
                    <img src={TotalCourse} />
                    <div className="prev-box-content">
                        <p className="prev-content-text">30+</p>
                        <p className="prev-content-yellow-line"></p>
                        <p className="prev-content-sec-text">TOTAL COURSES</p>
                    </div>
                </div>
                <div className="single-prev-box prev-box-content-2">
                    <img src={PromiseDel} />
                    <div className="prev-box-content">
                        <p className="prev-content-text">10000</p>
                        <p className="prev-content-yellow-line"></p>
                        <p className="prev-content-sec-text">PROMISES DELIVERED</p>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="display-grid fr2 techvanto-what-why-item techvanto-why-grid display-on-small-screen">
            <img src={question} alt="" className="image-size" />
            <Typography component="div" className="techvanto-whyus">
              <Typography component="h2" className="techvanto-whyus-heading">
                What We Do?
              </Typography>
              <div className="techvanto-grid-column text-medium">
              </div>
            </Typography>
            <Typography component="div" className="techvanto-whyus ">
              <Typography component="h2" className="techvanto-whyus-heading">
                Why We Do?
              </Typography>
              <div className="techvanto-why-we-do">
              </div>
            </Typography>
            <img src={question2} alt="" className="image-size" />
        </div> */}
      </Container>
    </section>
  );
};

export default PreviousWork;
