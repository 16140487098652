/* eslint-disable no-lone-blocks */
import React from "react";
import { Typography } from "@mui/material";
import "./AboutUs.css";
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
import { useIsMobile } from "../../utils/mobileScreen";

const AboutUs = (props) => {
  //const [value, setValue] = useState(0);
  const isMobile = useIsMobile();

  return (
    <motion.section
      initial={[{ opacity: 0 }]}
      whileInView={[{ opacity: 1, marginLeft: 0 }]}
      transition={{ duration: 2 }}
      className="animattion-div"
    >
      <Typography component="div" className="techvanto-whyus ">
        <Typography component="h2" className="techvanto-whyus-heading">
          Partnerships with top universities to make{" "}
          <span style={{ color: "#ea395d" }}>WORLD-CLASS EDUCATION</span>{" "}
          accessible globally.
        </Typography>
      </Typography>
    </motion.section>
  );
};

export default AboutUs;
