import English from "../../assets/images/servicesIcon/English.png";
import labIcon from "../../assets/images/servicesIcon/Lab.png";
import iit from "../../assets/images/servicesIcon/IIT.png";
import logical from "../../assets/images/servicesIcon/Logical.png";
import reasoning from "../../assets/images/servicesIcon/Reasoning.png";
import tution from "../../assets/images/servicesIcon/Tuition for school.png";

const Services = [
  {
    id: "1",
    tab: "School",
    text: "Resume Review",
    link: "/resources/resume",
    icon: labIcon,
    description:
      "Compete with students from better-equipped schools when it comes to lab experiments while bridging the digital divide.",
  },
  {
    id: "2",
    tab: "School",
    text: "Career Counselling",
    link: "/resources/counseling",
    icon: tution,
    description:
      "Supplement your academic curricula with tutoring of important subjects and reach your academic goals.",
  },
  {
    id: "3",
    tab: "School",
    text: "Discussion Forum",
    link: "/resources/discussion-forum",
    icon: iit,
    description:
      "Experience a new era of smart coaching to secure a seat at your dream IIT.",
  },
  {
    id: "6",
    tab: "School",
    text: "Campus Ambassador",
    link: "/campus-ambassador",
    icon: logical,
    description:
      "Your journey towards getting that perfect score in the reasoning section starts here. Prepare for competitive exams like never before.",
  },
  {
    id: "6",
    tab: "School",
    text: "Olympiad",
    link: "/olympiad",
    icon: logical,
    description:
      "Your journey towards getting that perfect score in the reasoning section starts here. Prepare for competitive exams like never before.",
  },
  {
    id: "4",
    tab: "School",
    text: "Exercise",
    link: "/resources/excercise",
    icon: English,
    description:
      "Willing to overcome your fear of English? You are at the right place. Conquer the challenges of feeling shy or making mistakes by building your English vocabulary and speaking skills the real way. We guide you from Day 1 until you learn effective communication for lasting and effective business relations and achieving your professional goals.",
  },
  {
    id: "5",
    tab: "School",
    text: "Tutorials",
    link: "/resources/editors",
    icon: reasoning,
    description:
      "Logical and Reasoning-based questions form the core of many aptitude and competitive examinations. Techvanto Academy’s reasoning training allows you to discover your critical and computational thinking abilities and logic through the lens of practical problems from the beginner level.",
  }
];

export default Services;
