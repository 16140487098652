export const commentTimeCount = (date) => {
    let dateDiff = Math.abs(new Date(date) - new Date()); 
    let message = ''
    if(parseInt(dateDiff/(1000)) < 60){
        message = `${parseInt(dateDiff/(1000))}s`;
    }else if(parseInt(dateDiff/(1000)) >= 60 && parseInt(dateDiff/(1000*60)) < 60){
        message = `${parseInt(dateDiff/(1000*60))}m`;
    }else if(parseInt(dateDiff/(1000*60)) >= 60 && parseInt(dateDiff/(1000*60*60)) < 24){
        message = `${parseInt(dateDiff/(1000*60*60))}h`;
    }else{
        message = parseInt(dateDiff/(1000*60*60*24)) > 1 ? 
                    `${parseInt(dateDiff/(1000*60*60*24))} days` : 
                        `${parseInt(dateDiff/(1000*60*60*24))} day`;
    }
    return message
}