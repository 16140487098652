import * as React from "react";
import { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Check from "./Check";
import getFromCartApi from "../apis/api/GetFromCart";
import ErrorIcon from '@mui/icons-material/Error';
import { Button, DialogContent, Typography } from "@mui/material";
//import { DialogActions } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { cartAction } from "../redux/slices/cart.slice";
import { useNavigate } from "react-router-dom";
import './PaymentSuccessDialog.css';
import logoTransperent from "../assets/images/logo-print-hd-transparent-removebg-preview.png";
import {Link} from "react-router-dom";
import {DialogContentText} from "@mui/material";

export default function PaymentSuccessDialog(props) {
  const [cartData, setCartData] = useState();
  const [y, setY] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const { handleClose, open, message, orderId, paymentId } = props;

  useEffect(async() => {
    let data = await getFromCartApi(setCartData, setY, setLoading, setError);
    dispatch(cartAction({cartCount:data?.length}))
  },[])

  return (
    <Dialog onClose={handleClose} open={open} className="check-popup">
      <center><p style={{marginTop:"4%"}}><img src={logoTransperent} height="100%" width="250px" alt="Logo" /></p></center>
      <DialogTitle sx={{display: 'flex', justifyContent: 'center'}}>
      <p>{message === "Payment Success" || message === "Order Placed" ? <Check /> : <ErrorIcon fontSize="large" color="error"/>}</p>
      </DialogTitle>
      <DialogContent sx={{width: '550px',height:'270px',}}>
        <DialogContentText className="payment-popup">
          <center>
          <p><h2 style={{color:"black",fontWeight:"bolder"}}>{message === "Payment Success" || message === "Order Placed" ? "Your Order is Complete !" : " "}</h2></p>
        <p><h5 style={{opacity:"80%"}}>Thank you for choosing Techvanto Academy. Our Team will contact you shortly.</h5></p>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {orderId && <Typography variant="span" component="span"><b>Order Id</b> : {orderId}</Typography>}
          {/** {paymentId && <Typography variant="span" component="span"><b>Payment Id</b> : {paymentId}</Typography>}*/}
        </div>
        </center>
        </DialogContentText>
        <center style={{marginTop: '12px'}}>
          <Link to="/all-courses/all" style={{ textDecoration: "none", marginRight: '12px' }}>
            <button type="button" className="btn-grad btn-nav">Explore More</button> 
          </Link>
          <Link to={`/`} style={{ textDecoration: "none" }}>
            <button type="button" className="btn-grad btn-nav">Home Page</button>
          </Link> 
        </center>
      </DialogContent>
    </Dialog>
  );
}
