import routes from "../routes/Services.routes";
import axios from "axios";

export default async function getEditorsSubTopicApi(topic){
  console.log("sub--==",`${routes.EditorsHtmlInfo}/${topic}/sections`)
    return await axios.get(`${routes.EditorsHtmlInfo}/${topic}/sections`,{
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then(res => {
        return res.data;
    }).catch(error => {
        console.log(error);
    })
}
