import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SinglePost from "../../../components/Blogs/SingleBlogPost/SinglePost";
import "./single.css";
import getSingleBlogApi from "../../../apis/api/SingleBlog";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function Single() {
  const [blogItem, setBlogItem] = useState();
  const params = useParams();
  useEffect(() => {
    getSingleBlogApi(params.id, setBlogItem);
  }, []);
  return (
    <div className="single" >
       {console.log(blogItem)}
      <Box
        component="section"
        className="page-heading blog-heading-container" 
        style={{background: 'transparent'}}      
      >
        <div className="course-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" style={{
                color: 'var(--color-secondary-text)',
              }}>
                Home
                <div className="line" style={{
                  backgroundColor: 'var(--color-secondary-text)'
                }}></div>
              </li>
              <li className="breadcrumb-item active" style={{
                color: 'var(--color-secondary-text)',
              }}>
                Blogs<div className="line"></div>
              </li>
              <li className="breadcrumb-item active" style={{
                color: 'var(--color-secondary-text)',
              }}>{blogItem?.title}</li>
            </ol>            
          </nav>
          <div className="">
            <Typography component="h2" 
              className="techvanto-whyus-heading blog-title" 
              style={{
                  color: '#000',
              }}>
                {blogItem?.title}
            </Typography>
            <p className="blog-headline-aria">
              {blogItem?.headline}
            </p>
          </div>
        </div>
        <div className="blog-img-container">
          <img src={blogItem?.blog_image} />
        </div>
      </Box>
      <SinglePost blogDetails={blogItem} />
    </div>
  );
}