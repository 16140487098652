import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './summerIntern.css';
import { Typography, Container, CardActions } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import HeaderImage from '../../assets/images/summer-intern.png';
import WhyUsImaage from '../../assets/images/why-us.png';
import EvaluationImage from '../../assets/images/intershipBenefits/evaluation.png';
import GlobalNetworkingImage from '../../assets/images/intershipBenefits/global-networking.png';
import InstructionImage from '../../assets/images/intershipBenefits/instruction.png';
import LiveProjectsImage from '../../assets/images/intershipBenefits/live-projects.png';
import LiveTrainingImage from '../../assets/images/intershipBenefits/live-training.png';
import MentorshipImage from '../../assets/images/intershipBenefits/mentorship.png';
import PlacementImage from '../../assets/images/intershipBenefits/placement.png';
import PracticalTrainingImage from '../../assets/images/intershipBenefits/practical-training.png';
import RegisterNow from '../../assets/images/register-now.png';
import InternCertificateImage from '../../assets/images/intern-certificate.png';
import TopClient from "../../components/TopClients/TopClients";
import HiringPartner from "../../components/HiringPartners/HiringPartner.js";
import WhatOurStudentSays from "../../components/WhatOurStudentSays/WhatOurStudentSays";
import BookDemo from "../../components/BookDemo/BookDemo";
import AllCourseApi from "../../apis/api/AllCourse";
import ButtonLoader from "../../assets/images/button_loader.gif";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import getAllReferals from '../../apis/api/GetReferals';
import addSummerOrderApi from "../../apis/api/AddSummerOrder";
import verifyOrderApi from "../../apis/api/OrderVerify";
import useRazorpay from "react-razorpay";
import PaymentSuccessDialog from "../../components/PaymentSuccessDialog";
import { NavHashLink } from "react-router-hash-link";
import getFromCartApi from "../../apis/api/GetFromCart";
import { logoutAction } from "../../redux/slices/auth.slices";
import { cartAction } from "../../redux/slices/cart.slice";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.development";
import LoginPopup from "../Generic/LoginPopup/LoginPopup";

const SummerIntern = () => {
    const [courseList, setCourseList] = useState([]);
    const [loading, setLoading] = useState();
    const [courseByCategory, setCourseByCategory] = useState();
    const [selectedCourse, setSelectedCourse] = useState([]);
    const [radioValue, setRadioValue] = useState("full fee");
    const [error, setError] = useState(false);
    const [referalError, setReferalError] = useState(false);
    const [referalCode, setReferalCode] = useState("");
    const [referalSucces, setReferalSucces] = useState(false);
    const [verifyLoader, setVerifyLoader] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [refreeName, setRefreeName] = useState();
    const [checkoutLoader, setCheckoutLoader] = useState(false);
    const [isItemSelected, setIsItemSelected] = useState('');
    const [referalData, setReferalData] = useState();
    const [verifiedRefralCode, setVerifiedRefralCode] = useState();
    const [collegeError, setCollegeError] = useState(false);
    const [paymentId, setPaymentId] = useState();
    const [cartItems, setCartItems] = useState([]);
    const [paymentMessage, setPaymentMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [openLoginPopup, setOpenLoginPopup] = useState(false);
    const [college, setCollege] = useState("");
    const [OrderId, setOrderId] = useState();
    let [y, setY] = useState([]);
    const navigate = useNavigate();
    let dispatch = useDispatch();
    const Razorpay = useRazorpay();
    let verifiedCode = [];
    let location = useLocation();

    let { admin, isLogin } = useSelector((state) => state.AuthReducer);

    useEffect(() => {
        getCourseData()
        
    }, [])
    const getCourseData = async() => {
        let data = [];
       data = await AllCourseApi(setCourseList, setCourseByCategory, setLoading);
       if(data?.length > 0) {
        setCourseList(data.filter((item) => {
            return item.category === "College";
        }))
       }
    }
    
    const selectCourse = (event) => {
        setError(false);
        setSelectedCourse(courseList.filter((item) => {
            return item._id === event.target.value;
        }))
    }
    const selectPaymentType = (event) => {
        setRadioValue(event.target.value)
    }
    
    const getReferalCode = (event) => {
        setReferalSucces(false)
        event.target.value === "" ? setReferalError(true) : setReferalError(false)
        event.target.value === "" ? setErrorText("Enter Referal Code") : setErrorText("")
        setReferalCode(event.target.value);
    }
    const handleVerify = async(event) => {
        event.preventDefault();
        setReferalSucces(false)
        setVerifyLoader(true)
        referalCode === "" ? setReferalError(true) : setReferalError(false)
        referalCode === "" ? setErrorText("Enter Referal Code") : setErrorText("")
        if(referalCode !==  "") {
          let allReferals = await getAllReferals(setReferalData, setVerifyLoader);
          if(allReferals) {
            verifiedCode = allReferals.filter(item => {
                return item.referal_code === referalCode
            });
          }
          
          if(verifiedCode.length < 1){
            setReferalError(true)
            setErrorText("Invalid Referal Code")
          }
          else{
            setVerifiedRefralCode(verifiedCode[0].referal_code)
            setRefreeName(verifiedCode[0].refree_name)
            setReferalSucces(true);
            setReferalError(false);
          }
        }
        else{
          setVerifyLoader(false)
        }
        return;
    }
    const registerNow = async() => {
        setCheckoutLoader(true);
        if(isLogin){
            setCollegeError(false);
            if(college === ""){ 
                setCheckoutLoader(false)
                return setCollegeError(true)
            }
            if(selectedCourse.length === 0){
                setCheckoutLoader(false);
                return setError(true)
            }
            if(referalCode === "" || referalSucces){
                let fee = "";
                selectedCourse[0]?.discount > 0 ?
                    fee = parseInt(document.getElementById("totalPrice2").innerHTML) :
                    fee = parseInt(document.getElementById("totalPrice1").innerHTML)
                let body = {
                    college: college,
                    course_id: selectedCourse[0]?._id,
                    order_type: "paid",
                    total_amount: fee,
                    refree_name: refreeName ? refreeName : null,
                    refral_code: verifiedRefralCode ? verifiedRefralCode : null
                }
                let order = await addSummerOrderApi(body);
                if(order?.data?.message === "Unauthorized"){
                    toast.error("Unauthorized", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      dispatch(logoutAction());
                      setOpenLoginPopup(true);
                }
                const options = {
                    key: process.env.REACT_APP_RAZORPAY_KEY,
                    amount: parseInt(fee)*100,
                    currency: "INR",
                    name: "Techvanto Academy",
                    description: "Transaction",
                    image: "https://i.ibb.co/jVR0Kyc/logo-3.png",
                    // order_id: order.data.order.order_id,
                    modal: {
                      ondismiss: async function () {
                       //console.log("hello",response)
                      },
                    },
                    handler: async function (response) {
                      let verificationDetail = {
                        razorpay_payment_id: response.razorpay_payment_id,
                        order_id: response.razorpay_order_id,
                        cart_item: body,
                        placed_from: "summer"
                      };
                      setOrderId(response.razorpay_order_id);
                      setPaymentId(response.razorpay_payment_id);
                      setCheckoutLoader(false);
                      await verifyOrderApi(verificationDetail, setPaymentMessage, setOpen);
                    },
                    prefill: {
                      name: admin.name,
                      email: admin.email,
                      contact: admin.phone,
                    },
                    notes: {
                      address: "Razorpay Corporate Office",
                    },
                    theme: {
                      color: "#3399cc",
                    },
                  };
                  const rzp1 = new Razorpay(options);
                  rzp1.on("payment.failed", function (response) {
                    let verificationDetail = {
                      razorpay_payment_id: response.error.metadata.payment_id,
                      order_id: response.error.metadata.order_id,
                      cart_item: body
                    };
                    setOrderId(response.error.metadata.order_id);
                    setPaymentId(response.error.metadata.payment_id);
                    //options.modal.ondismiss(verificationDetail)
                    verifyOrderApi(verificationDetail, setPaymentMessage, setOpen);
                  });
                  setCheckoutLoader(false);
                  rzp1.open();
            }else{
                setCheckoutLoader(false)
            }
        }else{
            toast.error("Unauthorized", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              dispatch(logoutAction());
              setOpenLoginPopup(true)
        }
    }
    const getCollege = (event) => {
        event.target.value === "" ? setCollegeError(true) : setCollegeError(false);
        setCollege(event.target.value);
    }
    const handleClose = async() => {
        setOpen(false);
        if(paymentMessage === "Payment Success" || 
          paymentMessage === "Order Placed"){
          let data = await getFromCartApi(setCartItems, setY, setLoading, setError);
          if(data){
            dispatch(cartAction({ cartCount: data?.length }))
          }
          window.location.assign("/#/my-courses");
        }
        else{
          //navigate('/my-cart')
          //window.location.assign("/#/my-cart");
        }
        
      };
    return (
        <>
            <Typography variant="p">
                {paymentMessage !== "" ? (
                <>
                    <PaymentSuccessDialog
                    message={paymentMessage}
                    orderId={OrderId}
                    paymentId={paymentId}
                    open={open}
                    handleClose={handleClose}
                    />
                </>
                ) : (
                ""
                )}
            </Typography>
            <section className="summer-intern" >
                <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                    <img src={`${HeaderImage}`} alt="SummerInternshipImage" className="intern-header-image"/>
                    <Typography component="div" variant="div" sx={{marginLeft:'30px'}}>
                            <p className="intern-text">Techvanto Academy Summer Internship Program'23</p>
                            <HashLink  to="/techvanto-summer-internship/#registerForm" 
                                className="intern-register summer-register" 
                                style={{textDecoration:'none'}}
                                >
                                Register Now
                            </HashLink>
                    </Typography>
               </div>
            </section>
            <Typography 
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    }}
                className="second-section">
                <Typography component="h2" variant="h2" className="section-heading section-heading-2">
                    Summer Internship Program'23
                </Typography>
                <Typography variant="p" component="p" className="para-1 para-3">
                    Looking for a training program that not only teaches you technical skills but also offers global networking 
                    opportunities? Techvanto's Summer Training Programme is just the right thing for you. Transform your theoretical 
                    understanding of concepts to learn industrial skills and gain exposure with us as we lead you to building a 
                    foundation for your future career.
                </Typography>
            </Typography>
            <Typography
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    backgroundColor: '#fff'
                    }}
                className="second-section"
            >
                <Typography component="h2" variant="h2" className="section-heading"
                    sx={{marginTop: '42px'}}
                >
                    Why Us ?
                </Typography>
                <div className="why-us-section">
                    <div className="why-us-image">
                        <img src={`${WhyUsImaage}`} alt="Why Image" className="why-image"/>
                    </div>
                    <Typography variant="p" component="p" className="para-1 para-2">
                        Techvanto has created a premium, immersive environment for young learners in the form of this training programme with 
                        a special focus on the most useful and crucial skillsets today—complete with performance evaluations and on-the-job 
                        instructions that are at par with the current industry standards. All of this in a fun environment! During this 
                        six-week training, you shall develop a holistic understanding of how the industry works and be guided well on future 
                        career options.
                    </Typography>
                </div>
            </Typography>
            <Typography
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    backgroundColor: '#fff'
                    }}
                className="second-section"
            >
                <Typography component="h2" variant="h2" className="section-heading">
                    Benefits of Summer Internship Program
                </Typography>
                <Typography component="div" variant="div">
                    <Typography component="div" variant="div" className="benefit-section">
                        <div className="benefit-box">
                            <div>
                                <img src={`${GlobalNetworkingImage}`} alt="Evaluation Image" />
                            </div>
                            <div className="bene-title">
                                <p>Global Network Oppurtunities</p>
                            </div>
                        </div>
                        <div className="benefit-box">
                            <div>
                                <img src={`${MentorshipImage}`} alt="Global Networking Image" />
                            </div>
                            <div className="bene-title">
                                <p>Mentorship Programme</p>
                            </div>
                        </div>
                        <div className="benefit-box">
                            <div>
                                <img src={`${EvaluationImage}`} alt="Instruction Image" />
                            </div>
                            <div className="bene-title">
                                <p>Performance Evaluation</p>
                            </div>
                        </div>
                    </Typography>
                    <Typography component="div" variant="div" className="benefit-section">
                        <div className="benefit-box">
                            <div>
                                <img src={`${InstructionImage}`} alt="Live Projects Image" />
                            </div>
                            <div className="bene-title">
                                <p>On-the-job Instructions</p>
                            </div>
                        </div>
                        <div className="benefit-box">
                            <div>
                                <img src={`${LiveTrainingImage}`} alt="Instruction Image" />
                            </div>
                            <div className="bene-title">
                                <p style={{width: '140px'}}>60 hrs Live Training</p>
                            </div>
                        </div>
                        <div className="benefit-box">
                            <div>
                                <img src={`${PracticalTrainingImage}`} alt="Mentorship Image" />
                            </div>
                            <div className="bene-title">
                                <p>90% Practical Training</p>
                            </div>
                        </div>
                    </Typography>
                    <Typography component="div" variant="div" className="benefit-section">
                        <div className="benefit-box">
                            <div>
                                <img src={`${PlacementImage}`} alt="Placement Image" />
                            </div>
                            <div className="bene-title">
                                <p>100% Placement Assistance</p>
                            </div>
                        </div>
                        <div className="benefit-box">
                            <div>
                                <img src={`${LiveProjectsImage}`} alt="Practical Training Image" />
                            </div>
                            <div className="bene-title">
                                <p>Work on Live Projects</p>
                            </div>
                        </div>
                    </Typography>
                </Typography>
            </Typography>
            <Typography
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    backgroundColor: '#fff',
                    paddingTop: '0px'
                    }}
                className="second-section"
            >
                <Typography component="h2" variant="h2" className="section-heading">
                    A Glimpse about Summer Internship Program
                </Typography>
                <div
                    style={{margin: '42px 0'}}
                >
                    <iframe
                        title="techvanto"
                        className="internship-glimps"
                        style={{ borderRadius: "8px" }}
                        src="https://www.youtube.com/embed/HH8us39zlYs?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://youtubeembedcode.com"
                    ></iframe>
                </div>
                <Typography component="h2" variant="h2" className="section-heading">
                    Register Now With Us !
                </Typography>

            </Typography>
            <Typography
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    margin: '20px 0'
                    }}
                className="second-section"
            >
                <div className="inner-box" id="registerForm">
                    <Typography 
                        component="div" 
                        variant="div"
                        className="register-form-box"
                    >
                        <div className="image-aria" style={{width: "100%"}}>
                            <img src={`${RegisterNow}`} alt="Register Now" />
                        </div>
                        <div style={{width: '100%'}} className="form-aria">
                            <div
                                className="input-fields"
                            >
                                {(error || collegeError) && 
                                <p style={{
                                    margin: '0',
                                    color: 'var(--color-secondary)',
                                    fontWeight: '600'}}>
                                    {error && `Select a course` }
                                    {collegeError && `Enter Your College` }
                                </p>}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'}}
                                >
                                    <input type="text" 
                                        onChange={getCollege} 
                                        placeholder="College Name *"
                                        className="college-input-box" />
                                    <select onChange={selectCourse}>
                                        <option disabled selected value="">Select Course *</option>
                                        {courseList.length > 0 &&
                                            courseList.map((item,index) => (
                                                <option key={index} value={item._id}>{item.course_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                {selectedCourse.length > 0 &&
                                <div className="details-section">
                                    {selectedCourse[0]?.discount === 0 && <p>Price: Rs. 
                                        <b id="totalPrice1">{radioValue === "full fee" ? selectedCourse[0]?.price : 
                                            selectedCourse[0]?.registration_fee}</b> /-
                                    </p>}
                                    {selectedCourse[0]?.discount > 0 && 
                                        <p>Price: Rs. &nbsp; {radioValue === "full fee" ? <del>{selectedCourse[0]?.price}</del> : ``}
                                            <p style={{marginBottom: '0', fontSize: '13px', textAlign: 'right'}}>
                                                <b id="totalPrice2">{radioValue === "full fee" ? 
                                                    parseInt(selectedCourse[0]?.price) - parseInt(selectedCourse[0]?.discount) :
                                                    selectedCourse[0]?.registration_fee
                                                }</b> /-
                                            </p>
                                        </p>
                                    }
                                    <Link to={`/courses/${selectedCourse[0]?._id}`}>View course details</Link>
                                </div>}
                            </div>
                            <div>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    value={radioValue}
                                    onChange={selectPaymentType}
                                    name="radio-buttons-group"
                                    className="radio-aria"
                                >
                                    <FormControlLabel 
                                        value="full fee" 
                                        control={<Radio 
                                            sx={{color: '#fff',
                                            '&.Mui-checked': {
                                                color: '#fff',
                                            },
                                            }} 
                                            />} 
                                        label="Register with full payment"
                                        sx={{color: '#fff'}} />
                                    <FormControlLabel 
                                        value="registration fee" 
                                        control={<Radio
                                            sx={{color: '#fff',
                                            '&.Mui-checked': {
                                                color: '#fff',
                                            },
                                            }} 
                                            />} 
                                        label="Register with registration fee"
                                        sx={{color: '#fff'}} />
                                </RadioGroup>
                                <CardActions 
                                    sx={{display: "flex", flexDirection: "column"}}
                                    className="referal-code"
                                    >
                                    <FormControl
                                        sx={{ height: '48px'}}
                                        className="referal-aria"
                                    >
                                        <InputLabel 
                                            htmlFor="component-outlined" 
                                            error={referalError}
                                            sx={{color: '#fff'}}
                                        >
                                            Referal Code(Optional)
                                        </InputLabel>
                                        <OutlinedInput
                                        id="component-outlined"
                                        label="Referal Code(Optional)"
                                        value={referalCode}
                                        onChange={(e) => getReferalCode(e)}
                                        error={referalError}
                                        sx={{
                                            color: '#fff'
                                        }}
                                        />
                                        <button
                                        className="btn-verify"
                                        onClick={(e) => handleVerify(e)}
                                        disabled={verifyLoader}
                                        style={verifyLoader ? {backgroundColor: "var(--color-disable)"} :
                                        {backgroundColor: "var(--color-secondary)"}}
                                        >

                                        {verifyLoader ? <img src={ButtonLoader} width="40" alt="" /> : 
                                        'Verify'}
                                        </button>
                                        {referalError && <p className="referal-error">{errorText}</p>}
                                        {referalSucces && <p 
                                        className="referal-error"
                                        style={{
                                            color: 'rgb(13 207 32)',
                                            fontWeight: '800'
                                        }}
                                        >
                                        Applied Successfully({refreeName})
                                        <CheckCircleIcon className="check-icon" />
                                        </p>}
                                    </FormControl>
                                    <p style={{color: "var(--color-secondary)", margin:"0"}}>{isItemSelected}</p>
                                </CardActions>
                                <button 
                                    onClick={registerNow} 
                                    className="intern-register inter-btn" 
                                    style={
                                        checkoutLoader
                                          ? { backgroundColor: "var(--color-disable)" }
                                          : { backgroundColor: "var(--color-secondary)" }
                                    }
                                    disabled={checkoutLoader}
                                    >
                                        {checkoutLoader ? <img src={ButtonLoader} width="80" /> : "Register Now"}
                                </button>
                            </div>
                        </div>
                    </Typography>
                </div>
            </Typography>
            <Typography
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    backgroundColor: '#fff',
                    paddingBottom: '0'
                    }}
                className="second-section"
            >
                <Typography component="h2" variant="h2" className="section-heading"
                    sx={{marginTop: '42px'}}
                >
                    Certificate of Completion
                </Typography>
                <Typography 
                    variant="p" 
                    component="p" 
                    className="para-1" 
                    style={{
                        textAlign: 'center',
                        marginBottom: '30px',
                        marginTop: '20px'
                        }}>
                    Receive a Certificate of Internship at the end of the training and showcase your acquired skills to the world.
                </Typography>
                <div>
                    <img src={`${InternCertificateImage}`} alt="Certificate Image" />
                </div>
            </Typography>
            <Typography 
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    backgroundColor: '#fff',
                    paddingTop: '0',
                    marginTop: '52px'
                    }}
                className="second-section"
            >
                <WhatOurStudentSays name="Home" />
            </Typography>
            <Typography 
                className="second-section topUniversities"
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    backgroundColor: '#fff',
                    paddingTop: '0',
                    paddingBottom: '0'
                    }}
            >
                {" "}
                <Container>
                <h3 className="about-us-heading universities"
                    style={{marginBottom: '44px'}}
                >
                    {" "}
                    Partnerships with top universities to make{" "}
                    <span style={{ color: "#ea395d" }}>WORLD-CLASS EDUCATION</span>{" "}
                    accessible globally.
                </h3>
                <TopClient />
                </Container>
            </Typography>
            <br/>
            <br/>
            <Typography 
                className="second-section hiring-partner"
                variant="section" 
                component="section"
                sx={{
                    textAlign:'center',
                    backgroundColor: '#fff'
                    }}
                >
                <Container>
                <h3 className="about-us-heading" style={{marginBottom: '44px'}}>Hiring Partner</h3>
                <HiringPartner />
                </Container>
            </Typography>
            <br/>
            <br/>
            <Typography
                className="second-section"
                variant="section" 
                component="section" 
                sx={{
                    textAlign:'center',
                    backgroundColor: '#fff'
                }}
            >
                <BookDemo/>
            </Typography>
            <LoginPopup openPopup={openLoginPopup} setClose={() => setOpenLoginPopup(false)} prevUrl={location.pathname} />
        </>
    )
}
export default SummerIntern;