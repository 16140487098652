import React, {useState, useEffect} from 'react';
import { Link, useLocation } from "react-router-dom";
import getDoneExercisesApi from '../../apis/api/GetDoneExcercises';
import getUndoneExercisesApi from '../../apis/api/GetUndoneExercises';
import ExcerciseHeader from './ExcerciseHeader';
import ExcerciseHeaderImage from '../../assets/images/excercise_header_pic.png';
import Loader from "../Loader";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import LoginPopup from "../Generic/LoginPopup/LoginPopup";
import { logoutAction } from "../../redux/slices/auth.slices";
import './excercise.css';

function ExcerciseBody(){
    const [undoneExercise, setUndoneExercise] = useState([]);
    const [doneExercise, setDoneExercise] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [unauthorized, setUnauthorized] = useState(false);
    const [open, setOpen] = useState(false);
    let { isLogin } = useSelector((state) => state.AuthReducer);
    let location = useLocation();
    let dispatch = useDispatch();
    useEffect(() => {
        getDoneExercise();
        getUndoneExercises();
    },[])

    const getDoneExercise = async() => {
        let res = await getDoneExercisesApi();
        if(res?.statusCode === 200){
            setDoneExercise(res?.data?.categories_completed);
        }
    }
    const getUndoneExercises = async() => {
        setIsLoader(true);
        let res = await getUndoneExercisesApi();
        if(res?.statusCode === 200){
            setIsLoader(false)
            setUndoneExercise(res?.data)
        }else{
            if(res === "Unauthorized" || res === "Session Expired"){
                setOpen(true);
                errorToast('Session expired, please login.');
                setUnauthorized(true);
                setIsLoader(false);
                return;
            }
            errorToast('Something went wrong.')
            setIsLoader(false)
            
        }
    }
    const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    return(
        <>
            {isLoader ? <Loader /> :
            <>
            <ExcerciseHeader 
                heading={'Excercises'}
                description={'Practice your way to the top.'}
                image={ExcerciseHeaderImage} />
            {(isLogin && !unauthorized) ?
            <div className="excercise-main-box">
                {undoneExercise?.length > 0 && undoneExercise?.map((item, index) => (
                <div className="excercise-box" key={index}>
                    <div>
                        <div className="excercise-subj-title">Start {item} Excercise</div>
                        <div className="excercise-greet">Good Luck!</div>
                    </div>
                    <Link to={`/resources/${item}`}>
                        <button type="button" className='btn-grad btn-nav' 
                            style={(doneExercise[index] && (doneExercise[index] === item)) && 
                                {backgroundColor: 'var(--color-green-icon)'}}
                            >
                            {doneExercise[index] === item ? 'Try Again' : 'Start Excercise'}
                        </button>
                    </Link>
                </div>))}
            </div>:
                <div>
                    <p style={{
                        textAlign: 'center',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        marginTop: '24px'
                    }}>Please login to see exercises.</p>
                </div>
            }
            <LoginPopup openPopup={open} setClose={() => setOpen(false)} prevUrl={location.pathname} />
            </>
            }
        </>
    )
}
export default ExcerciseBody;