import React from 'react';
import { useEffect, useState } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Typography, Container, CardActions } from "@mui/material";
import UploadOnS3 from "../../../src/apis/api/UploadOnS3";
import addCampusAmbasApi from "../../apis/api/AddCampusAmbas";
import { Link } from 'react-router-dom';
import "./campusAmbas.css";
import { Button } from "@mui/material";
import ButtonLoader from "../../assets/images/button_loader.gif";
import TrainingBenefits from "../../assets/images/benifits_campus_ambs.png";
import RedCheck from "../../assets/images/red_check.png";
import NetworkSkills from "../../assets/images/network_skills.png";
import ManagementSkills from "../../assets/images/management_skills.png";
import SoftSkills from "../../assets/images/soft_skills.png";
import AmbasHeaderImage from "../../assets/images/ambas_header_image.png";
import { toast } from "react-toastify";

const CampusAmbas = () => {
    const [fileFieldError, setFileFieldError] = useState(false);
    const [selectedFile, setSelectedFile] = useState({image:{}});
    const [fileLoader, setFileLoader] = useState(false);
    const [file, setFile] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [resumeError, setResumeError] = useState(true);
    const [loader, setLoader] = useState(true);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [info, setInfo] = useState({
        name: "",
        email: "",
        phone: "",
        resume_link:"",
    });
    let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const handleUploadOnS3 = async(e) => {
        try {
            setFileLoader(true);
            const image = new FormData();
            image.append("file", e.target.files[0]);
            if(e.target.files[0]?.type === 'application/pdf' || 
                e.target.files[0]?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                setFile(e.target.files[0]?.name);
                const data = await UploadOnS3(image);
                setInfo({...info, resume_link: data?.data?.urls[0]})
                setFileLoader(false);
                setResumeError(false);
            }else{
                errorToast('Only pdf or doc files are allowed')
            }
            
        } catch (e) {
            console.log(e);
            setFileLoader(false);
        } finally {
            console.log("Finally called");
            setFileLoader(false);
        }
    };
    const submitForm= async(e) =>{
        e.preventDefault();
        setLoader(true);
        if(!nameError && !phoneError && 
           !emailError && !resumeError)
        {
            let res;
            setButtonLoader(true)
            res = await addCampusAmbasApi(info);
            console.log("res==========",res)
            if(res){
                // console.log("res==========",res)
                setButtonLoader(false)
                if(res.statusCode === 200){
                    console.log("success");
                    let fileInfo = {
                        name: "",
                        email: "",
                        phone: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).phone : "",
                        resume_link:"",
                    }
                    setNameError(true);
                    setEmailError(true);
                    JSON.parse(localStorage.getItem('user')) ? setPhoneError(false) : setPhoneError(true);
                    setResumeError(true);
                    setFile(null);
                    setInfo(fileInfo);
                    toast.success(res.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }else{
                    toast.error('Something went wrong.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }
            }else{
                setButtonLoader(false);
                toast.error('Something went wrong.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            }
        }else{
            if(nameError) return errorToast('Name required.');
            if(phoneError) return errorToast('Invalid phone number entered.');
            if(emailError) return errorToast('Invalid email entered.');
            if(resumeError) return errorToast('Upload your resume.');
            
        }
    }
    const formValidate = (event) => {
        if (event.name === "name") {
          setInfo({ ...info, name: event.value });
          if(event.value === "") return setNameError(true);
          setNameError(false);
        }
        if (event.name === "email") {
          setInfo({ ...info, email: event.value });
          if(event.value === "" || !event.value.match(emailValidate)) return setEmailError(true);
          setEmailError(false);
        }
        if (event.name === "phone") {
          setInfo({ ...info, phone: event.value });
          if(event.value !== "" && event.value.length === 10) return setPhoneError(false);
          setPhoneError(true);
        }
      }
    const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    return (
        <>
            <section className="summer-intern campus-ambas-back" style={{
                background: `url(${AmbasHeaderImage})`,
                backgroundRepeat: 'no-repeat',
                }}>
                <div className="wintr-intern-box">
                    <Typography 
                        component="div" 
                        variant="div" 
                        className="wintr-intern-content">
                            <p className="intern-text winter-text"
                            style={{
                                textTransform: "uppercase",
                                color: "var(--color-black)"
                            }}>
                                Join The Family!!
                            </p>
                            <p style={{
                                color: "var(--color-black)",
                                fontWeight: "bold",
                                fontSize: "28px"

                            }}>
                                Campus Ambassador
                            </p>
                            <p className="wintr-small-text">
                                Strive for perfection with our crash course. Each class is held over 15 days or 30 days.
                            </p>
                            {/* <HashLink  to="/techvanto-winter-internship/#registerForm" 
                                className="intern-register summer-register" 
                                style={{textDecoration:'none'}}
                                >
                                Apply Now
                            </HashLink> */}
                            <button className="intern-register summer-register" data-bs-toggle="modal" data-bs-target="#myModal" >
                                Apply Now
                            </button>
                    </Typography>
               </div>
            </section>
            <br />
            <div style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center'
            }} className="campus-ambas-top-text">
                <p style={{
                    margin: 0
                }}>
                    If you are ready for an opportunity that will kickstart your resume, join us as a student for our Campus Ambassador 
                    Program 2.0 and get hands-on experience of working and collaborating with one of the leading Ed- Techs of India.  
                </p>   
            </div>
            <br />
            <div className="wintr-inter-registr-aria benefit-being-campus-ambas">
                <div className="wintr-training-img-box">
                    <img src={TrainingBenefits} />
                </div>
                <div>
                    <div className="wintr-intrn-heading">
                        <Typography component="h2" className="techvanto-whyus-heading heading-alignment wintr-intrn-text">
                            Benefits being Campus Ambassador
                        </Typography>
                    </div>
                    <div className="wintr-training-prog-bene">
                        <p className="program-last-for-15 program-lasts">
                            The Program lasts eight to ten weeks, but it offers tons of great benefits:
                        </p>
                        <div className="wintr-training-benefit-list">
                            <div>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Incentives (Variable Based)</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Free Trainings</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Full time job offer</p>
                                </div>

                            </div>
                            <div className="wintr-training-right-list">
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Internship Offers</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Certificate Of Excellence</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                                    <img src={RedCheck} />
                                    <p style={{marginBottom: 0, marginLeft: '12px'}}>Learn Business and Management Skills</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
            }} className="campus-ambas-top-text">
                <Typography component="h2" className="techvanto-whyus-heading heading-alignment wintr-intrn-text">
                    Skills you will learn
                </Typography>
                <p style={{
                    margin: 0,
                    color: 'var(--color-secondary-text)'
                }} className="apart-from">
                    Apart from getting Business and Management Insights from Industry Experts, you will: 
                </p>   
            </div>
            <br />
            <section>
                <div className="capmps-ambs-skills-learn">
                    <div className="capmps-ambas-skill-box">
                        <img src={`${SoftSkills}`} className="skills-img-size" />
                        <p>Improve the ability to help people engage with the company which is an important soft skills.</p>
                    </div>
                    <div className="capmps-ambas-skill-box">
                        <img src={`${ManagementSkills}`} className="skills-img-size" />
                        <p>It will also expose you to handling multiple projects and acquire Time Management Skills.</p>
                    </div>
                    <div className="capmps-ambas-skill-box">
                        <img src={`${NetworkSkills}`} className="skills-img-size" />
                        <p>It shall prove to be an individual chance to get your name recognised and network.</p>
                    </div>
                </div>
            </section>
            <br />
            <br />
            <section>
                <div className="responsibility-box responsibilty-outer-box">
                    <div className="responsibility-aria">
                        <Typography component="h2" className="techvanto-whyus-heading heading-alignment">
                            Responsibilities
                        </Typography>
                        <p>
                            If selected, your main tasks will be to spread awareness about Techvanto Academy’s Offerings among the 
                            student’s community in your institution. 
                        </p>
                    </div>
                    <div className="dotted-line"></div>
                    <div>
                        <Typography component="h2" className="techvanto-whyus-heading heading-alignment">
                            Who can apply?
                        </Typography>
                        <p>
                            If you are in your second year of a course and upwards, seize the opportunity to be the face of Techvanto 
                            Academy in your college. 
                        </p>
                    </div>
                </div>
            </section>
            <br />
            <br />
            {/* <section>
                <Container className="main-container-box-prev">
                    <div className="enroll-box" 
                        style={{
                            background: 'linear-gradient(to right, #4249F0, #9922C3)',
                            alignItems: 'center',
                            position: 'relative',
                            }}>
                        <div>
                            <p className="want-to-learn-explain">We have all the technology to make you smarter. Get Smarter With Tech today.</p>
                        </div>
                        <div>
                            <button className="enroll-now-know-more">
                                Register Now
                            </button>
                        </div>
                        <div>
                            <img src={WintrRegistrCard} width="180" className="wintr-card-image" />
                        </div>
                    </div>
                </Container>
            </section> */}
            {/* <LoginPopup openPopup={openLoginPopup} setClose={() => setOpenLoginPopup(false)} prevUrl={location.pathname} /> */}
            <div className="modal" id="myModal" style={{
                zIndex: 10000
            }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title modal-header-text-style">
                                Apply For Campus Ambassador
                            </h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div>
                                    <form onSubmit={submitForm} className="right-panel-form">
                                        <div>
                                            <div className="form-group">
                                                <label className="resume-form-label">Name</label>
                                                <input 
                                                type="text" 
                                                name="name" 
                                                className="form-control resume-input-box" 
                                                value={info.name}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={nameError}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div>
                                            <div className="form-group">
                                                <label className="resume-form-label">Contact Number</label>
                                                <input 
                                                type="number"
                                                name="phone" 
                                                className="form-control resume-input-box" 
                                                value={info.phone}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={phoneError}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div>
                                            <div className="form-group">
                                                <label className="resume-form-label">Email id</label>
                                                <input 
                                                type="email" 
                                                name="email" 
                                                className="form-control resume-input-box" 
                                                value={info.email}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={emailError} 
                                                //helperText={name ? "Enter Your Name." : ""}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="file-upload" style={{
                                            margin: 0,
                                            marginBottom: '16px',
                                            width: '100%'
                                        }}>
                                            <Button component="div" className="upload-btn" sx={{
                                                height: '84px',
                                                width: '100%'
                                            }}>
                                                    <label for="files" className="upload-icon resume-upload-aria">
                                                        <UploadFileIcon sx={{
                                                            fontSize:"32px",
                                                            marginBottom:"10%"}}/>  
                                                    </label>
                                                    <input id="files" type="file" style={{visibility:"hidden"}}
                                                    onChange={handleUploadOnS3} 
                                                    accept=".doc,.docx,.pdf"
                                                    className={selectedFile ? "upload-res-text" : "upload-res-text disabled"}
                                                    />
                                                    {file && <p className="file-name select-file-name" style={{
                                                        marginTop:"8px"
                                                    }}>{file}</p>}
                                                    {fileLoader && 
                                                    <div className="upload-loader">
                                                        <img src={ButtonLoader} width="80" alt="" />
                                                    </div>
                                                    }
                                                    <p className="upload-res-text upload-text-style" style={{
                                                        fontSize: "12px",
                                                        height: "22px",
                                                        marginTop: "24px"
                                                    }}>Upload my resume</p>                    
                                                </Button>
                                                <br/>
                                                {fileFieldError && (
                                                <p style={{ marginTop: "0", color: "red" }}>
                                                    Field Required or Upload File{" "}
                                                </p>
                                                )}
                                            </div>
                                        <div>
                                            <center> <button type="submit"
                                            className="btn-grad-resume resume-upload-btn"
                                            style={buttonLoader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-primary)'}}
                                                disabled={buttonLoader}
                                            >
                                                {buttonLoader ? <img src={ButtonLoader} width="80" alt="" /> : 'Submit'}
                                            </button></center>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        </>
    )
}
export default CampusAmbas;