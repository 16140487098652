import routes from '../routes/Services.routes';
import axios from 'axios';

export default async function addHireFromUsApi(body){
    return await axios.post(routes.HireFromUs, body, {
        headers: {
            'token': localStorage.getItem('token')
        },
    }).then(res=>{
        return res.data;
    }).catch(error =>{
        // console.log("error=============",error);
        if(error.response){
            return error.response.data.message;
        }else if(error.request){           
        }else{
        }
    })
}