import * as React from "react";
import { Typography, Container } from "@mui/material";
import "./exerciseAria.css";
import { Link } from "react-router-dom";
import ExerciseHomePic from "../../assets/images/exercise_home_pic.png";


const ExerciseAria = () => {
  //const isMobile = useIsMobile();
  return (
    <section>
      <Container className="main-container-box-prev">
        <div className="exercise-aria-box">
            <div className="e-content-aria">
                <p className="e-top-heading">
                  Not sure where to start your prep?
                </p>
                {/* <p style={{fontSize: '18px'}}></p> */}
                <p style={{
                    fontSize: '24px', 
                    color: 'var(--color-green-icon)',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    }}>Attempt our Quizzes</p>
                <Link style={{
                    backgroundColor: 'var(--color-secondary-red)',
                    border: 'none',
                    color: 'var(--color-white)',
                    borderRadius: '8px',
                    padding: '8px 12px',
                    textDecoration: 'none'
                }} to={'/resources/excercise'} 
                // disabled={true}
                >
                    Attempt Now
                </Link>
            </div>
            <div>
                <img src={ExerciseHomePic} width="240"/>
            </div>
        </div>
      </Container>
    </section>
  );
};

export default ExerciseAria;
