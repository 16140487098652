import React from 'react';
import { useEffect, useState } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Typography, Container, CardActions } from "@mui/material";
import UploadOnS3 from "../../apis/api/UploadOnS3";
import addCampusAmbasApi from "../../apis/api/AddCampusAmbas";
import { Link } from 'react-router-dom';
import "./hireFromUs.css";
import { Button } from "@mui/material";
import ButtonLoader from "../../assets/images/button_loader.gif";
import TrainingBenefits from "../../assets/images/benifits_campus_ambs.png";
import RedCheck from "../../assets/images/red_check.png";
import NetworkSkills from "../../assets/images/network_skills.png";
import ManagementSkills from "../../assets/images/management_skills.png";
import SoftSkills from "../../assets/images/soft_skills.png";
import HeaderImage from "../../assets/images/placement_training_header_img.png";
import placementAsissKey from "../../assets/images/placement_asiss_key.png";
import { toast } from "react-toastify";
import { Partners } from "../../components";
import hiringData from '../../data/partner/partner';
import topClientData from '../../data/universityPartners/universityPartner';
import {ReviewAria} from "../../components"
import GreenCheck from "../../assets/images/green_check.png";
import WhyShould1 from "../../assets/images/why-should-1.png";
import WhyShould2 from "../../assets/images/why-should-2.png";
import WhyShould3 from "../../assets/images/why-should-3.png";
import WhyShould4 from "../../assets/images/why-should-4.png";
import WhyShould5 from "../../assets/images/why-should-5.png";
import WhyShould6 from "../../assets/images/why-should-6.png";
import PlBottomImg from "../../assets/images/pl-bottom-img.png";
import HirHeaderImg from "../../assets/images/hir_header_img.jpg";
import HirMenu from "../../assets/images/hir_menu.png";
import HireProfessionalPic from "../../assets/images/hire_professional_pic.png";
import HirFormBackImg from "../../assets/images/hir-form-back-img.jpg";
import HirFormImg from "../../assets/images/hir-form-img.png";
import HirFormMobileImg from "../../assets/images/mobile-back-img-hir-form.png";
import DownArrow from "../../assets/images/down-arrow.png";
import addHireFromUsApi from "../../apis/api/AddHireFromUs";

const CampusAmbas = () => {
    const [fileFieldError, setFileFieldError] = useState(false);
    const [selectedFile, setSelectedFile] = useState({image:{}});
    const [fileLoader, setFileLoader] = useState(false);
    const [file, setFile] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [resumeError, setResumeError] = useState(true);
    const [loader, setLoader] = useState(true);
    const [nameError, setNameError] = useState(true);
    const [emailError, setEmailError] = useState(true);
    const [phoneError, setPhoneError] = useState(true);
    const [companyError, setCompanyError] = useState(true);
    const [roleError, setRoleError] = useState(true);
    const [techStackError, setTechStackError] = useState(true);
    const [info, setInfo] = useState({
        name: "",
        email: "",
        phone: "",
        company: "",
        role: "",
        tech_stack: ""
    });
    let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const submitForm= async(e) =>{
        e.preventDefault();
        setLoader(true);
        if(!nameError && !phoneError && 
           !emailError && !techStackError && 
           !companyError && !roleError )
        {
            let res;
            setButtonLoader(true)
            res = await addHireFromUsApi(info);
            console.log("res==========",res)
            if(res){
                // console.log("res==========",res)
                setButtonLoader(false)
                if(res.statusCode === 200){
                    console.log("success");
                    let fileInfo = {
                        name: "",
                        email: "",
                        phone: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).phone : "",
                        company:"",
                        role: "",
                        tech_stack: ""
                    }
                    setNameError(true);
                    setEmailError(true);
                    JSON.parse(localStorage.getItem('user')) ? setPhoneError(false) : setPhoneError(true);
                    setResumeError(true);
                    setFile(null);
                    setInfo(fileInfo);
                    toast.success(res.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }else{
                    toast.error('Something went wrong.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }
            }else{
                setButtonLoader(false);
                toast.error('Something went wrong.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            }
        }else{
            if(nameError) return errorToast('Name required.');
            if(companyError) return errorToast('Company Required.');
            if(phoneError) return errorToast('Invalid phone number entered.');
            if(emailError) return errorToast('Invalid email entered.');
            if(roleError) return errorToast('Job Role Required.');
            if(techStackError) return errorToast('Tech Stack Required.');
        }
    }
    const formValidate = (event) => {
        if (event.name === "name") {
          setInfo({ ...info, name: event.value });
          if(event.value === "") return setNameError(true);
          setNameError(false);
        }
        if (event.name === "email") {
          setInfo({ ...info, email: event.value });
          if(event.value === "" || !event.value.match(emailValidate)) return setEmailError(true);
          setEmailError(false);
        }
        if (event.name === "phone") {
          setInfo({ ...info, phone: event.value });
          if(event.value !== "" && event.value.length === 10) return setPhoneError(false);
          setPhoneError(true);
        }
        if (event.name === "company") {
            setInfo({ ...info, company: event.value });
            if(event.value !== "") return setCompanyError(false);
            setCompanyError(true);
          }
        if (event.name === "job_role") {
            setInfo({ ...info, role: event.value });
            if(event.value !== "") return setRoleError(false);
            setRoleError(true);
        }
        if (event.name === "tech_stack") {
            setInfo({ ...info, tech_stack: event.value });
            if(event.value !== "") return setTechStackError(false);
            setTechStackError(true);
        }
      }
    const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    return (
        <>
            <section className="summer-intern">
                <div style={{
                    position: "relative"
                }}>
                    <img src={HirHeaderImg} />
                    <div style={{
                        position: "absolute",
                        padding: '0 12px',
                        width: '100%',
                        textAlign: "center"
                    }} className="hir-header-box">
                        <Typography component="h2" className="techvanto-whyus-heading hir-heading" style={{
                            color: '#3D95D1'
                        }}>
                            Hire Job Ready Professionals
                        </Typography>
                        <Typography component="h2" className="techvanto-whyus-heading hir-heading">
                            For Your Team
                        </Typography>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '24px'
                        }}>
                            <p style={{
                                textAlign: "center",
                                color: 'var(--color-secondary-text)'
                            }} className="hir-extensive">
                                With an extensive pool of experienced and qualified candidates, we can help you find the right person for 
                                any role.
                            </p>
                        </div>
                        <div style={{
                            textAlign: 'center',
                        }}>
                            <button 
                                className="intern-register placement-apply-now" 
                                style={{
                                    backgroundColor: "#FFE870",
                                    color: 'var(--color-black)',
                                    fontWeight: 800
                                }}>
                                Start Hiring
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='hir-perfect-fit-aria'>
                <div className='hir-perfect-fit-box'>
                    <Typography component="h2" className="techvanto-whyus-heading perfect-learn-heading" style={{
                        color: '#3D95D1',
                    }}>
                        What makes Techvanto learners ‘Perfect Fit’ for your company
                    </Typography>
                    <p className="hir-perfect-fit-des">
                        Access on exclusive network of highly-skilled talent matched to your needs and ready to join your company from 
                        anywhere.
                    </p>
                </div>
                <div className='hir-perfect-fit-box hir-perfect-fit-box-2'>
                    <div className='hir-perfect-fit-card'>
                        <img src={HirMenu} />
                        <Typography component="h2" className="techvanto-whyus-heading hir-perfect-fit-card-heading" style={{
                            textAlign: 'center'
                        }}>
                            Verified Talent
                        </Typography>
                        <p style={{
                            textAlign: 'center'
                        }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, congue etiam donec rhoncus amet, eleifend.
                        </p>
                    </div>
                    <div className="hir-perfect-fit-box-3-pos">
                        <div className='hir-perfect-fit-card hir-perfect-fit-box-3'>
                            <img src={HirMenu} />
                            <Typography component="h2" className="techvanto-whyus-heading hir-perfect-fit-card-heading" style={{
                                textAlign: 'center'
                            }}>
                                Zero Hiring Costing
                            </Typography>
                            <p style={{
                                textAlign: 'center'
                            }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, congue etiam donec rhoncus amet, eleifend.
                            </p>
                        </div>
                    </div>
                    <div className='hir-perfect-fit-card hir-perfect-fit-box-4'>
                        <img src={HirMenu} />
                        <Typography component="h2" className="techvanto-whyus-heading hir-perfect-fit-card-heading" style={{
                            textAlign: 'center'
                        }}>
                            Hiring Companies
                        </Typography>
                        <p style={{
                            textAlign: 'center'
                        }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, congue etiam donec rhoncus amet, eleifend.
                        </p>
                    </div>
                </div>
            </section>
            <br />
            <Partners data={topClientData} heading="Partnership with Top Universities" />
            <br />
            <div style={{
                textAlign: 'center',
            }}>
                <button 
                    className="intern-register placement-apply-now" 
                    data-bs-toggle="modal" 
                    data-bs-target="#myModal"
                    style={{
                        backgroundColor: "#FFE870",
                        color: 'var(--color-black)',
                        fontWeight: 800
                    }}>
                    Start Hiring
                </button>
            </div>
            <br />
            <section className="hir-pro-section">
                <Typography component="h2" className="techvanto-whyus-heading" style={{
                        textAlign: 'center',
                    }}>
                        How you Hire professionals from Techvanto
                </Typography>
                <br />
                <div className="hir-pro-box">
                    <div>
                        <img src={HireProfessionalPic} width="342" />
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </li>
                            <li>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </li>
                            <li>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </li>
                            <li>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </li>
                            <li>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <br />
            <Partners data={hiringData} heading="Our Alumini work in leading companies" />
            <br />
            <ReviewAria />
            <br />
            <section style={{
                position: 'relative',
            }} className="hir-us-form-height" id="hir-us-form-info">
                <Typography component="h2" className="techvanto-whyus-heading hir-heading" style={{
                    color: '#223A7B',
                    textAlign: 'center',
                }}>
                    Hire From Us
                </Typography>
                <div className="hir-form-aria" style={{
                    marginTop: '24px',
                }}>
                    <div className="hir-form-1" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#fff'
                    }}>
                        <img src={HirFormImg} />
                    </div>
                    <div className="hir-form-1" style={{
                        backgroundColor: '#223A7B',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <form className="right-panel-form hir-emp-form">
                            <div>
                                <div className="form-group" style={{textAlign: 'left'}}>
                                    <label className="resume-form-label" style={{color: '#fff'}}>Full Name</label>
                                    <input 
                                    type="text" 
                                    name="name" 
                                    className="form-control resume-input-box" 
                                    value={info.name}
                                    onChange={((e)=>formValidate(e.target))}
                                    error={nameError}
                                    required/>
                                </div>
                            </div>
                            <br/>
                            <div>
                                <div className="form-group" style={{textAlign: 'left'}}>
                                    <label className="resume-form-label" style={{color: '#fff'}}>Company Name</label>
                                    <input 
                                    type="text" 
                                    name="company" 
                                    className="form-control resume-input-box" 
                                    value={info.company}
                                    onChange={((e)=>formValidate(e.target))}
                                    error={companyError}
                                    required/>
                                </div>
                            </div>
                            <br/>
                            <div>
                                <div className="form-group" style={{textAlign: 'left'}}>
                                    <label className="resume-form-label" style={{color: '#fff'}}>Contact Number</label>
                                    <input 
                                    type="number"
                                    name="phone" 
                                    className="form-control resume-input-box" 
                                    value={info.phone}
                                    onChange={((e)=>formValidate(e.target))}
                                    error={phoneError}
                                    required/>
                                </div>
                            </div>
                            <br/>
                            <div>
                                <div className="form-group" style={{textAlign: 'left'}}>
                                    <label className="resume-form-label" style={{color: '#fff'}}>Email id</label>
                                    <input 
                                    type="email" 
                                    name="email" 
                                    className="form-control resume-input-box" 
                                    value={info.email}
                                    onChange={((e)=>formValidate(e.target))}
                                    error={emailError} 
                                    //helperText={name ? "Enter Your Name." : ""}
                                    required/>
                                </div>
                            </div>
                            <br/>
                            <div>
                                <div className="form-group" style={{
                                    textAlign: 'left',
                                    position: 'relative',
                                    }}>
                                    <label className="resume-form-label" style={{color: '#fff'}}>Your Job Role</label>
                                    <select name="job_role" className="form-control input-style-coun" 
                                        onChange={((e)=>formValidate(e.target))}
                                        >
                                        <option 
                                            value="Select Education" 
                                            selected
                                            disabled>Select Job Role</option>
                                        <option value="In 12th">CSE</option>
                                        <option value="12th completed">MEC</option>
                                        <option value="Graduating 1">ELE</option>
                                        <option value="Graduated 2">BIO</option>
                                    </select>
                                    <div className="down-arrow">
                                        <img src={DownArrow} height="24" width="24" />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div>
                                <div className="form-group" style={{textAlign: 'left'}}>
                                    <label className="resume-form-label" style={{color: '#fff'}}>Tech Stack</label>
                                    <input 
                                    type="text" 
                                    name="tech_stack" 
                                    className="form-control resume-input-box" 
                                    value={info.tech_stack}
                                    onChange={((e)=>formValidate(e.target))}
                                    error={techStackError}
                                    required/>
                                </div>
                            </div>
                            <br />
                            <div>
                                <center> <button type="button"
                                onClick={submitForm}
                                className="btn-grad-resume resume-upload-btn"
                                style={
                                    buttonLoader ? {backgroundColor: 'var(--color-disable)'} : 
                                    {backgroundColor: 'var(--color-secondary)', fontWeight: 600}}
                                    disabled={buttonLoader}
                                >
                                    {buttonLoader ? <img src={ButtonLoader} width="80" alt="" /> : 'Submit'}
                                </button></center>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CampusAmbas;