import React from 'react';
import { useState } from 'react';
import { Tab, Box, Typography } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import "./services.css";
import ButtonLoader from "../../assets/images/button_loader.gif";
import { toast } from "react-toastify";
import addServicesQueryApi from "../../apis/api/AddServicesQuery";
import NewServices from "../../data/services/NewServices";
import ServicesBackImg from "../../assets/images/services-back-img.png";

const Services = () => {
    const [file, setFile] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [resumeError, setResumeError] = useState(true);
    const [loader, setLoader] = useState(true);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [collegeError, setCollegeError] = useState(false);
    const [techError, setTechError] = useState(false);
    const [modalData, setModalData] = useState({});
    const { category } = useParams();
    const [value, setValue] = useState(
        category === 'corporates' ? '1' : (category === 'schools' ? '2' : '3')
    );
    const [info, setInfo] = useState({
        name: "",
        email: "",
        phone: "",
        designation:"",
        message: "",
    });
    let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const submitForm= async(e) =>{
        e.preventDefault();
        setLoader(true);
        if(!nameError && !phoneError && 
           !emailError && !collegeError && !techError)
        {
            let res;
            setButtonLoader(true)
            res = await addServicesQueryApi(info);
            console.log("res==========",res)
            if(res){
                // console.log("res==========",res)
                setButtonLoader(false)
                if(res.statusCode === 200){
                    console.log("success");
                    let fileInfo = {
                        name: "",
                        email: "",
                        phone: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).phone : "",
                        designation: "",
                        message: ""
                    }
                    setNameError(true);
                    setEmailError(true);
                    JSON.parse(localStorage.getItem('user')) ? setPhoneError(false) : setPhoneError(true);
                    setResumeError(true);
                    setFile(null);
                    setInfo(fileInfo);
                    toast.success(res.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }else{
                    toast.error('Something went wrong.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }
            }else{
                setButtonLoader(false);
                toast.error('Something went wrong.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }else{
            if(nameError) return errorToast('Name required.');
            if(phoneError) return errorToast('Invalid phone number entered.');
            if(emailError) return errorToast('Invalid email entered.');
            if(collegeError) return errorToast('Qualification/Designation Required.');
            if(techError) return errorToast('Message Required.');
        }
    }
    const formValidate = (event) => {
        if (event.name === "name") {
          setInfo({ ...info, name: event.value });
          if(event.value === "") return setNameError(true);
          setNameError(false);
        }
        if (event.name === "email") {
          setInfo({ ...info, email: event.value });
          if(event.value === "" || !event.value.match(emailValidate)) return setEmailError(true);
          setEmailError(false);
        }
        if (event.name === "phone") {
          setInfo({ ...info, phone: event.value });
          if(event.value !== "" && event.value.length === 10) return setPhoneError(false);
          setPhoneError(true);
        }
        if (event.name === "college_name") {
            setInfo({ ...info, designation: event.value });
            if(event.value !== "") return setCollegeError(false);
            setCollegeError(true);
          }
          if (event.name === "tech") {
            setInfo({ ...info, message: event.value });
            if(event.value !== "") return setTechError(false);
            setTechError(true);
          }
      }
    const errorToast = (message) => {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <section className="summer-intern" style={{
                backgroundImage: `url(${ServicesBackImg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
                <div style={{
                    height: '310px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Typography component="h2" className="techvanto-whyus-heading" 
                        style={{
                            color: '#fff',

                        }}>
                        Services
                    </Typography>
                </div>
            </section>
            <br />
            <section>
                <TabContext 
                    value={value}
                    >
                    <Box className="new-services">
                        <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs"
                        className="services-tabs"
                        variant="scrollable"
                        >
                            <Tab label="Colleges/Undergrads" value="1" />
                            <Tab label="Schools/Intermediate" value="2" />
                            <Tab label="Companies" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div style={{
                            display: "inline-block",
                        }}>
                            {NewServices.map((item, index) => (
                                (item.tab === 'Undergrads' || item.tab === 'Colleges') && 
                                    <div key={index} className="ser-card-box" >
                                        {!item.isPage ? 
                                        <div onClick={() => {setModalData({
                                            icon: item.icon, text: item.text, description: item.description})}} 
                                            data-bs-toggle="modal" data-bs-target="#myModal">
                                            <img src={item.icon} width="32" />
                                            <h6 style={{
                                                fontWeight: 'bold',
                                                color: 'var(--color-secondary)',
                                                marginTop: '8px'
                                            }}>
                                                {item.text}
                                            </h6>
                                            <p style={{
                                                color: 'var(--color-secondary-text)',
                                                textAlign: 'left',
                                                fontSize: '14px'
                                            }}>
                                                {item.description < 105 ? item.description : item.description.substr(0, 105)+'...'}
                                            </p>
                                        </div>:
                                        <Link to={item.link} style={{
                                            textDecoration: 'none',
                                        }}>
                                            <img src={item.icon} width="32" />
                                            <h6 style={{
                                                fontWeight: 'bold',
                                                color: 'var(--color-secondary)',
                                                marginTop: '8px'
                                            }}>
                                                {item.text}
                                            </h6>
                                            <p style={{
                                                color: 'var(--color-secondary-text)',
                                                textAlign: 'left',
                                                fontSize: '14px'
                                            }}>
                                                {item.description < 105 ? item.description : item.description.substr(0, 105)+'...'}
                                            </p>
                                        </Link>}
                                    </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel value="2">
                        <div style={{
                            display: "inline-block",
                        }}>
                            {NewServices.map((item, index) => (
                                (item.tab === 'Intermediate' || item.tab === 'School') && 
                                    <div key={index} className="ser-card-box" data-bs-toggle="modal" 
                                        data-bs-target="#myModal">
                                        <img src={item.icon} width="32" />
                                        <h6 style={{
                                            fontWeight: 'bold',
                                            color: 'var(--color-secondary)',
                                            marginTop: '8px'
                                        }}>
                                            {item.text}
                                        </h6>
                                        <p style={{
                                            color: 'var(--color-secondary-text)',
                                            textAlign: 'left',
                                            fontSize: '14px'
                                        }}>
                                            {item.description < 105 ? item.description : item.description.substr(0, 105)+'...'}
                                        </p>
                                    </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel value="3">
                        <div style={{
                            display: "inline-block",
                        }}>
                            {NewServices.map((item, index) => (
                                (item.tab === 'Companies') && 
                                    <div key={index} className="ser-card-box">
                                        {!item.isPage ? 
                                            <div data-bs-toggle="modal" data-bs-target="#myModal">
                                                <img src={item.icon} width="32" />
                                                <h6 style={{
                                                    fontWeight: 'bold',
                                                    color: 'var(--color-secondary)',
                                                    marginTop: '8px'
                                                }}>
                                                    {item.text}
                                                </h6>
                                                <p style={{
                                                    color: 'var(--color-secondary-text)',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {item.description < 105 ? item.description : item.description.substr(0, 105)+'...'}
                                                </p>
                                            </div>:
                                            <Link to={item.link} style={{
                                                textDecoration: 'none',
                                            }}>
                                                <img src={item.icon} width="32" />
                                                <h6 style={{
                                                    fontWeight: 'bold',
                                                    color: 'var(--color-secondary)',
                                                    marginTop: '8px'
                                                }}>
                                                    {item.text}
                                                </h6>
                                                <p style={{
                                                    color: 'var(--color-secondary-text)',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {item.description < 105 ? item.description : item.description.substr(0, 105)+'...'}
                                                </p>
                                            </Link>
                                        }
                                    </div>
                            ))}
                        </div>
                    </TabPanel>
                </TabContext>
            </section>
            <div className="modal" id="myModal" style={{
                zIndex: 10000
            }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                        <div className="modal-header" style={{
                            border: 'none'
                        }}>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <img src={modalData.icon} width="32" />
                                    <h6 style={{
                                            fontWeight: 'bold',
                                            color: 'var(--color-secondary)',
                                            marginTop: '8px'
                                        }}>
                                            {modalData.text}
                                    </h6>
                                    <p style={{
                                        color: 'var(--color-secondary-text)',
                                        fontSize: '14px',
                                        textAlign: 'center'
                                    }}>
                                        {modalData.description}
                                    </p>
                                </div>
                                <br />
                                <div>
                                    <h6 style={{
                                            fontWeight: 'bold',
                                            color: 'var(--color-secondary)',
                                            textAlign: 'center'
                                        }}>
                                            Query Form
                                    </h6>
                                    <form onSubmit={submitForm} className="or-form">
                                        <div>
                                            <div className="form-group">
                                                <label className="secondary-text-color">Name</label>
                                                <input 
                                                type="text" 
                                                name="name" 
                                                className="form-control or-input-box" 
                                                value={info.name}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={nameError}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div>
                                                <div className="form-group">
                                                    <label className="secondary-text-color">Phone Number</label>
                                                    <input 
                                                    type="number"
                                                    name="phone" 
                                                    className="form-control or-input-box" 
                                                    value={info.phone}
                                                    onChange={((e)=>formValidate(e.target))}
                                                    error={phoneError}
                                                    required/>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-group">
                                                    <label className="secondary-text-color">Email id</label>
                                                    <input 
                                                    type="email" 
                                                    name="email" 
                                                    className="form-control or-input-box" 
                                                    value={info.email}
                                                    onChange={((e)=>formValidate(e.target))}
                                                    error={emailError} 
                                                    //helperText={name ? "Enter Your Name." : ""}
                                                    required/>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div>
                                            <div className="form-group">
                                                <label className="secondary-text-color">Qualification/Designation</label>
                                                <input 
                                                type="text" 
                                                name="college_name" 
                                                className="form-control or-input-box" 
                                                value={info.designation}
                                                onChange={((e)=>formValidate(e.target))}
                                                error={collegeError}
                                                required/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div>
                                            <div className="form-group">
                                                <label className="secondary-text-color">Message</label>
                                                <textarea 
                                                    type="text"
                                                    name="tech" 
                                                    rows="4"
                                                    className="form-control or-input-box" 
                                                    value={info.message}
                                                    onChange={((e)=>formValidate(e.target))}
                                                    error={techError}
                                                    style={{
                                                        resize: 'none',
                                                    }}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <div>
                                            <center> <button type="submit"
                                            className="btn-grad-resume resume-upload-btn"
                                            style={buttonLoader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-primary)'}}
                                                disabled={buttonLoader}
                                            >
                                                {buttonLoader ? <img src={ButtonLoader} width="80" alt="" /> : 'Submit'}
                                            </button></center>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

const ServiceCard = () => {

}
export default Services;