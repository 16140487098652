import React, {useState,useEffect} from 'react';
import CounselingHeader from './CounselingHeader';
import './counseling.css'
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import DownArrow from '../../assets/images/down-arrow.png';
import addCouncellingApi from "../../../src/apis/api/AddCouncelling";
import DatePicker from "react-datepicker";
import ButtonLoader from "../../assets/images/button_loader.gif";
import CalenderIcon from '../../assets/images/calendar.png';
import TimeIcon from '../../assets/images/clock.png';

let date = '';
let time = '';
let today = '';

function Counseling(){
    const [educationError, setEducationError] = useState(true);
    const [nameError, setNameError] = useState(true);
    const [emailError, setEmailError] = useState(true);
    const [phoneError, setPhoneError] = useState(true);
    const [dateError, setDateError] = useState(true);
    const [timeError, setTimeError] = useState(true);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [dateValue, onChange] = useState(new Date());
    const [timeValue, timeCahnge] = useState(new Date());
    let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    
    const [info, setInfo] = useState({
        name: "",
        email: "",
        phone: "",
        education:"",
        date: ""
    });
    useEffect(() => {
        todayDate();
        let user = JSON.parse(localStorage.getItem('user'));
        if(user){
            setPhoneError(false)
            setInfo({...info, phone: user.phone})
        }else{
            setPhoneError(true)
            setInfo({...info, phone: ''})
        }
    },[])
    const todayDate = () => {
        let fullYear = new Date().getFullYear();
        let month =  new Date().getMonth()+1;
        let date = new Date().getDate();
        month = month < 10 ? `0${month}` : month;
        date = date < 10 ? `0${date}` : date;
        today = `${fullYear}-${month}-${date}`;
    }
    const formValidate = (e) => {
        if(e.name === 'name'){
            setInfo({...info, name: e.value});
            setNameError(false);
        }
        if(e.name === 'phone'){
            setInfo({...info, phone: e.value});
            if(e.value !== "" && e.value.length === 10) return setPhoneError(false);
            setPhoneError(true);
        }
        if(e.name === 'email'){
            setInfo({...info, email: e.value});
            if(e.value === "" || !e.value.match(emailValidate)) return setEmailError(true);
            setEmailError(false);
        }
        if(e.name === 'education'){
            setInfo({...info, education: e.value});
            setEducationError(false);
        }
    }
    const submitForm = async() => {
        if(!nameError && !emailError && !phoneError && !dateError && !timeError && !educationError){
            setButtonLoader(true);
            console.log(info);
            let res = await addCouncellingApi(info);
            if(res?.statusCode === 200){
                let newInfo = {
                    name: "",
                    email: "",
                    education:"",
                    date: "",
                    phone: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user'))?.phone : "",
                }
                setNameError(true);
                JSON.parse(localStorage.getItem('user')) ? setPhoneError(false) : setPhoneError(true);
                setEmailError(true);
                setEducationError(true);
                setDateError(true);
                setTimeError(true);
                onChange(new Date());
                timeCahnge(new Date());
                setInfo(newInfo);
                setButtonLoader(false);
                toast.success(res.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else{
                setButtonLoader(false)
                toast.error('Something went wrong.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }else{
            if(nameError) return alertErrr('Name required');
            if(emailError) return alertErrr('Invalid email entered');
            if(phoneError) return alertErrr('Invalid phone number entered');
            if(dateError) return alertErrr('Date required');
            if(timeError) return alertErrr('Time required');
            if(educationError) return alertErrr('Education required');
        }
    }
    const alertErrr = (message) => {
        toast.warning(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const handleDateSelect = (value, from) => {
        if(from === 'date'){
            date = value;
            onChange(value);
            setDateError(false);
        }
        if(from === 'time'){
            time = value;
            timeCahnge(value);
            setTimeError(false);
        }
        if(date !== '' && time !== ''){
            let y = new Date(dateValue).getFullYear();
            let m = new Date(dateValue).getMonth()+1;
            let d = new Date(dateValue).getDate();
            let hh = new Date(time).getHours();
            let min = new Date(time).getMinutes();
            let newDate = new Date(y + ',' + m + ',' + d + ' ' + hh + ':' + min);
            setInfo({...info, date: `${newDate}`});
        }
    }
    return(
        <div>
            <CounselingHeader/>
            <div className="counsel-body">
                <center>
                    <div style={{marginTop:"3%"}}>
                        <h2>You are just one step away!</h2><br/>
                        <p className="description-text">Let us help you find a rewarding 
                        career opportunities, job and/or internship.
                        Fill out the form below to tell us about yourself,
                        and our expert career counsellors will make it a meaningful 
                        journey for you.</p>
                    </div>
                </center>
                <div className="form-box-councelling">
                    <div className="career-left">
                        <div className="col-lg-12" style={{margin:"2%"}}>
                            <div className="form-group">
                                <label className="coun-form-label">Name</label>
                                <input 
                                    type="text" 
                                    name="name" 
                                    className="form-control input-style-coun" 
                                    value={info.name}
                                    onChange={((e)=>formValidate(e.target))}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12" style={{margin:"2%"}}>
                        <div className="form-group">
                                <label className="coun-form-label">Contact number</label>
                                <input 
                                    type="number" 
                                    name="phone" 
                                    className="form-control input-style-coun" 
                                    value={info.phone}
                                    onChange={((e)=>formValidate(e.target))}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12" style={{margin:"2%"}}>
                            <div className="form-group">
                                    <label className="coun-form-label">Date</label>
                                    <div className="datepicker-box">
                                        <DatePicker
                                            selected={dateValue}
                                            //onSelect={() => {handleDateSelect()}}
                                            onChange={(date) => handleDateSelect(date, 'date')}
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()}
                                        />
                                        <div className="calender-icon">
                                            <img src={CalenderIcon} width="20" />
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="career-right">
                        <div className="col-lg-12" style={{margin:"2%"}}>
                        <div className="form-group">
                                <label className="coun-form-label">Email Id</label>
                                <input 
                                    type="email" 
                                    name="email"
                                    className="form-control input-style-coun" 
                                    value={info.email}
                                    onChange={((e)=>formValidate(e.target))}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12" style={{margin:"2%"}}>
                            <div className="form-group" style={{position: "relative"}}>
                                <label className="coun-form-label">Education</label><br/>
                                <select name="education" className="form-control input-style-coun" 
                                    onChange={((e)=>formValidate(e.target))}>
                                    <option 
                                        value="Select Education" 
                                        selected 
                                        disabled>Select Education</option>
                                    <option value="In 12th">In 12th</option>
                                    <option value="12th completed">12th completed</option>
                                    <option value="Graduating">Graduating</option>
                                    <option value="Graduated">Graduated</option>
                                </select>
                                <div className="down-arrow">
                                    <img src={DownArrow} height="24" width="24" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12" style={{margin:"2%"}}>
                        <div className="form-group">
                                <label className="coun-form-label">Time</label>
                                {/* <input 
                                    type="time" 
                                    name="time" 
                                    className="form-control input-style-coun" 
                                    onChange={((e)=>formValidate(e.target))}
                                /> */}
                                <div className="datepicker-box">
                                    <DatePicker
                                        selected={timeValue}
                                        //onChange={handleDateChange}
                                        //onTimeSelect={() => {handleDateSelect()}}
                                        dateFormat="HH ':' mm"
                                        //dateFormat="HH ':' mm aa"
                                        //onChange={timeCahnge}
                                        onChange={(date) => handleDateSelect(date, 'time')}
                                        minDate={new Date()}
                                        timeIntervals={30}
                                        timeFormat="HH:mm"
                                        timeCaption="time"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        />
                                        <div className="calender-icon">
                                            <img src={TimeIcon} width="20" />
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '20px'}}>
                    <center> 
                        <button type="button" onClick={submitForm}
                            className="btn-grad-resume resume-upload-btn"
                            style={buttonLoader ? {backgroundColor: 'var(--color-disable)'} : {backgroundColor: 'var(--color-primary)'}}
                                disabled={buttonLoader}
                            >
                            {buttonLoader ? <img src={ButtonLoader} width="80" alt="" /> : 'Submit'}
                        </button>
                    </center>
                </div>
            </div>
            <div className="resume-content counceling-content">
                <center> <h2 style={{fontWeight: "bolder"}}>Why seek our online career counseling ?</h2></center>
                <div className="description-box">
                        <div className="content-div">
                            <Typography 
                            sx={{fontSize:"48px",fontWeight:"400"}}>
                                01
                            </Typography>
                            <p className="min-text" style={{fontSize:"18px"}}>Helps in determining strengths and weaknesses</p>
                            <p>It is an excellent method to gauge the student’s qualifications and shortcomings.
                                They are provided with unbaised insights from counsellors that will help their future growth and development.</p>
                        </div>
                        <div className="content-div">
                        <Typography 
                            sx={{fontSize:"48px",fontWeight:"400"}}>
                                02
                            </Typography>
                            <p className="min-text" style={{fontSize:"18px"}}>Helps to clarify career goals.</p>
                            <p>Relevant to their interests and aptitude, students are made aware of the 
                            numerous career pathways available to them with knowledge of what is required
                            in order to persue and achieve those goals.</p>
                        </div>
                        <div className="content-div">
                        <Typography 
                            sx={{fontSize:"48px",fontWeight:"400"}}>
                                03
                            </Typography>
                            <p className="min-text" style={{fontSize:"18px"}}>Helps in upskilling</p>
                            <p>Counsellors assist as well as cater activities and seminars for the 
                            students that will help them is gaining skill sets relevant to their best career options.
                            </p>
                        </div>    
                </div>
            </div>
        </div>
    )
}
export default Counseling;