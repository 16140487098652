//all routes here
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import Events from "../pages/events/Events";
import Course from "../pages/courses/Course";
import AllCourses from "../pages/courses/All-courses/AllCourses";
import Blogs from "../pages/blogs/Blogs";
import Single from "../pages/blogs/singleBlog/Single";
import ContactUsForHiring from "../pages/contact-us/contactUsForHiring";
import NotFound from "../components/NotFound";
import EventDetail from "../pages/SingleEvent/UpcomingEvent.jsx";
import TestQuestion from "../pages/testQuestion/TestQuestion";
import ContactUsToGetHired from "../pages/contact-us/contactUsToGetHired";
import MyCart from "../pages/myCart/MyCart";
import MyCourses from "../pages/myCourses/MyCourses";
import ComingSoon from "../components/ComingSoon/ComingSoon";
import TermsAndCondition from "../components/TermsAndCondition";
import PrivacyPolicy from "../components/PrivacyPolicy";
import PlacementPolicies from "../components/PlacementPolicies";
import ScrollToTop from "../components/ScrollToTop";
import PaymentPolicies from "../components/PaymentPolicies";
import AboutUs from "../pages/about-us/AboutUs.jsx";
import Signup from "../pages/Auth/auth";
import AuthSuccess from "../components/Login/Content/AuthSuccess";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import AllEvents from "../pages/events/AllEvents";
import UpcomingEvent from "../pages/SingleEvent/UpcomingEvent";
import SummerIntern from "../components/summerInternship/SummerIntern";
import InternScholarshipTest from "../components/InternScholarshipTest/InternScholarshipTest";
import CustomBanner from "../components/CustomBanner/CustomBanner";
import Resource from "../pages/resources/Resources";
import BookDemoNew from "../components/BookDemo/BookDemoNew";
import Counseling from  "../components/Counseling/Counseling";
import DiscussionForum from "../components/Discussion/DiscussionForum";
import ExcerciseBody from "../components/Excercise/EcerciseBody";
import ExcerciseDetail from "../components/Excercise/ExcerciseDetail";
import Editors from "../components/Editors/Editors";
import WinterTraining from "../pages/WinterTraining/WinterTraining";
import CampusAmbas from "../pages/CampusAmb/CampusAmbas";
import Olympiad from "../pages/Olympiad/Olympiad";
import KidsCoding from "../pages/KidsCoding/KidsCoding";
import PlacementTraining from "../pages/PlacementTraining/PlacementTraining";
import HireFromUs from "../pages/HireFromUs/HireFromUs";
import OrganiseWorkshop from "../pages/organiseWorkshop/OrganiseWorkshop";
import Services from '../pages/services/Services';

const MainRouters = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/coming-soon" element={<ComingSoon />}></Route>
        {/* doubt below route */}
        <Route
          path="/all-courses/:categoryRoute"
          element={<AllCourses></AllCourses>}
        ></Route>
        <Route path="/auth-user" element={<Signup />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/register-success" element={<AuthSuccess />}></Route>
        <Route path="/forget-password" element={<ForgetPassword />}></Route>
        <Route path="/courses/:id" element={<Course></Course>}></Route>
        <Route
          path="/interview-questions"
          element={<TestQuestion></TestQuestion>}
        ></Route>
        <Route path="/all-events" element={<AllEvents></AllEvents>}></Route>
        <Route path="/event/:id" element={<UpcomingEvent></UpcomingEvent>}></Route>
        <Route path="/events" element={<Events></Events>}></Route>
        <Route path="/blogs" element={<Blogs />}></Route>
        <Route path="/blogs/:id" element={<Single />}></Route>
        <Route path="*" element={<NotFound />}></Route>
        <Route
          path="/contact-us-for-hiring"
          element={<ContactUsForHiring />}
        ></Route>
        <Route
          path="/contact-us-to-get-hired"
          element={<ContactUsToGetHired />}
        ></Route>
        <Route path="/event/:id" element={<EventDetail></EventDetail>}></Route>

        <Route path="/my-cart" element={<MyCart />}></Route>
        <Route path="/my-courses" element={<MyCourses />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/terms-and-conditions" element={<TermsAndCondition />}></Route>
        <Route path="/placement-policy" element={<PlacementPolicies />}></Route>
        <Route path="/payment-policy" element={<PaymentPolicies />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/techvanto-summer-internship" element={<SummerIntern />}></Route>
        <Route path="/internship-shcolarship-test" element={<InternScholarshipTest />}></Route>
        <Route path="/custom-banner/:id" element={<CustomBanner />}></Route>
        <Route path="/bookdemoforme" element={<BookDemoNew/>}></Route>
        <Route path="/resources/resume" element={<Resource/>}></Route>
        <Route path="/resources/counseling" element={<Counseling/>}></Route>
        <Route path="/resources/discussion-forum" element={<DiscussionForum/>}></Route>
        <Route path="/resources/excercise" element={<ExcerciseBody/>}></Route>
        <Route path="/resources/:category" element={<ExcerciseDetail/>}></Route>
        <Route path="/resources/editors" element={<Editors/>}></Route>
        <Route path="/techvanto-winter-internship" element={<WinterTraining/>}></Route>
        <Route path="/campus-ambassador" element={<CampusAmbas/>}></Route>
        <Route path="/olympiad" element={<Olympiad/>}></Route>
        <Route path="/kids-coding" element={<KidsCoding/>}></Route>
        <Route path="/placement-training" element={<PlacementTraining/>}></Route>
        <Route path="/hire-from-us" element={<HireFromUs/>}></Route>
        <Route path="/organise-workshop" element={<OrganiseWorkshop/>}></Route>
        <Route path="/resources/blogs" element={<Blogs />}></Route>
        <Route path="/services/:category" element={<Services/>}></Route>
      </Routes>
    </ScrollToTop>
  );
};
export default MainRouters;
