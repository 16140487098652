import * as React from "react";
import { Typography, Container } from "@mui/material";
import "./industryExpert.css";
import TopCourseBg from "../../assets/images/top_course_bg.png";
import AliceCarousel from "react-alice-carousel";
import ExpertsData from "../../data/experts/experts"

const responsive = {
    400: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
    1280: { items: 4 }
};

const IndustryExpert = () => {

  let items = ExpertsData.map((data, index) => (
    <TopCoursesData data={data} key={index}></TopCoursesData>
  ));

  return (
    <section>
      <Container className="admin-aria-container top-courses-box">
        <div className="top-courses-aria industry-exprt-aria">
            <Typography component="h2" className="techvanto-whyus-heading heading-alignment home-head">
                Top Courses
            </Typography>
            <br/>
            <div>
                <AliceCarousel
                    className="techvanto-student-says-carousel"
                    responsive={responsive}
                    autoPlayDirection={"rtl"}
                    autoPlayStrategy={"none"}
                    animationEasingFunction={"ease"}
                    autoPlay={false}
                    controlsStrategy="alternate"
                    infinite={true}
                    mouseTracking
                    items={items}
                    renderPrevButton={() => {
                        return <div className="left-arrow-studentSays">◄</div>;
                    }}
                    renderNextButton={() => {
                        return <div className="right-arrow-studentSays">►</div>;
                    }}
                />
            </div>
        </div>
      </Container>
    </section>
  );
};
const TopCoursesData = (props) => {
    return (
        <main className="main">
          <section className="container">
            <article className="review review2">
              <div className="img-container top-course-card-new">
                <div style={{
                  position: 'relative',
                  height: '210px'
                  }}>
                  <img src={props.data?.image} alt="" className="t-course-img"/>
                  <div style={{
                    position: 'absolute',
                    height: '80px',
                    bottom: 0,
                    left: '30px',
                    width: '200px',
                    borderLeft: '4px solid var(--color-secondary)',
                    backgroundColor: '#fff',
                    textAlign: 'center',
                    padding: '4px 0'
                  }}>
                    <h6 style={{
                      fontWeight: 'bold'
                    }}>
                      {props.data?.name}
                    </h6>
                    <p style={{
                      color: 'var(--color-secondary)',
                      fontSize: '14px',
                      marginBottom: '6px',
                      fontWeight: 'bold'
                    }}>
                      {props.data?.company}
                    </p>
                    <p style={{
                      color: 'var(--color-secondary-text)',
                      fontSize: '12px',
                      marginBottom: '0px',
                    }}>
                      {props.data?.desig}
                    </p>
                  </div>
                </div>
                {/* <div className="t-course-detail">
                </div> */}
              </div>
            </article>
          </section>
        </main>
      );
}
export default IndustryExpert;
